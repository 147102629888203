<template>
    <div>
        <div v-if="other" class="d-flex">
            <div class="input-group">
                <input ref="input" v-model="otherValue" type="text" @keyup.esc="hideOther" @keyup.enter="applyOther"
                       class="form-control" :class="{'form-control-sm': formSmall,'is-invalid':field.error}"
                       placeholder="Submit your new value">

                <Button @click.native="applyOther" :default-class="'btn-primary'"
                        :class="{'btn-sm': formSmall,'btn-warning': otherValue.length > 0}">
                    <i class="bi-arrow-return-left"/>
                </Button>
            </div>
            <Button @click.native="hideOther" :default-class="'btn-link'"
                    :class="{'btn-sm': formSmall}">
                <i class="bi-x-lg"/>
            </Button>
        </div>

        <!-- v-show must be used otherwise when the dropdown reappears, via hideOther, it doesn't work -->
        <div v-show="!other" class="dropdown">
            <Button ref="btn" :id="dropdownId" aria-expanded="true" class="dropdown-toggle border"
                    :class="{'btn-sm': formSmall}" data-bs-toggle="dropdown">
                {{ currentData[field.name] }}
            </Button>

            <ul :aria-labelledby="dropdownId" class="dropdown-menu">
                <li v-for="group in navigationGroups">
                    <span @click="setValue(group)" role="button" class="dropdown-item"
                          :class="{ 'active': group === currentData[field.name], 'small': formSmall }">{{
                            group
                        }}</span>
                </li>

                <li>
                    <hr class="dropdown-divider">
                </li>

                <li>
                    <span class="dropdown-item" :class="{'small': formSmall }" @click="showOther">Other&hellip;</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "FormControlPagesNavigation",
    components: {
        Button,
    },
    data() {
        return {
            other: false,
            otherValue: ''
        }
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        dropdownId() {
            return 'dropdownMenuField' + this.field.id
        },
        parent() {
            return this.currentData.parent
        },
        navigationGroups() {
            return this.$store.state.siteTree.navigationGroups
        },
        formSmall() {
            return this.$store.state[this.formId].formSmall
        }
    },
    created() {
        this.toggleField()

        this.$store.dispatch('siteTree/setNavigationGroups')
    },
    watch: {
        parent() {
            this.toggleField()
        }
    },
    methods: {
        toggleField() {
            this.field.visibility = this.parent === 0 ? '' : 'hidden'

            if (this.parent > 0) {
                // Set it with the parent's navigation.
                this.$store.dispatch('itemData/get', {
                        tableName: 'pages',
                        id: this.parent
                    })
                    .then((o) => {
                        this.currentData[this.field.name] = o.navigation
                    })
            }
        },
        setValue(i) {
            this.currentData[this.field.name] = i

            // Removes redundant groups after their last page is moved out of them
            this.reloadNavigationGroups()
        },
        showOther() {
            this.other = true

            this.$nextTick(() => {
                this.$refs.input.select()
            })
        },
        applyOther() {
            if (this.otherValue) {
                this.currentData[this.field.name] = this.otherValue
                this.hideOther()
                this.reloadNavigationGroups()
            } else {
                console.log('aaa')
            }
        },
        hideOther() {
            this.other = false
            this.otherValue = ''

            this.$nextTick(() => {
                this.$refs.btn.$el.focus()
            })
        },
        reloadNavigationGroups() {
            setTimeout(() => {
                this.$store.dispatch('siteTree/setNavigationGroups', true)
            }, 1000)
        }
    }
}
</script>

<style scoped>

</style>