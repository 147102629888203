<template>
    <select v-model="schoolId" class="form-select form-select-sm w-auto">
        <option v-for="option in options" :value="option.value">
            {{ option.text }}
        </option>
    </select>
</template>

<script>
export default {
    name: "OdpCourseDatesSchoolsMenu",
    props: {
        viewId: String,
    },
    data() {
        return {
            options: [],
        }
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        filter() {
            return this.filters.find(o => o.columnName === 'schoolId')
        },
        filterKey() {
            return this.filters.map(o => o.columnName).indexOf('schoolId')
        },
        showArchive() {
            return this.$store.state[this.viewId].showArchive
        },
        schoolId: {
            get() {
                return parseInt(this.filter?.value) || ''
            },
            set(schoolId) {
                // If there is no active filter which filters by columnId, then add it.
                if (this.filterKey === -1) {
                    const type = 'relationshipOneToMany'
                    const option = this.$store.getters['advancedFilters/typesOptions'](type)[0]
                    this.$store.dispatch(this.viewId + '/addFilter', {
                        columnName: 'schoolId',
                        type: type,
                        condition: option.condition,
                        predicate: option.predicate,
                        conditionText: option.text
                    })
                }

                this.$store.dispatch(this.viewId + '/setFilterProp', {
                    key: this.filterKey,
                    name: 'value',
                    value: schoolId
                })
            }
        }
    },
    asyncComputed: {
        options() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/odp_course_dates',
                    params: {
                        field: 'DISTINCT schoolId',
                        schoolId: {gt: 0},
                        isArchived: this.showArchive ? 1 : 0
                    }
                })
                .then((obj) => {
                    const schoolIds = obj.data.values

                    const params = {
                        fields: ['id', 'name'],
                        id: schoolIds,
                        sort: 'name'
                    }
                    if (this.$store.state.user.user.franchiseId) {
                        params.franchiseId = this.$store.state.user.user.franchiseId
                    }

                    return this.$store.dispatch('request/get', {
                        url: 'api/component/odp_schools',
                        params: params
                    })
                })
                .then((obj) => {
                    let options = [{
                        value: '',
                        text: 'Select a school…'
                    }]

                    obj.data.items.forEach((obj) => {
                        options.push({
                            value: obj.id,
                            text: obj.name
                        })
                    })

                    return options
                })
        }
    }
}
</script>

<style scoped>

</style>