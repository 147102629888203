var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "rounded-0",
      attrs: {
        "active-class": "btn-warning",
        "active-value": _vm.isFilterActive,
      },
      nativeOn: {
        click: function ($event) {
          return _vm.toggleSearchToolbar.apply(null, arguments)
        },
      },
    },
    [_c("i", { staticClass: "bi-search" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }