<template>
    <button v-if="show" :class="[currentData.status ? 'btn-success' : 'btn-danger', {'btn-sm': formSmall}]"
            :disabled="!allowPublish" class="btn wizard-form-control-publish" type="button" @click="onClick">
        <span>{{ statusLabel }}</span>
    </button>
</template>

<script>
export default {
    name: "FormPublishBtn",
    props: {
        formId: String,
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        statusLabel() {
            return this.currentData.status ? 'Published' : 'Unpublished'
        },
        show() {
            return this.hasPublishAccess && this.currentData.status !== undefined
        },
        component() {
            return this.$store.state.components.items.find(obj => obj.tableName === this.tableName)
        },
        hasPublishAccess() {
            return this.$store.getters['user/access'](this.tableName, 'publish')
        },
        allowPublish() {
            if (this.tableName === 'pages') {
                return this.$store.state.settings.homePage !== this.id
            }

            return true
        },
        formSmall() {
            return this.$store.state[this.formId].formSmall
        }
    },
    watch: {
        formId: {
            immediate: true,
            handler() {
                if (
                    this.component.showStatus
                    && this.id === 0
                ) {
                    this.$store.dispatch(this.formId + '/setDefaultValue', {
                        name: 'status',
                        value: 0
                    })
                }
            },
        }
    },
    methods: {
        onClick() {
            // ng-disabled doesn't disable the button, we still need to check this value
            if (this.allowPublish) {
                this.currentData.status = !this.currentData.status ? 1 : 0
            }
        }
    }
}
</script>

<style scoped>

</style>