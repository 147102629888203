<template>
    <label class="d-flex align-items-center">
        <input v-model.number="pageSize" class="me-2 form-control input-sm listing-options-page-size" max="50" min="1"
               size="3" type="number">
        Page size
    </label>
</template>

<script>
export default {
    name: "ListingOptionsPageSize",
    props: {
        viewId: String,
    },
    computed: {
        pageSize: {
            get() {
                return this.$store.state[this.viewId].pageSize
            },
            set(pageSize) {
                if (pageSize <= 0 || pageSize > 50) {
                    return
                }

                this.$store.dispatch(this.viewId + '/setPageSize', pageSize)

                // Reset pagination when changing the page size
                this.$store.dispatch(this.viewId + '/setPage', 1)
            }
        },
        listingName() {
            return this.$store.state[this.viewId].listingName
        }
    }
}
</script>

<style scoped>
label {
    margin-bottom: 0;
    font-size: inherit;
}

input {
    margin-left: -5px;
    margin-right: 5px;
    padding: 2px;
    width: auto !important;
    text-align: center;
}
</style>