var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "d-flex align-items-center position-relative" },
    [
      _c("i", { staticClass: "bi bi-search" }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchTerm,
            expression: "searchTerm",
          },
        ],
        ref: "input",
        staticClass: "form-control form-control-sm rounded-5 p-0 pe-2",
        class: { active: _vm.active },
        domProps: { value: _vm.searchTerm },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.searchTerm = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }