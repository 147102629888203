<template>
    <transition name="fadeHeight">
        <div v-if="showSearchToolbar" class="d-flex flex-wrap align-items-center gap-1 border-bottom px-3 py-1">
            <SearchFilterToolbarSearchField :view-id="viewId" class="me-1"/>
            <SearchFilterToolbarFilter v-for="(filter, i) in filters" :key="i" :view-id="viewId" :off-canvas-id="offCanvasId" :filter-key="i" class="me-1"/>
            <SearchFilterToolbarFiltersBtn :view-id="viewId" :off-canvas-id="offCanvasId"/>
            <SearchFilterToolbarFilterOffcanvas :view-id="viewId" :off-canvas-id="offCanvasId"/>
        </div>
    </transition>
</template>

<script>
import SearchFilterToolbarSearchField from "./SearchFilterToolbarSearchField.vue"
import SearchFilterToolbarFiltersBtn from "./SearchFilterToolbarFiltersBtn.vue"
import SearchFilterToolbarFilterOffcanvas from "./SearchFilterToolbarFilterOffcanvas.vue"
import SearchFilterToolbarFilter from "./SearchFilterToolbarFilter.vue"

export default {
    name: "SearchFilterToolbar",
    components: {
        SearchFilterToolbarFilter,
        SearchFilterToolbarFilterOffcanvas,
        SearchFilterToolbarFiltersBtn,
        SearchFilterToolbarSearchField
    },
    props: {
        viewId: String
    },
    computed: {
        showSearchToolbar() {
            return this.$store.state[this.viewId].showSearchToolbar
        },
        filters() {
            return this.$store.state[this.viewId].filters
        },
        offCanvasId() {
            return this.viewId.replace(':', '__') + '__offcanvas'
        }
    }
}
</script>

<style scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
    transition: all 0.2s;
    max-height: 50px;
}

.fadeHeight-enter,
.fadeHeight-leave-to {
    opacity: 0;
    max-height: 0;
}
</style>