<template>
    <Button v-if="show && showText" class="d-flex align-items-center" @click.native="deleteSelectItems">
        <i class="bi-trash3 me-2"/>
        {{ text }}
    </Button>

    <Button v-else-if="show" @click.native="deleteSelectItems">
        <i class="bi-trash3"/>
        {{ text }}
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ListingOptionsDeleteBtn",
    components: {Button},
    props: {
        viewId: String,
        showText: Boolean
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        selectedIds() {
            return this.$store.state[this.viewId].selectedIds
        },
        selectedIdsLength() {
            return this.selectedIds.length
        },
        allSelected() {
            return this.$store.state[this.viewId].allSelected
        },
        allItemsSelected() {
            return this.$store.state[this.viewId].allItemsSelected
        },
        itemCount() {
            return this.$store.state[this.viewId].itemCount
        },
        showArchive() {
            return this.$store.state[this.viewId].showArchive
        },
        show() {
            return (
                (
                    this.showArchive
                    || this.useArchive === false
                )
                && this.hasVariations === false
                && this.deleteOption
                && this.selectedIdsLength
            )
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        text() {
            let count = this.selectedIdsLength
            return this.showText
                ? 'Delete ' + count + ' item' + (count > 1 ? 's' : '')
                : ''
        },
        productTypeId() {
            return parseInt(this.$route.query.productTypeId || 0)
        },
        deleteOption() {
            let component = this.$store.state.components.items.find(o => o.id === this.componentId)
            return !!component.deleteOption
        },
        useArchive() {
            let component = this.$store.state.components.items.find(o => o.id === this.componentId)
            return !!component.useArchive
        },
    },
    asyncComputed: {
        hasVariations() {
            if (
                this.tableName === 'product_options'
                && this.productTypeId
            ) {
                return this.$store.dispatch('request/post', {
                        url: 'api/ProductTypes/hasVariations',
                        postData: {
                            typeId: this.productTypeId
                        }
                    })
                    .then((obj) => {
                        return obj && obj.data.count > 0
                    })
            } else {
                return false
            }
        }
    },
    methods: {
        deleteSelectItems() {
            if (this.tableName === 'product_variables') {
                let msg = '',
                    count = this.selectedIdsLength,
                    i = 0

                // Sends a request for each selected variable to check its usage count.
                this.selectedIds.forEach((variableId) => {
                    this.$store.dispatch('request/get', {
                            url: 'api/ProductOptions/getVariablesUsageCount/' + variableId
                        })
                        .then((obj) => {
                            let data = obj.data

                            msg += data.count + ' variation uses ' + data.variableTitle + '.<br>'

                            // Once the final request has completed
                            i++
                            if (count === i) {

                                this.$store.dispatch('modals/show', {
                                    componentName: 'ConfirmModal',
                                    obj: {
                                        modalTitle: 'Deleting variables',
                                        modalBody: `
                                            <div class="alert alert-danger">
                                                Deleting variables also deletes the variations which use them.
                                                <br>
                                                <br>
                                                ` + msg + `
                                            </div>
                                            <p>
                                                This action <strong>cannot</strong> be undone.
                                            </p>
                                        `,
                                        confirmStr: 'DELETE',
                                        onConfirm: () => {
                                            this.deleteAction()
                                        }
                                    }
                                })
                            }
                        })
                })

            } else if (this.selectedIdsLength) {
                let numItems = this.allItemsSelected ? this.itemCount : this.selectedIdsLength

                this.$store.dispatch('modals/show', {
                    componentName: 'ConfirmModal',
                    obj: {
                        modalTitle: 'Deleting ' + numItems + ' item' + (numItems > 1 ? 's' : ''),
                        modalBody: `
                          <div class="alert alert-danger">
                            This action will result in loss of data.
                          </div>
                          <p>
                            This action <strong>cannot</strong> be undone.
                          </p>
                        `,
                        onConfirm: () => {
                            this.deleteAction()
                        }
                    }
                })
            }
        },
        deleteAction() {
            let onDelete = () => {
                this.$store.commit('cacheNeedsClearing')
                this.$store.commit(this.viewId + '/selectNone')
                this.$store.dispatch(this.viewId + '/scheduleLoad')
            }

            if (this.allItemsSelected) {
                // If this is a variations table then only delete variations associated to the current product.
                if (this.tableName.indexOf('product_variations__') === 0) {
                    const productId = parseInt(this.$route.query.productId || '')

                    if (!productId) {
                        this.$store.dispatch('toasts/add', {
                            body: 'A product must be specified.'
                        })
                        return
                    }

                    // Load the product's variation IDs.
                    this.$store.dispatch('request/get', {
                            url: 'api/component/' + this.tableName,
                            params: {
                                field: 'id',
                                productId: productId
                            }
                        })
                        .then((obj) => {
                            if (obj.data.values.length) {
                                // Because there could be a huge number of IDs we must submit the ids as a post
                                // request using X-Http-Method-Override to convert it server side back to a delete
                                // request. Symfony automatically does the conversion.
                                this.$store.dispatch('request/post', {
                                        url: 'api/component/' + this.tableName,
                                        postData: {
                                            ids: obj.data.values,
                                            fastDelete: true,
                                        },
                                        customHeaders: {
                                            'X-Http-Method-Override': 'DELETE'
                                        }
                                    })
                                    .then(onDelete)
                            } else {
                                onDelete()
                            }
                        })
                } else {
                    console.error('Bulk delete for non-variations components not configured.')
                    return
                    let autoIncrement = confirm('Do you wish to reset the IDs?')
                    console.log('bool', bool)
                    return
                    this.$store.dispatch('request/post', {
                            url: 'api/component/' + this.tableName,
                            postData: {
                                deleteAll: true,
                                autoIncrement: autoIncrement
                            }
                        })
                        .then(onDelete)
                }

            } else {
                this.$store.dispatch('request/delete', {
                        url: 'api/component/' + this.tableName,
                        params: {
                            ids: this.selectedIds
                        }
                    })
                    .then(onDelete)
            }
        }
    }
}
</script>

<style scoped>

</style>