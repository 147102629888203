var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _vm.oldIds?.length
      ? _c(
          "div",
          _vm._l(_vm.oldIds, function (id) {
            return _c("FormControlCategoryTitle", {
              key: id,
              staticClass: "d-block mb-1 border rounded py-1 px-2 small",
              attrs: { "table-name": _vm.tableName, id: id },
            })
          }),
          1
        )
      : _c("i", { staticClass: "small" }, [_vm._v("none")]),
    _vm._v(" "),
    _c("i", { staticClass: "mx-2 bi-chevron-right" }),
    _vm._v(" "),
    _vm.newIds?.length
      ? _c(
          "div",
          _vm._l(_vm.newIds, function (id) {
            return _c("FormControlCategoryTitle", {
              key: id,
              staticClass: "d-block mb-1 border rounded py-1 px-2 small",
              attrs: { "table-name": _vm.tableName, id: id },
            })
          }),
          1
        )
      : _c("i", { staticClass: "small" }, [_vm._v("none")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }