<template>
    <div class="d-flex align-items-center">
        <div v-if="oldIds?.length">
            <FormControlCategoryTitle v-for="id in oldIds" :key="id" :table-name="tableName" :id="id" class="d-block mb-1 border rounded py-1 px-2 small"/>
        </div>
        <i v-else class="small">none</i>

        <i class="mx-2 bi-chevron-right"/>

        <div v-if="newIds?.length">
            <FormControlCategoryTitle v-for="id in newIds" :key="id" :table-name="tableName" :id="id" class="d-block mb-1 border rounded py-1 px-2 small"/>
        </div>
        <i v-else class="small">none</i>
    </div>
</template>

<script>
import FormControlCategoryTitle from '../../common/form-control/FormControlCategoryTitle'

export default {
    name: "ItemHistoryChangeTypeRelationshipManyToMany",
    components: {
        FormControlCategoryTitle
    },
    props: {
        oldValue: [Array, String],
        newValue: [Array, String],
        categoryComponentId: Number
    },
    computed: {
        component() {
            return this.$store.state.components.items.find(o => o.id === this.categoryComponentId)
        },
        tableName() {
            return this.component?.tableName
        },
        oldIds() {
            // Support for values provided as ID strings e.g. [x, y, z]
            return typeof this.oldValue === 'string' ? JSON.parse(this.oldValue) : this.oldValue
        },
        newIds() {
            return typeof this.newValue === 'string' ? JSON.parse(this.newValue) : this.newValue
        }
    }
}
</script>

<style scoped>

</style>