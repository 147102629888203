var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    {
      staticClass: "border-end overflow-y-scroll",
      class: { open: _vm.activeTab, closed: !_vm.activeTab },
    },
    [
      _c("section", { staticClass: "d-flex h-100" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column",
            staticStyle: { "margin-top": "-1px" },
          },
          [
            _vm._l(_vm.tabs, function (tab) {
              return _c("PageEditorContentPanelTabBtn", {
                key: tab.name,
                attrs: {
                  "icon-class": tab.icon,
                  "tab-name": tab.name,
                  "active-tab-prop-name": "leftSidebarActiveTab",
                  "border-class-name": "border-end",
                },
              })
            }),
            _vm._v(" "),
            _c("div", { staticClass: "flex-fill bg-body-tertiary border-end" }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.activeTab
          ? _c(
              "div",
              { staticClass: "flex-fill overflow-scroll" },
              [
                _vm.activeTab === "Site tree"
                  ? [_c("PageEditorSiteTree")]
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeTab === "Content"
                  ? _c("PageEditorContentNav")
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }