var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "d-flex border-bottom", on: { click: _vm.formScrollTop } },
    [
      _c(
        "div",
        { staticClass: "flex-fill d-flex align-items-center" },
        [
          _c("ShowSiteTreeBtn", {
            attrs: {
              "table-name": _vm.tableName,
              id: _vm.id,
              "site-tree-id": "siteTreeModal",
            },
          }),
          _vm._v(" "),
          _vm.show
            ? _c("FormAddBtn", { attrs: { "form-id": _vm.formId } })
            : _vm._e(),
          _vm._v(" "),
          _c("FormDisplayTitle", { attrs: { "form-id": _vm.formId } }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSaveBtn
        ? _c("FormSaveBtn", {
            staticClass: "rounded-0",
            attrs: { "form-id": _vm.formId },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSaveAndCloseBtn
        ? _c("FormSaveCloseBtn", { attrs: { "form-id": _vm.formId } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }