<template>
    <FormSidebarBtn v-if="show" @click.prevent.native="onClick" class="text-warning-emphasis">
        <i class="me-2 bi bi-x-lg"/>
        Detach from page
    </FormSidebarBtn>
</template>

<script>
import FormSidebarBtn from "./FormSidebarBtn.vue"

export default {
    name: "PageEditorDetachFromPageBtn",
    components: {FormSidebarBtn},
    props: {
        formId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        isPageEditor() {
            return this.$route.name === 'pageEditor'
        },
        show() {
            return this.isPageEditor && this.tableName === 'content'
        },
        activePageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch('pageEditor/detachContent', this.activePageContentId)
        }
    },
}
</script>

<style scoped>

</style>