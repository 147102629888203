<template>
    <!-- The email type cannot use type="email" else the value is null until the field validates -->
    <input v-if="isActive(['currency', 'email', 'tel', 'text', 'textarea', 'texteditor', 'url'])"
           @keyup.enter="closeOffcanvas" v-model="value" class="form-control form-control-sm"/>

    <input v-else-if="isActive(['number'])" @keyup.enter="closeOffcanvas" type="number" v-model="value"
           class="form-control form-control-sm w-auto"/>

    <select v-else-if="isActive(['checkbox'])" v-model="value" class="form-select form-select-sm w-auto">
        <option v-for="option in checkboxOptions" :value="option.value">
            {{ option.text }}
        </option>
    </select>

    <input v-else-if="isActive(['color'])" v-model="value" class="form-control form-control-sm" maxlength="7"
           minlength="7" placeholder="#000000" size="8">

    <input v-else-if="isActive(['date'])" v-model="value" type="datetime-local" class="form-control w-auto">

    <input v-else-if="isActive(['dateDate'])" v-model="value" type="date" class="form-control form-control-sm">

    <input v-else-if="isActive(['datetime-local'])" v-model="value" type="datetime-local"
           class="form-control form-select-sm">
    <!-- todo
    <span v-if="isActive(['dateRange'])">
        <input
                class="form-control"
                v-model="value.from"
                placeholder="dd/mm/yyyy"
                size="12"
                type="text"
        >
        to
        <input
                class="form-control"
                v-model="value.to"
                placeholder="dd/mm/yyyy"
                size="12"
                type="text"
        >
    </span>
    -->

    <SearchFilterFieldInternalLink v-else-if="isActive(['internalLink'])" :view-id="viewId" :filter-key="filterKey"
                                   style="display: flex"/>

    <select v-else-if="isActive(['password'])" v-model="value" class="form-select form-select-sm">
        <option v-for="option in passwordOptions" :value="option.value">
            {{ option.text }}
        </option>
    </select>

    <input v-else-if="isActive(['range'])" v-model="value" class="form-control form-control-sm" type="number"/>

    <SearchFilterFieldCategory v-else-if="isActiveRelationshipField" v-model="value" :view-id="viewId"
                               :filter-key="filterKey"/>

    <SearchFilterFieldSelect v-else-if="isActive(['select'])" :view-id="viewId" :filter-key="filterKey"/>

    <input v-else-if="isActive(['time'])" v-model="value" class="form-control form-control-sm" type="time">
</template>

<script>
import SearchFilterFieldInternalLink from './SearchFilterFieldInternalLink'
import SearchFilterFieldSelect from './SearchFilterFieldSelect'
import SearchFilterFieldCategory from './SearchFilterFieldCategory'

export default {
    name: "SearchFilterField",
    components: {
        SearchFilterFieldInternalLink,
        SearchFilterFieldSelect,
        SearchFilterFieldCategory,
    },
    props: {
        viewId: String,
        filterKey: Number
    },
    data() {
        return {
            checkboxOptions: [
                {text: 'Select…'},
                {value: 1, text: 'Checked'},
                {value: 0, text: 'Not checked'}
            ],
            passwordOptions: [
                {text: 'Select…'},
                {value: 'is set', text: 'Set'},
                {value: 'is not set', text: 'Not set'}
            ]
        }
    },
    computed: {
        filter() {
            return this.$store.state[this.viewId].filters[this.filterKey]
        },
        value: {
            get() {
                let value = this.filter.value

                // Fix for when the ID is set via a query string e.g. ?courseId=4
                // todo This should ideally be handled by the view module
                if (this.filter.type === 'relationshipOneToMany') {
                    value = parseInt(value) || 0
                }

                return value
            },
            set(val) {
                this.$store.dispatch(this.viewId + '/setFilterProp', {
                    key: this.filterKey,
                    name: 'value',
                    value: val
                })
            }
        },
        isActiveRelationshipField() {
            if (
                this.filter.type === 'relationshipOneToMany'
                && (
                    this.filter.condition === 'is associated to'
                    || this.filter.condition === 'is not associated to'
                )
            ) {
                return true
            }

            if (
                this.filter.type === 'relationshipManyToMany'
                && (
                    this.filter.condition === 'is associated to many'
                    || this.filter.condition === 'is not associated to many'
                )
            ) {
                return true
            }

            return false
        }
    },
    methods: {
        isActive(types) {
            return types.indexOf(this.filter.type) > -1
                && this.filter.condition !== 'is set'
                && this.filter.condition !== 'is not set'

        },
        closeOffcanvas() {
            document.getElementById('offcanvasFilters').querySelector('[data-bs-dismiss="offcanvas"]').click()
        }
    }
}
</script>

<style scoped>

</style>