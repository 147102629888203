<template>
    <div>
        <FormControlPlainText v-if="currentData.id && !betaMode" :value="templateTitle" :form-id="formId"/>
        <FormControlTypeSelect v-else-if="options" :current-data="currentData" :field="field" :form-id="formId"
                               :options="options"/>

        <PageContentTemplateContent v-if="currentData.templateId" :template-id="currentData.templateId"/>
    </div>
</template>

<script>
import FormControlTypeSelect from '../form-control-type/FormControlTypeSelect'
import PageContentTemplateContent from "../page-content/PageContentTemplateContent.vue"
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlPagesTemplateId",
    components: {
        FormControlPlainText,
        PageContentTemplateContent,
        FormControlTypeSelect,
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    computed: {
        id() {
            return this.$store.state[this.formId].id
        },
        pageTemplates() {
            return this.$store.getters['templates/pageTemplates']
        },
        options() {
            if (this.pageTemplates.length) {
                let options = [{
                    value: '',
                    text: 'Select…'
                }]

                this.pageTemplates.forEach((o) => {
                    options.push({
                        value: o.id,
                        text: o.title
                    })
                })

                return options
            }
        },
        betaMode() {
            return this.$store.state.betaMode
        }
    },
    asyncComputed: {
        templateTitle() {
            const id = this.currentData[this.field.name]
            if (id) {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'templates_templates',
                        id: id
                    })
                    .then(o => o.title)
            }
        }
    },
    created() {
        this.$store.dispatch('templates/init')
    }
}
</script>

<style scoped>

</style>