<template>
    <button v-if="hasThumbnailColumn" class="d-flex align-items-center" type="button" @click="changeView">
        <i
            :class="{'bi-grid-3x3-gap-fill': viewMode === 'table', 'bi-list': viewMode === 'thumbnail'}"
            class="me-2"
        />
        {{ viewMode === 'thumbnail' ? 'List' : 'Thumbnail' }} view
    </button>
</template>

<script>
export default {
    name: "ListingOptionsViewMode",
    props: {
        viewId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        component() {
            return this.$store.state.components.items.filter(obj => obj.tableName === this.tableName)[0]
        },
        hasThumbnailColumn() {
            return !!this.component.thumbnailColumn
                // The "files" column doesn't have a thumbnail column
                || this.tableName === 'files'
        },
        viewMode() {
            return this.$store.state[this.viewId].viewMode
        },
    },
    methods: {
        changeView() {
            const viewMode = this.viewMode === 'thumbnail' ? 'table' : 'thumbnail'
            this.$store.dispatch(this.viewId + '/setViewMode', viewMode)
        }
    }
}
</script>

<style scoped>

</style>