import { render, staticRenderFns } from "./PageEditorContentForm.vue?vue&type=template&id=189fe647&scoped=true&"
import script from "./PageEditorContentForm.vue?vue&type=script&lang=js&"
export * from "./PageEditorContentForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189fe647",
  null
  
)

export default component.exports