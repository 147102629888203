var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.other
      ? _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.otherValue,
                      expression: "otherValue",
                    },
                  ],
                  ref: "input",
                  staticClass: "form-control",
                  class: {
                    "form-control-sm": _vm.formSmall,
                    "is-invalid": _vm.field.error,
                  },
                  attrs: { type: "text", placeholder: "Submit your new value" },
                  domProps: { value: _vm.otherValue },
                  on: {
                    keyup: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        return _vm.hideOther.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.applyOther.apply(null, arguments)
                      },
                    ],
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.otherValue = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "Button",
                  {
                    class: {
                      "btn-sm": _vm.formSmall,
                      "btn-warning": _vm.otherValue.length > 0,
                    },
                    attrs: { "default-class": "btn-primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.applyOther.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "bi-arrow-return-left" })]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "Button",
              {
                class: { "btn-sm": _vm.formSmall },
                attrs: { "default-class": "btn-link" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.hideOther.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "bi-x-lg" })]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.other,
            expression: "!other",
          },
        ],
        staticClass: "dropdown",
      },
      [
        _c(
          "Button",
          {
            ref: "btn",
            staticClass: "dropdown-toggle border",
            class: { "btn-sm": _vm.formSmall },
            attrs: {
              id: _vm.dropdownId,
              "aria-expanded": "true",
              "data-bs-toggle": "dropdown",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.currentData[_vm.field.name]) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          {
            staticClass: "dropdown-menu",
            attrs: { "aria-labelledby": _vm.dropdownId },
          },
          [
            _vm._l(_vm.navigationGroups, function (group) {
              return _c("li", [
                _c(
                  "span",
                  {
                    staticClass: "dropdown-item",
                    class: {
                      active: group === _vm.currentData[_vm.field.name],
                      small: _vm.formSmall,
                    },
                    attrs: { role: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setValue(group)
                      },
                    },
                  },
                  [_vm._v(_vm._s(group))]
                ),
              ])
            }),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                {
                  staticClass: "dropdown-item",
                  class: { small: _vm.formSmall },
                  on: { click: _vm.showOther },
                },
                [_vm._v("Other…")]
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("hr", { staticClass: "dropdown-divider" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }