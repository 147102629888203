<script>
import FormControlTypeText from "../form-control-type/FormControlTypeText.vue"

export default {
    name: "FormControlContentTitle",
    extends: FormControlTypeText,
    computed: {
        pageEditorMode() {
            return this.$store.state.pageEditorMode
        }
    },
    created() {
        //if (this.$route.name === 'siteTree') {
            this.field.required = !this.pageEditorMode
        //}
    }
}
</script>

<style scoped>

</style>