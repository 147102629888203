<template>
    <div v-if="showSelectAllMessage" class="ms-3 mb-2 py-1">
        <strong>
            <template v-if="allItemsSelected">
                All {{ itemCount }} items are selected.
                <button class="ms-1 btn btn-light btn-sm" type="button" @click="clearAllItemsSelection">
                    Clear selection
                </button>
            </template>

            <template v-else>
                {{ ids.length }} of {{ itemCount }} items are selected.
                <button class="ms-1 btn btn-light btn-sm" type="button" @click="selectAllItems">
                    Select all {{ itemCount }} items
                </button>
            </template>
        </strong>
    </div>
</template>

<script>
export default {
    name: "ListingSelectAll",
    props: {
        viewId: String,
    },
    computed: {
        listingName() {
            return this.$store.state[this.viewId].listingName
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        allSelected() {
            return this.$store.state[this.viewId].allSelected
        },
        ids() {
            return this.$store.state[this.viewId].ids
        },
        itemCount() {
            return this.$store.state[this.viewId].itemCount
        },
        allItemsSelected() {
            return this.$store.state[this.viewId].allItemsSelected
        },
        showSelectAllMessage() {
            return (
                this.tableName?.indexOf('product_variations__') === 0
                && this.allSelected
                && this.ids.length < this.itemCount
            )
        }
    },
    methods: {
        selectAllItems() {
            this.$store.commit(this.viewId + '/selectAllItems')
        },
        clearAllItemsSelection() {
            this.$store.commit(this.viewId + '/selectNone')
        }
    }
}
</script>

<style scoped>

</style>