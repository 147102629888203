<template>
    <figure v-if="item && show" :class="{ 'active': selectedIds.indexOf(item.id) !== -1 }" @dblclick="onDblClick"
            @click.left.exact="onClick" @click.meta="onMetaClick" @click.shift="onShiftClick" role="button">
        <Picture v-if="file.id && showThumbnail" :alt="item[nameField]" :file-id="file.id" :on-click="showLightbox"
                 :sizes="'17vw'"/>
        <div v-else-if="file.id && !showThumbnail" class="text-center">
            not an image
        </div>
        <div v-else class="text-center">
            no file
        </div>

        <figcaption class="text-center">
            <small>{{ item[nameField] }}</small>
        </figcaption>
    </figure>
</template>

<script>
export default {
    name: "GridViewThumbnail",
    props: {
        viewId: String,
        id: Number,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        selectMode() {
            return this.$store.state[this.viewId].selectMode
        },
        selectedIds() {
            return this.$store.state[this.viewId].selectedIds
        },
        allSelected() {
            return this.$store.state[this.viewId].allSelected
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        show() {
            return this.item && this.file
        },
        isImage() {
            return (
                this.file.mimetype
                && this.file.mimetype.indexOf('image') === 0 // e.g. image/jpeg image/gif
            )
        },
        showThumbnail() {
            return (
                this.isImage
                // todo - CloudImage can show PDFs so enabling this will present the PDF.
                //        I've not enabled it because the Lightbox fails to present the PDF.
                || this.item.mimetype === 'application/pdf'
            )
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.componentId)
        },
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.id
                })
                .then((obj) => {
                    return obj
                })
        },
        file() {
            if (this.item) {
                if (this.tableName === 'files') {
                    return new Promise((resolve) => {
                        resolve(this.item)
                    })
                }

                let component = this.$store.state.components.items.find(o => o.id === this.componentId)
                let fileId = this.item[component.thumbnailColumn]
                return this.$store.dispatch('itemData/get', {
                        tableName: 'files',
                        id: fileId
                    })
                    .then((obj) => {
                        return obj
                    })
            }
        }
    },
    watch: {
        file(obj) {
            if (
                obj
                && obj.fileName
                && obj.mimetype
                && obj.mimetype.indexOf('image') === 0
            ) {
                let src = this.$store.getters.fileSrc(obj.directory, obj.fileName)
                this.$store.commit('lightbox/add', {
                    id: this.item.id,
                    title: this.title,
                    src: src,
                    onShow: this.onClick
                })
            }
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch(this.viewId + '/onClick', this.item.id)
        },
        onDblClick() {
            this.$store.dispatch(this.viewId + '/onDblClick')
                .then((hasAccess) => {
                    if (hasAccess) {
                        this.$router.push({
                            name: 'form',
                            params: {id: this.id},
                            query: this.$route.query
                        })
                    }
                })
        },
        onShiftClick() {
            this.$store.commit(this.viewId + '/onShiftClick', this.item.id)
        },
        onMetaClick() {
            this.$store.commit(this.viewId + '/onMetaClick', this.item.id)
        },
        showLightbox() {
            this.$store.dispatch('lightbox/show', this.item.id)
        }
    }
}
</script>

<style scoped>
figure {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
    overflow: hidden;
    border-radius: 0;
    height: 100%;
}

figure:hover {
    background-color: #eee;
}

figure.active,
figure.active:hover {
    background-color: #ffcb4a;
}

figure > a {
    flex: auto;
}

figcaption {
    padding-bottom: 3px;
}
</style>