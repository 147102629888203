<template>
    <Button v-if="show" class="rounded-0" @click.native="onClick">
        <i class="bi-plus-lg"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ListingOptionsAddBtn",
    components: {Button},
    props: {
        viewId: String
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        addNew() {
            let component = this.$store.state.components.items.find(o => o.id === this.componentId)
            return !!component.addNew
        },
        show() {
            return (
                this.hasAccess
                && !this.$store.state[this.viewId].showArchive
                && this.tableName !== 'm_basket_orders'
                && this.addNew
            )
        },
        productTypeId() {
            if (this.tableName === 'product_options') {
                return parseInt(this.$route.query.productTypeId || 0)
            }
        },
        hasAddAccess() {
            return this.$store.getters['user/access'](this.tableName, 'add')
        },
        hasAccess() {
            return this.hasAddAccess && !this.hasVariations
        }
    },
    asyncComputed: {
        hasVariations() {
            if (this.productTypeId) {
                return this.$store.dispatch('request/post', {
                        url: 'api/ProductTypes/hasVariations',
                        postData: {
                            typeId: this.productTypeId
                        }
                    })
                    .then((obj) => {
                        return obj.data.count > 0
                    })
            }
        }
    },
    methods: {
        onClick() {
            this.$router.push({
                name: 'form',
                params: {id: 0},
                query: this.$route.query
            })
        }
    },
}
</script>

<style scoped>

</style>