<template>
    <div>
        <div class="mb-3" v-if="currentData.createdDate">
            <label class="form-sidebar-label">Created</label>

            <div class="d-flex align-items-start">
                <UserAvatar v-if="currentData.createdBy" :user-id="currentData.createdBy" :is-small="false"
                            :tooltip-placement="'right'" class="mt-1 me-2"/>

                <small>
                    <UserName v-if="currentData.createdBy" :id="currentData.createdBy" :no-user-found-str="''"/>
                    <br>
                    {{ getDate(currentData.createdDate * 1000) }}
                </small>
            </div>
        </div>

        <div class="mb-3" v-if="currentData.modifiedDate">
            <label class="form-sidebar-label">Modified</label>

            <div class="d-flex align-items-start">
                <UserAvatar v-if="currentData.modifiedBy" :user-id="currentData.modifiedBy" :is-small="false"
                            :tooltip-placement="'right'" class="mt-1 me-2"/>

                <small>
                    <UserName v-if="currentData.modifiedBy" :id="currentData.modifiedBy" :no-user-found-str="'<em>user not found</em>'"/>
                    <br>
                    {{ getDate(currentData.modifiedDate * 1000) }}
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import UserName from '../../common/UserName'
import UserAvatar from '../../common/UserAvatar'

export default {
    name: "FormCreatedModified",
    components: {UserName, UserAvatar},
    props: {
        formId: String
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        }
    },
    methods: {
        getDate(timestamp) {
            return moment(timestamp).format('DD MMM YYYY @ HH:mm')
        }
    }
}
</script>

<style scoped>
.form-sidebar-label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    color: #999 !important;
}
</style>