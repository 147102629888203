<script>
import FormControlTypeSelect from "../form-control-type/FormControlTypeSelect.vue"

export default {
    name: "FormControlPagesType",
    extends: FormControlTypeSelect,
    created() {
        if (this.currentData.id) {
            this.field.readonly = true
        }
    }
}
</script>

<style scoped>

</style>