<template>
    <button v-if="isSet" class="btn btn-link btn-sm focus-ring" type="button" @click="onClick">
        <i class="bi-x-lg"/>
    </button>
</template>

<script>
export default {
    name: "SearchFilterItemResetBtn",
    props: {
        viewId: String,
        filterKey: Number
    },
    computed: {
        filter() {
            return this.$store.state[this.viewId].filters[this.filterKey]
        },
        typeOptions() {
            return this.$store.getters['advancedFilters/typesOptions'](this.filter.type)
        },
        option() {
            return this.typeOptions.find(o => o.predicate === this.filter.predicate)
        },
        isSet() {
            return this.filter.value !== undefined && this.filter.value !== ''
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch(this.viewId + '/setFilterProp', {
                key: this.filterKey,
                name: 'value',
                value: undefined
            })

            if (this.option.value !== undefined) {
                const defaultOption = this.typeOptions[0]

                this.$store.dispatch(this.viewId + '/setFilterProp', {
                    key: this.filterKey,
                    name: 'condition',
                    value: defaultOption.condition
                })

                this.$store.dispatch(this.viewId + '/setFilterProp', {
                    key: this.filterKey,
                    name: 'conditionText',
                    value: defaultOption.text
                })
            }
        }
    }
}
</script>

<style scoped>

</style>