<template>
    <button class="btn btn-light btn-sm" type="button" @click="addFilter">
        <i class="bi-plus-lg"/> filter
    </button>
</template>

<script>
export default {
    name: "SearchFilterAddFilterBtn",
    props: {
        viewId: String
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        }
    },
    methods: {
        addFilter() {
            this.$store.dispatch(this.viewId + '/addFilter')
        }
    }
}
</script>

<style scoped>

</style>