<template>
    <Button :disabled="isLast" class="rounded-0" @click.native="onClick">
        <i class="bi-chevron-right"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ListingOptionsNextBtn",
    components: {Button},
    props: {
        viewId: String
    },
    computed: {
        listingName() {
            return this.$store.state[this.viewId].listingName
        },
        pageSize() {
            return this.$store.state[this.viewId].pageSize
        },
        itemCount() {
            return this.$store.state[this.viewId].itemCount
        },
        page() {
            return this.$store.state[this.viewId].page
        },
        isLast() {
            return this.page >= Math.ceil(this.itemCount / this.pageSize)
        }
    },
    methods: {
        onClick() {
            if (!this.isLast) {
                this.$store.dispatch(this.viewId + '/pageUp')
            }
        }
    }
}
</script>

<style scoped>

</style>