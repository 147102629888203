var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "form form-inline",
      attrs: { role: "search" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    _vm._l(_vm.filters, function (filter, i) {
      return _c("SearchFilterItem", {
        key: i,
        attrs: { "view-id": _vm.viewId, filterKey: i },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }