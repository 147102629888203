<template>
    <select v-model="currentData[field.name]" class="form-select w-auto">
        <option value="">Select…</option>
        <optgroup v-for="(group, name) in optionGroups" :label="name">
            <option v-for="(props, value) in group" :value="value">
                {{ props.title }}
            </option>
        </optgroup>
    </select>
</template>

<script>
export default {
    name: "FormControlTemplatesTemplatesDetailsPageLayoutLayout",
    props: {
        currentData: Object,
        field: Object,
    },
    data() {
        return {
            optionGroups: this.$store.state.pageContentLayouts.layouts,
        }
    },
    created() {
        if (
            this.currentData.contentType !== 'Listing'
            || this.currentData.componentId === 0
        ) {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>