var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type
    ? _c("div", { staticClass: "d-flex align-items-top" }, [
        _c("small", { staticClass: "mt-1 me-2 w-25" }, [
          _vm._v(_vm._s(_vm.label)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-75" },
          [
            _c(
              _vm.componentName,
              _vm._b(
                { tag: "component" },
                "component",
                _vm.componentProps,
                false
              )
            ),
            _vm._v(" "),
            !_vm.componentName && _vm.type !== "hidden"
              ? [
                  _vm.tableName === "pages" && _vm.property === "content"
                    ? _c("div", [
                        _c("div", { staticClass: "badge bg-secondary" }, [
                          _vm._v("changed"),
                        ]),
                      ])
                    : _c("div", { staticClass: "d-flex align-items-center" }, [
                        _vm.oldValue
                          ? _c("small", {
                              staticClass: "border rounded py-1 px-2",
                              domProps: { innerHTML: _vm._s(_vm.oldValue) },
                            })
                          : _c("i", { staticClass: "small" }, [
                              _vm._v("empty"),
                            ]),
                        _vm._v(" "),
                        _c("i", { staticClass: "mx-2 bi-chevron-right" }),
                        _vm._v(" "),
                        _vm.newValue
                          ? _c("small", {
                              staticClass: "border rounded py-1 px-2",
                              domProps: { innerHTML: _vm._s(_vm.newValue) },
                            })
                          : _c("i", { staticClass: "small" }, [
                              _vm._v("empty"),
                            ]),
                      ]),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _vm.type !== "password"
          ? _c(
              "button",
              {
                staticClass:
                  "position-absolute end-0 top-0 mt-2 me-2 btn btn-secondary btn-sm visible-on-hover-hidden",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.restore(_vm.property, _vm.oldValue)
                  },
                },
              },
              [_vm._v("\n        restore\n    ")]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }