<template>
    <div v-if="page">
        <div class="d-flex align-items-center mb-1" :class="{ 'page-selection-mode': selectedPageIds }">

            <Button :disabled="!hasChildPages && !hasContent" class="btn-sm p-0">
                <i @click="togglePage(pageId)"
                   :class="{ 'bi-chevron-down': isOpen, 'bi-chevron-right': !isOpen, 'invisible': !hasChildPages && !hasContent }"/>
            </Button>

            <span v-if="selectedPageIds" class="me-2" @click="updateSelectedPageIds"
                  :class="{ 'bi-square': !isChecked, 'bi-check-square': isChecked }"/>

            <Button class="btn-sm py-0 px-1 w-100 text-start d-flex align-items-center"
                    @click.prevent.native="onSelectPage" :active-class="'btn-primary'"
                    :active-value="isLastSelected || isSelectedContentsPageId || isSelected">

                <span class="flex-fill">{{ page.title }}</span>

                <small class="text-body-secondary">{{ page.pageLabel }}</small>
                <i v-if="isRestricted" class="mx-1 bi-person-fill" title="This page is assigned group access"/>
                <i v-if="isHomePage" class="mx-1 bi-house" title="Home page"/>
                <i v-if="page.hidden" class="mx-1 bi-eye-slash-fill"/>
                <i v-if="isUnpublished" class="mx-1 bi-x-circle-fill" :class="{'text-danger':!isSelected}"/>

                <i v-if="isScheduled" :title="schedulingTitle" class="bi-clock-fill"
                   :class="{ 'text-success': isPending, 'text-warning': isExpiring, 'text-danger': isExpired }"/>
            </Button>
        </div>

        <template v-if="hasContent && isOpen && showContent">
            <SiteTreeContentAreas :page-id="pageId" :select-content="selectContent" :site-tree-id="siteTreeId"/>
        </template>

        <div v-if="showChildPages" class="ms-3">
            <SiteTreePageItem v-for="pageId in parentChildIds[pageId]" :key="pageId" :page-id="pageId"
                              :select-content="selectContent" :select-page="selectPage"
                              :selected-page-ids="selectedPageIds" :show-content="showContent"
                              :site-tree-id="siteTreeId"/>
        </div>
    </div>
</template>

<script>
import SiteTreeContentAreas from './SiteTreeContentAreas'
import Button from "../Button.vue"

export default {
    name: "SiteTreePageItem",
    components: {
        Button,
        SiteTreeContentAreas
    },
    props: {
        siteTreeId: String,
        pageId: Number,
        selectedPageIds: Array,
        selectPage: Function,
        selectContent: Function,
        showContent: Boolean
    },
    computed: {
        parentChildIds() {
            return this.$store.state[this.siteTreeId]?.parentChildIds
        },
        expandedPageIds() {
            return this.$store.state[this.siteTreeId]?.expandedPageIds
        },
        selectedPageId() {
            return this.$store.state[this.siteTreeId]?.selectedPageId
        },
        selectedContentId() {
            return this.$store.state[this.siteTreeId]?.selectedContentId
        },
        selectedContentsPageId() {
            if (this.selectedContentId) {
                return this.$store.state.pageContentData.items.find((obj) => obj.id === this.selectedContentId).pageId
            }
        },
        lastSelectedPageId() {
            return this.$store.state[this.siteTreeId]?.lastSelectedPageId
        },
        hasChildPages() {
            return (
                this.parentChildIds[this.pageId]
                && this.parentChildIds[this.pageId].length
            )
        },
        schedulingTitle() {
            switch (true) {
                case this.isPending:
                    return 'Pending'
                case this.isExpiring:
                    return 'Expiring'
                case this.isExpired:
                    return 'Expired'
            }
        },
        isSelected() {
            return this.selectedPageId === this.pageId
        },
        isChecked() {
            if (this.selectedPageIds) {
                return this.selectedPageIds.indexOf(this.pageId) > -1
            }
        },
        isSelectedContentsPageId() {
            return this.selectedContentsPageId === this.pageId
        },
        isOpen() {
            return this.expandedPageIds.indexOf(this.pageId) > -1
        },
        showChildPages() {
            return (
                this.parentChildIds[this.pageId]
                && this.parentChildIds[this.pageId].length
                && this.isOpen
            )
        },
        isHomePage() {
            return (
                this.homePageId
                && this.homePageId === this.pageId
            )
        },
        isUnpublished() {
            return this.page.status === 0
        },
        isRestricted() {
            return this.page.groupAccess?.length
        },
        isLastSelected() {
            return (
                (
                    this.selectedPageId === 0
                    // Highlights the previously selected page when editing content in the site tree.
                    // todo - This was erroneously targeting this.selectedContentId, so wasn't doing anything.
                    //|| this.siteTree.selectedContentId
                )
                && this.lastSelectedPageId === this.pageId
            )
        },
        isScheduled() {
            return (this.isPending || this.isExpiring || this.isExpired)
        },
        isPending() {
            let now = Date.now() / 1000
            return (
                this.page
                && this.page.validFrom
                && this.page.validFrom >= now
            )
        },
        isExpiring() {
            let now = Date.now() / 1000
            return (
                this.page
                && this.page.validTo
                && this.page.validTo >= now
            )
        },
        isExpired() {
            let now = Date.now() / 1000
            return (
                this.page
                && this.page.validTo
                && this.page.validTo <= now
            )
        },
        homePageId() {
            return this.$store.state.settings.homePage
        },
        hasContent() {
            if (this.showContent) {
                const objs = this.$store.state.pageContentData.items.filter(o => o.pageId === this.pageId)
                return objs.length
            }
        }
    },
    asyncComputed: {
        page() {
            return this.$store.dispatch('itemData/get', {
                    tableName: 'pages',
                    id: this.pageId
                })
                .then((obj) => {
                    return obj
                })
        }
    },
    methods: {
        onSelectPage() {
            // Site tree select mode
            if (this.selectedPageIds) {
                this.updateSelectedPageIds()
            } else if (this.selectPage) {
                this.selectPage(this.pageId)
            }
        },
        updateSelectedPageIds() {
            let i = this.selectedPageIds.indexOf(this.pageId)
            i === -1
                ? this.selectedPageIds.push(this.pageId)
                : this.selectedPageIds.splice(i, 1)

            // The values must be sorted to ensure that they can be compared
            this.selectedPageIds.sort()
        },
        togglePage(pageId, value) {
            if (pageId) {
                let i = this.expandedPageIds.indexOf(pageId)

                if (value === undefined) {
                    value = i === -1
                }

                if (value) {
                    if (i === -1) {
                        this.$store.commit(this.siteTreeId + '/addExpandedPageId', pageId)
                    }
                } else {
                    this.expandedPageIds.splice(i, 1)
                }
            }
        }
    }
}
</script>

<style scoped>
.page-selection-mode {
    min-height: 35px;
}
</style>