var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "btn-sm",
      nativeOn: {
        click: function ($event) {
          return _vm.toggleSiteTreePin.apply(null, arguments)
        },
      },
    },
    [
      _c("i", {
        staticClass: "bi",
        class: {
          "bi-pin": !_vm.isSiteTreePinned,
          "bi-pin-angle-fill": _vm.isSiteTreePinned,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }