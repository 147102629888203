var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _vm.oldValue
        ? _c("FormControlCategoryTitle", {
            staticClass: "border rounded py-1 px-2 small",
            attrs: { "table-name": _vm.tableName, id: _vm.oldValue },
          })
        : _c("i", [_vm._v("none")]),
      _vm._v(" "),
      _c("i", { staticClass: "mx-2 bi-chevron-right" }),
      _vm._v(" "),
      _vm.newValue
        ? _c("FormControlCategoryTitle", {
            staticClass: "border rounded py-1 px-2 small",
            attrs: { "table-name": _vm.tableName, id: _vm.newValue },
          })
        : _c("i", [_vm._v("none")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }