<template>
    <div class="d-flex align-items-center">
        <i :class="class1"/>
        <i class="mx-2 bi-chevron-right"/>
        <i :class="class2"/>
    </div>
</template>

<script>
export default {
    name: "ItemHistoryChangeTypeCheckbox",
    props: {
        oldValue: Number,
        newValue: Number
    },
    data() {
        return {
            class1: '',
            class2: '',
        }
    },
    created() {
        if (!this.oldValue) {
            this.class1 = 'bi-square'
            this.class2 = 'bi-check-square'
        } else {
            this.class1 = 'bi-check-square'
            this.class2 = 'bi-square'
        }
    }
}
</script>

<style scoped>

</style>