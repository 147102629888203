var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.showInput
    ? _c(
        "button",
        {
          staticClass: "btn btn-light btn-sm",
          attrs: { type: "button" },
          on: { click: _vm.showPasswordField },
        },
        [_vm._v("\n    Change password\n")]
      )
    : _c("div", { staticClass: "row g-0 align-items-center" }, [
        _c("div", { staticClass: "col-auto" }, [
          (_vm.isPasswordTextVisible ? "text" : "password") === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentData[_vm.field.name],
                    expression: "currentData[field.name]",
                  },
                ],
                ref: "passwordInput",
                staticClass: "form-control",
                class: { "is-invalid": _vm.field.error },
                attrs: {
                  name: _vm.field.name,
                  size: _vm.field.size,
                  required: "",
                  type: "checkbox",
                },
                domProps: {
                  checked: Array.isArray(_vm.currentData[_vm.field.name])
                    ? _vm._i(_vm.currentData[_vm.field.name], null) > -1
                    : _vm.currentData[_vm.field.name],
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.currentData[_vm.field.name],
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.currentData,
                            _vm.field.name,
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.currentData,
                            _vm.field.name,
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.currentData, _vm.field.name, $$c)
                    }
                  },
                },
              })
            : (_vm.isPasswordTextVisible ? "text" : "password") === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentData[_vm.field.name],
                    expression: "currentData[field.name]",
                  },
                ],
                ref: "passwordInput",
                staticClass: "form-control",
                class: { "is-invalid": _vm.field.error },
                attrs: {
                  name: _vm.field.name,
                  size: _vm.field.size,
                  required: "",
                  type: "radio",
                },
                domProps: {
                  checked: _vm._q(_vm.currentData[_vm.field.name], null),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.currentData, _vm.field.name, null)
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentData[_vm.field.name],
                    expression: "currentData[field.name]",
                  },
                ],
                ref: "passwordInput",
                staticClass: "form-control",
                class: { "is-invalid": _vm.field.error },
                attrs: {
                  name: _vm.field.name,
                  size: _vm.field.size,
                  required: "",
                  type: _vm.isPasswordTextVisible ? "text" : "password",
                },
                domProps: { value: _vm.currentData[_vm.field.name] },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.currentData,
                      _vm.field.name,
                      $event.target.value
                    )
                  },
                },
              }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-auto btn-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light btn-sm",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.isPasswordTextVisible = !_vm.isPasswordTextVisible
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.isPasswordTextVisible
                      ? "hide password"
                      : "show password"
                  ) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-light btn-sm",
              attrs: { type: "button" },
              on: { click: _vm.hidePasswordField },
            },
            [_vm._v("\n            reset\n        ")]
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }