<template>
    <div class="p-1 border-bottom">
        <div class="text-center p-3">
            <SiteTreeNavigationMenu :site-tree-id="siteTreeId"/>
        </div>

        <SiteTree :select-page="onPageSelect" :site-tree-id="siteTreeId"/>

        <!--<div class="text-end">
            <PageEditorSiteTreePinBtn/>
        </div>-->
    </div>
</template>

<script>
import SiteTree from "../../common/site-tree/SiteTree.vue"
import PageEditorSiteTreePinBtn from "./PageEditorSiteTreePinBtn.vue"
import SiteTreeNavigationMenu from "../../common/site-tree/SiteTreeNavigationMenu.vue"

export default {
    name: "PageEditorSiteTree",
    components: {
        SiteTreeNavigationMenu,
        PageEditorSiteTreePinBtn,
        SiteTree
    },
    computed: {
        siteTreeId() {
            return this.$store.state.pageEditor.siteTreeId
        }
    },
    methods: {
        onPageSelect(pageId) {
            if (
                this.$store.state.pageEditor.pageId !== pageId
                || this.$route.query.area
                || this.$route.query.id
            ) {
                this.$store.commit(this.siteTreeId + '/selectedPageId', pageId, {root: true})
                this.$router.push({name: 'pageEditor', params: {pageId: pageId}})
            }
        }
    }
}
</script>

<style scoped>

</style>