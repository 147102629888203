var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field.readonly
    ? _c("FormControlPlainText", {
        attrs: {
          value: _vm.currentData[_vm.field.name],
          "form-id": _vm.formId,
        },
      })
    : _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentData[_vm.field.name],
            expression: "currentData[field.name]",
          },
          { name: "form-validation", rawName: "v-form-validation" },
        ],
        staticClass: "form-control",
        class: { "is-invalid": _vm.field.error },
        attrs: {
          id: _vm.field.id,
          maxlength: _vm.field.maxlength,
          minlength: _vm.field.minlength,
          name: _vm.field.name,
          pattern: _vm.field.pattern || false,
          placeholder: _vm.field.placeholder || false,
          required: _vm.field.required,
          size: _vm.field.size,
          autocomplete: "off",
          type: "tel",
        },
        domProps: { value: _vm.currentData[_vm.field.name] },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.currentData, _vm.field.name, $event.target.value)
          },
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }