<template>
    <div class="d-flex align-items-center">
        <button :class="btn1Class" class="me-1 btn btn-sm" type="button">
            {{ btn1Text }}
        </button>

        <i class="me-1 bi-chevron-right"/>

        <button :class="btn2Class" class="btn btn-sm" type="button">
            {{ btn2Text }}
        </button>
    </div>
</template>

<script>
export default {
    name: "ItemHistoryChangeTypeCustomStatus",
    props: {
        oldValue: Number,
        newValue: Number
    },
    data() {
        return {
            btn1Text: '',
            btn1Class: '',
            btn2Text: '',
            btn2Class: ''
        }
    },
    created() {
        if (!this.oldValue) {
            this.btn1Text = 'Unpublished'
            this.btn1Class = 'btn-danger'

            this.btn2Text = 'Published'
            this.btn2Class = 'btn-success'
        } else {
            this.btn1Text = 'Published'
            this.btn1Class = 'btn-success'

            this.btn2Text = 'Unpublished'
            this.btn2Class = 'btn-danger'
        }
    }

}
</script>

<style scoped>

</style>