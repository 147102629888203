var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { ref: "form" },
    [
      _vm._l(_vm.fields, function (field, i) {
        return _c("FormGroup", {
          key: i,
          staticClass: "mb-3",
          attrs: {
            "form-id": _vm.formId,
            field: field,
            "hide-help-text": true,
          },
        })
      }),
      _vm._v(" "),
      _vm.activeTab === "Settings"
        ? _c("FormSidebar", {
            staticClass: "small",
            attrs: {
              "form-id": _vm.formId,
              "show-duplicate-btn": true,
              "show-history-btn": true,
              "always-show-delete-btn": true,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }