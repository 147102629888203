<template>
    <div class="d-flex border-bottom">
        <div class="flex-fill d-flex align-items-center">
            <PageEditorToolbarAddPageBtn/>
            <PageEditorToolbarContentAreaBtn/>
            <PageEditorToolbarContentBtn/>
        </div>

        <div class="d-inline-flex">
            <Button v-if="!showUnpublishedContent" @click.native="toggleShowUnpublishedContent"
                    class="rounded-0 text-danger">
                <i class="bi bi-eye-slash-fill"/>
            </Button>

            <div class="dropdown">
                <div class="d-inline-block" data-bs-toggle="dropdown">
                    <Button class="rounded-0">
                        <i class="bi-three-dots"/>
                    </Button>
                </div>

                <ul class="dropdown-menu">
                    <li>
                        <a href="#" @click.prevent="toggleShowUnpublishedContent" class="dropdown-item small"
                           :class="{'text-danger':!showUnpublishedContent}">
                            <i class="me-1 bi"
                               :class="{'bi-eye':showUnpublishedContent,'bi-eye-slash-fill':!showUnpublishedContent}"/>
                            {{ showUnpublishedContent ? 'Hide' : 'Show' }} unpublished
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import PageEditorToolbarContentAreaBtn from "./PageEditorToolbarContentAreaBtn"
import PageEditorToolbarContentBtn from "./PageEditorToolbarContentBtn"
import PageEditorToolbarAddPageBtn from "./PageEditorToolbarAddPageBtn"
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorToolbar",
    components: {
        Button,
        PageEditorToolbarAddPageBtn,
        PageEditorToolbarContentBtn,
        PageEditorToolbarContentAreaBtn
    },
    computed: {
        showUnpublishedContent() {
            return this.$store.state.pageEditor.showUnpublishedContent
        }
    },
    watch: {
        async showUnpublishedContent(newValue) {
            this.$store.state.pageEditor.contentItems.forEach(obj => {
                const contentId = this.$store.state.pageContentData.items.find(o => o.id === obj.pageContentId)?.contentId
                this.$store.dispatch('itemData/get', {tableName: 'content', id: contentId})
                    .then((o) => {
                        // Hiding unpublished content
                        if (!newValue && o.status === 0) {
                            obj.isHidden = true
                            obj.el.style.setProperty('display', 'none', 'important')

                            // Showing previously hidden content
                        } else if (newValue && obj.isHidden === true) {
                            obj.isHidden = false
                            obj.el.style.removeProperty('display')
                        }

                        this.$store.dispatch('pageEditor/positionAllIframes')
                    })
            })
        }
    },
    methods: {
        toggleShowUnpublishedContent() {
            this.$store.commit('pageEditor/toggleShowUnpublishedContent')
        }
    }
}
</script>

<style scoped>

</style>