<template>
    <button v-if="hasExportAccess" class="d-flex align-items-center" type="button" @click="exportCsv">
        <i class="me-2 bi-upload"/>
        Export CSV
    </button>
</template>

<script>
import axios from 'axios'

export default {
    name: "ListingOptionsExportBtn",
    props: {
        viewId: String,
    },
    computed: {
        listingName() {
            return this.$store.state[this.viewId].listingName
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        hasExportAccess() {
            return this.$store.getters['user/access'](this.tableName, 'export')
        },
        allIds() {
            return this.$store.state[this.viewId].allIds
        },
        productId() {
            return parseInt(this.$route.query.productId)
        }
    },
    asyncComputed: {
        productTitle() {
            if (this.productId) {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'm_products_products',
                        id: this.productId
                    })
                    .then(o => o.title)
            }
        }
    },
    methods: {
        exportCsv() {
            if (confirm('Are you sure?\nPlease be patient as this may take a minute.')) {

                let exportFileName = ''
                if (this.tableName.indexOf('product_variations__') === 0) {
                    exportFileName = this.productTitle
                } else {
                    const component = this.$store.state.components.items.find(o => o.tableName === this.tableName)
                    exportFileName = component.title
                }

                // Generate a notification before we start the process. This notification ID is then provided to the
                // export request so that it can update its progress.
                this.$store.dispatch('toasts/notification', {
                        title: exportFileName + ' export',
                        body: 'Starting&hellip;',
                        downloadUrl: '',
                    })
                    .then((obj) => {
                        const notificationId = obj.notificationId

                        axios({
                            method: 'post',
                            url: this.$store.state.importServiceEndpoint + '/export',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-Auth-Token': this.$store.state.importServiceToken
                            },
                            data: {
                                authorization: 'Bearer ' + localStorage.getItem('token'),
                                notificationId: notificationId,
                                buildName: this.$store.state.sitename,
                                tableName: this.tableName,
                                ids: this.allIds,
                                title: exportFileName
                            }
                        })
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>