var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.iframeSrcdoc
    ? _c("iframe", {
        ref: "iframe",
        staticClass: "flex-fill",
        attrs: { srcdoc: _vm.iframeSrcdoc },
        on: { load: _vm.init },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }