<template>
    <div>
        <ul class="list-group mb-2">
            <li v-for="item in items" :key="item.id" class="list-group-item d-flex align-items-center"
                :class="{'py-1 px-2 small':formSmall}">
                <div class="flex-fill">
                    <PagePath :page-id="item.pageId" class="me-2"/>
                    {{ item.contentArea }}
                </div>

                <Button class="btn-sm" @click.native="goToPage(item.pageId)">
                    <i class="bi-pencil-square"/>
                </Button>
            </li>
        </ul>

        <button class="btn btn-light" type="button" @click="showSiteTree">
            <i class="bi-plus-lg"/>
        </button>
    </div>
</template>

<script>
import PagePath from '../../common/PagePath'
import Button from "../Button.vue"

export default {
    name: "FormControlContentPageUse",
    components: {
        Button,
        PagePath
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    computed: {
        contentId() {
            return this.currentData.id
        },
        items() {
            return this.$store.state.pageContentData.items.filter(o => o.contentId === this.contentId)
        },
        formSmall() {
            return this.$store.state[this.formId].formSmall
        }
    },
    methods: {
        goToPage(pageId) {
            let tableName = window.location.hash.split('/')[1] === 'site_tree'
                ? 'site_tree' : 'pages'

            this.$router.push({
                path: '/' + tableName + '/' + pageId
            })
        },
        showSiteTree() {
            // Reduce the objects to an array of pageIds.
            let pageIds = this.items.map(o => o.pageId)

            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    linkOptions: ['p'],
                    selectedPageIds: JSON.parse(JSON.stringify(pageIds)),
                    propagatedContentId: this.contentId,
                    onSave: (obj) => {
                        let removedPageIds = pageIds.filter(x => !obj.selectedPageIds.includes(x))
                        if (removedPageIds.length) {
                            let promises = []
                            removedPageIds.forEach((pageId) => {
                                promises.push(
                                    this.$store.dispatch('request/delete', {
                                        url: 'api/delete-content-from-page/' + pageId + '/' + this.contentId
                                    })
                                )
                            })
                            Promise.all(promises).then(() => {
                                this.$store.dispatch('pageContentData/init')

                                this.onUpdate()
                            })
                        }

                        let newPageIds = obj.selectedPageIds.filter(x => !pageIds.includes(x))
                        if (newPageIds.length) {
                            let promises = []
                            newPageIds.forEach((pageId) => {
                                promises.push(
                                    this.$store.dispatch('request/post', {
                                        url: 'api/save-content-to-page',
                                        postData: {
                                            pageId: pageId,
                                            contentId: this.contentId,
                                            contentArea: obj.contentArea
                                        }
                                    })
                                )
                            })
                            Promise.all(promises).then(() => {
                                this.$store.dispatch('pageContentData/init')

                                this.onUpdate()
                            })
                        }
                    }
                }
            })
        },
        onUpdate() {
            this.$store.dispatch('pageContentData/init')
        }
    }
}
</script>

<style scoped>

</style>