var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "d-flex align-items-center",
      attrs: { type: "button" },
      on: { click: _vm.copyShareToClipboard },
    },
    [
      _c("i", { staticClass: "me-2 bi-share" }),
      _vm._v("\n    Share\n    "),
      _c("small", { staticClass: "ms-1 badge text-bg-warning" }, [
        _vm._v("beta"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }