<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name] ? '£' + currentData[field.name] : ''" :form-id="formId"/>
    <input v-else :id="field.id" v-model="currentData[field.name]" v-form-validation :max="field.max" :min="field.min"
           :name="field.name" :placeholder="field.placeholder || false" :required="field.required" :size="field.size"
           :step="field.step || step" :title="title" class="form-control" :class="{'is-invalid': field.error}"
           type="number">
</template>

<script>
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeCurrency",
    components: {FormControlPlainText},
    directives: {
        formValidation,
    },
    props: {
        currentData: Object,
        field: Object,
        formId: String,
        value: String // Without this the field won't invalidate if the no. of decimals doesn't make the step.
    },
    data() {
        return {
            // Without a step which includes decimal places Safari will invalidate number fields containing decimals.
            step: 0.01
        }
    },
    computed: {
        // Safari's validation message is completely meaningless, all it outputs is "Enter a valid value", so let's
        // provide something a bit more helpful!
        title() {
            const step = this.step + '' // Convert float to string
            const numberOfDecimals = step.indexOf('.') === -1
                ? 0
                : step.split('.')[1].length

            return 'Please enter a valid value. The number requires ' + numberOfDecimals + ' decimal places.'
        }
    },
    created() {
        if (
            this.field.defaultValue
            // todo - Shouldn't /setDefaultValue only ever be applied when an id is not set?
            //        This should be investigated and if so this condition moved inside the form module.
            && !this.currentData.id
        ) {
            this.$store.dispatch(this.formId + '/setDefaultValue', {
                name: this.field.name,
                value: this.field.defaultValue
            })
        }
    }
}
</script>

<style scoped>

</style>