var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex border-bottom" }, [
    _c(
      "div",
      { staticClass: "flex-fill d-flex align-items-center" },
      [
        _c("PageEditorToolbarAddPageBtn"),
        _vm._v(" "),
        _c("PageEditorToolbarContentAreaBtn"),
        _vm._v(" "),
        _c("PageEditorToolbarContentBtn"),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-inline-flex" },
      [
        !_vm.showUnpublishedContent
          ? _c(
              "Button",
              {
                staticClass: "rounded-0 text-danger",
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleShowUnpublishedContent.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [_c("i", { staticClass: "bi bi-eye-slash-fill" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "dropdown" }, [
          _c(
            "div",
            {
              staticClass: "d-inline-block",
              attrs: { "data-bs-toggle": "dropdown" },
            },
            [
              _c("Button", { staticClass: "rounded-0" }, [
                _c("i", { staticClass: "bi-three-dots" }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "dropdown-menu" }, [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "dropdown-item small",
                  class: { "text-danger": !_vm.showUnpublishedContent },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleShowUnpublishedContent.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "me-1 bi",
                    class: {
                      "bi-eye": _vm.showUnpublishedContent,
                      "bi-eye-slash-fill": !_vm.showUnpublishedContent,
                    },
                  }),
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.showUnpublishedContent ? "Hide" : "Show") +
                      " unpublished\n                    "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }