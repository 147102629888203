<template>
    <FormControlTypeText :current-data="currentData" :field="field" :form-id="formId"/>
</template>

<script>
import FormControlTypeText from '../form-control-type/FormControlTypeText'

export default {
    name: "FormControlContentClass",
    components: {
        FormControlTypeText
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        }
    },
    created() {
        // The Class names field is deprecated and should only be available used by the site already.
        this.field.visibility = 'hidden'

        this.setFieldDatalist();
    },
    methods: {
        async setFieldDatalist() {
            const o = await this.$store.dispatch('request/get', {
                url: 'api/component/' + this.tableName,
                params: {
                    field: this.field.name,
                    groupBy: this.field.name,
                    sort: this.field.name
                    // Not all components have an archive option. To reinstate this it will need to check
                    // the component's settings.
                    //isArchived: 0
                }
            })

            const values = []
            o.data.values.forEach((str) => {
                if (!str) return

                str.split(' ').forEach((className) => {
                    if (values.indexOf(className) === -1) {
                        values.push(className)
                    }
                })
            })

            values.sort()

            // If there are no values, remove the field completely, else show it
            values.length
                ? this.field.visibility = ''
                : this.field.hidden = true

            this.field.datalist = values
        }
    }
}
</script>

<style scoped>

</style>