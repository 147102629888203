<template>
    <section class="d-flex flex-column h-100 p-3">
        <header>
            <h2 class="h5 mb-1">{{ contentAreaName }}<br><small class="lh-1 text-body-tertiary">area</small></h2>
        </header>

        <div class="flex-fill py-4">
            <!--<div class="mb-3">
                <div class="label mb-0 fw-bold text-body-tertiary">Type</div>
                <small class="lh-1">Content area</small>
            </div>-->

            <div class="mb-3">
                <h6>New content</h6>
                <PageEditorAddContentMenu/>
                <!--<div class="list-group">
                    <div @click="addContent(contentType.id)" v-for="contentType in basicContentTypes"
                         class="list-group-item small">
                        {{ contentType.title }}
                    </div>
                </div>-->
            </div>

            <div class="mb-3">
                <h6>Existing content</h6>
                <div class="btn-group">
                    <Button @click.native="showSiteTreeBrowser" :default-class="'btn-light'">
                        <i class="bi-diagram-3-fill"/>
                    </Button>

                    <!--<Button @click.native="showSelectList" :default-class="'btn-light'">
                        Browse&hellip;
                    </Button>-->
                </div>
            </div>
        </div>

        <footer></footer>
    </section>
</template>

<script>
import Button from "../../common/Button.vue"
import PageEditorAddContentMenu from "./PageEditorAddContentMenu.vue"

export default {
    name: "PageEditorContentAreaPanel",
    components: {PageEditorAddContentMenu, Button},
    computed: {
        contentAreaName() {
            return this.$store.state.pageEditor.selectedContentAreaName
        },
        pageId() {
            return this.$store.state.pageEditor.pageId
        }
    },
    methods: {
        showSiteTreeBrowser() {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    linkOptions: 'ic',
                    onSelect: (obj) => {
                        // Prevent page selection.
                        // todo - This doesn't prevent the modal from closing.
                        // Ideally it should not close if a user clicks on a page item.
                        if (obj.pageId) {
                            return
                        }

                        this.setContentId(obj.contentId)
                    }
                }
            })
        },
        showSelectList() {
            const modal = this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    listingName: 'content.new',
                    selectedIds: [],
                    onSelect: (newIds) => {
                        this.setContentId(newIds.pop())
                        modal.then((obj) => {
                            this.$store.dispatch('modals/remove', obj.index)
                        })
                    }
                }
            })
        },
        async setContentId(contentId) {
            await this.$store.dispatch('request/post', {
                url: 'api/save-content-to-page',
                postData: {
                    pageId: this.pageId,
                    contentId: contentId,
                    contentArea: this.contentAreaName
                }
            })

            await this.$store.dispatch('pageEditor/addContentToPage', {
                contentId: contentId,
                contentAreaName: this.contentAreaName
            })

            return this.$store.dispatch('pageContentData/init')
        }
    }
}
</script>

<style scoped>

</style>