<template>
    <div class="modal-dialog modal-lg modal-fullscreen-sm-down">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Search</h4>

                <button aria-label="Close" class="btn-close" type="button" @click="$emit('hide-modal')"></button>
            </div>

            <div class="modal-body">
                <SearchField :view-id="viewId" :hide-modal="hideModal" class="mb-3 text-center"/>

                <SearchFilterSavedFiltersMenu :view-id="viewId"/>

                <SearchFilters :view-id="viewId"/>

                <div class="d-flex align-items-center pt-3">
                    <div class="flex-fill">
                        <SearchFilterAddFilterBtn :view-id="viewId"/>
                    </div>

                    <SearchFilterSaveFiltersForm :view-id="viewId"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchField from '../../common/search-filters/SearchField'
import SearchFilters from '../../common/search-filters/SearchFilters'
import SearchFilterAddFilterBtn from '../../common/search-filters/SearchFilterAddFilterBtn'
import SearchFilterSaveFiltersForm from "../../common/search-filters/SearchFilterSaveFiltersForm"
import SearchFilterSavedFiltersMenu from "../../common/search-filters/SearchFilterSavedFiltersMenu"

export default {
    name: "SearchModal",
    components: {
        SearchFilterSavedFiltersMenu,
        SearchFilterSaveFiltersForm,
        SearchField,
        SearchFilters,
        SearchFilterAddFilterBtn,
    },
    props: {
        options: Object,
    },
    data() {
        return {
            viewId: this.options.viewId,
        }
    },
    methods: {
        hideModal() {
            this.$emit('hide-modal')
        }
    }
}
</script>

<style scoped>

</style>