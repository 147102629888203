var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSet
    ? _c(
        "button",
        {
          staticClass: "btn btn-link btn-sm focus-ring",
          attrs: { type: "button" },
          on: { click: _vm.onClick },
        },
        [_c("i", { staticClass: "bi-x-lg" })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }