<template>
    <label v-if="showDisplayOrderOption" class="d-flex align-items-center">
        <div class="dropdown me-2">
            <Button id="dropdownMenuDisplayOrder" aria-expanded="true" class="btn-sm dropdown-toggle border"
                    data-bs-toggle="dropdown">
                {{ currentData.displayOrder }}
            </Button>

            <ul aria-labelledby="dropdownMenuDisplayOrder" class="dropdown-menu">
                <li v-for="i in options">
                    <button :class="{ 'active': i === currentData.displayOrder }" class="dropdown-item" type="button"
                            @click="setDisplayOrder(i)">
                        {{ i }}
                    </button>
                </li>
            </ul>
        </div>

        Display order
    </label>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "FormDisplayOrderMenu",
    components: {Button},
    props: {
        formId: String
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        showDisplayOrderOption() {
            return this.$store.getters[this.formId + '/showDisplayOrderOption']
        }
    },
    asyncComputed: {
        options() {
            return this.$store.dispatch('request/get', {
                    url: 'api/items-display-order-options',
                    params: {
                        tableName: this.tableName,
                        id: this.id
                    }
                })
                .then(o => o.data)
        }
    },
    methods: {
        setDisplayOrder(i) {
            this.currentData.displayOrder = i
        }
    }
}
</script>

<style scoped>

</style>