var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-lg" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v(" ")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { "aria-label": "Close", type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("form", { staticClass: "form-horizontal" }, [
          _c("div", { staticClass: "mb-4 row" }, [
            _c("label", { staticClass: "col-sm-4 col-lg-3 form-label" }, [
              _vm._v("Image"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-8 col-lg-9 control-wrapper" },
              [
                _c("ImageSelection", {
                  attrs: {
                    "file-id": _vm.fileId,
                    "on-change": _vm.onChange,
                    "form-id": _vm.formId,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-4 row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-4 col-lg-3 form-label",
                attrs: { for: "altText" },
              },
              [_vm._v("Alternative text")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-8 col-lg-9 control-wrapper" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.altText,
                    expression: "altText",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "altText", disabled: !_vm.fileId },
                domProps: { value: _vm.altText },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.altText = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-4 row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-4 col-lg-3 form-label",
                attrs: { for: "title" },
              },
              [_vm._v("Title")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-8 col-lg-9 control-wrapper" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.title,
                    expression: "title",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "title", disabled: !_vm.fileId },
                domProps: { value: _vm.title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.title = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-4 row" }, [
            _c("label", { staticClass: "col-sm-4 col-lg-3 form-label" }, [
              _vm._v("Alignment"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-8 col-lg-9 control-wrapper" }, [
              _c("div", { staticClass: "btn-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    class: { active: _vm.align === false },
                    attrs: { disabled: !_vm.fileId, type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.align = false
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                                none\n                            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    class: { active: _vm.align === "left" },
                    attrs: { disabled: !_vm.fileId, type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.align = "left"
                      },
                    },
                  },
                  [_c("i", { staticClass: "bi-align-start" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    class: { active: _vm.align === "center" },
                    attrs: { disabled: !_vm.fileId, type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.align = "center"
                      },
                    },
                  },
                  [_c("i", { staticClass: "bi-align-center" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    class: { active: _vm.align === "right" },
                    attrs: { disabled: !_vm.fileId, type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.align = "right"
                      },
                    },
                  },
                  [_c("i", { staticClass: "bi-align-end" })]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-4 row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-4 col-lg-3 form-label",
                attrs: { for: "width" },
              },
              [_vm._v("Dimensions")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-8 col-lg-9 d-flex align-items-center" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.width,
                      expression: "width",
                    },
                  ],
                  staticClass: "form-control me-1",
                  attrs: {
                    id: "width",
                    disabled: !_vm.fileId,
                    placeholder: "w",
                    size: "3",
                    type: "number",
                  },
                  domProps: { value: _vm.width },
                  on: {
                    keyup: _vm.setWidth,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.width = $event.target.value
                    },
                  },
                }),
                _vm._v("\n                        x\n                        "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.height,
                      expression: "height",
                    },
                  ],
                  staticClass: "form-control ms-1",
                  attrs: {
                    id: "height",
                    disabled: !_vm.fileId,
                    placeholder: "h",
                    size: "3",
                    type: "number",
                  },
                  domProps: { value: _vm.height },
                  on: {
                    keyup: _vm.setHeight,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.height = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ms-1 btn btn-sm btn-light",
                    attrs: { disabled: !_vm.fileId, type: "button" },
                    on: { click: _vm.setLockRatio },
                  },
                  [
                    _c("span", {
                      staticClass: "bi-lock",
                      class: { "bi-unlock": _vm.lockRatio === false },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ms-1 btn btn-sm btn-light",
                    attrs: { disabled: !_vm.fileId, type: "button" },
                    on: { click: _vm.refreshDimensions },
                  },
                  [_c("i", { staticClass: "bi-arrow-clockwise" })]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-4 row" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-4 col-lg-3 form-label",
                attrs: { for: "sizes" },
              },
              [_vm._v("Sizes")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-8 col-lg-9" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.sizes,
                    expression: "sizes",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "sizes", disabled: !_vm.fileId },
                domProps: { value: _vm.sizes },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.sizes = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v("\n\n    " + _vm._s(_vm.fileId) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }