<template>
    <FormControlPlainText v-if="field.readonly" :value="componentTitle" :form-id="formId"/>
    <FormControlTypeSelect v-else-if="options" :current-data="currentData" :field="field" :form-id="formId"
                           :options="options"/>
</template>

<script>
import FormControlTypeSelect from '../form-control-type/FormControlTypeSelect'
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlPagesComponentId",
    components: {
        FormControlPlainText,
        FormControlTypeSelect
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object
    },
    computed: {
        componentTitle() {
            if (this.currentData.id && this.currentData.componentId) {
                return this.$store.state.components.items.find(o => o.id === this.currentData.componentId)?.title
            }
        },
        options() {
            const isHidden = this.currentData.type !== 'Listing'
            if (isHidden) {
                // field.visibility must be used and not field.hidden because the latter will completely disable this
                // component and prevent these scripts from running.
                this.field.visibility = 'hidden'
                this.currentData.componentId = null
            } else {
                this.field.visibility = ''

                return this.$store.state.components.items
                    .filter(o => o.listing)
                    .map(o => ({
                        value: o.id,
                        text: o.title
                    }))
                    .sort((a, b) => (a.text > b.text) ? 1 : -1)
            }
        }
    },
    created() {
        if (this.currentData.id) {
            if (this.currentData.type === 'Listing') {
                this.field.readonly = true
            } else {
                this.field.hidden = true
            }
        }
    }
}
</script>

<style scoped>

</style>