var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm text-nowrap focus-ring",
        attrs: { "data-bs-toggle": "dropdown", type: "button" },
      },
      [_vm._v("\n        " + _vm._s(_vm.columnTitle) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "dropdown-menu pt-0", attrs: { role: "menu" } },
      [
        _vm._l(_vm.fieldsets, function (fieldset) {
          return [
            _c("li", {
              staticClass: "dropdown-item disabled",
              class: { "pt-3": fieldset.legend !== "&nbsp;" },
              domProps: { innerHTML: _vm._s(fieldset.legend) },
            }),
            _vm._v(" "),
            _vm._l(_vm.getColumns(fieldset.id), function (column) {
              return _c(
                "li",
                {
                  key: column.id,
                  on: {
                    click: function ($event) {
                      return _vm.setColumn(column.columnName)
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      class: { active: column.columnName === _vm.columnName },
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(column.title) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              )
            }),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }