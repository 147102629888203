var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SidebarToggle",
    {
      staticClass: "flex-grow-1 overflow-auto",
      attrs: { name: "form_nav" },
      scopedSlots: _vm._u([
        {
          key: "leftSidebar",
          fn: function () {
            return [
              _c("FormFieldsetAnchors", { attrs: { "form-id": _vm.formId } }),
            ]
          },
          proxy: true,
        },
        {
          key: "rightSidebar",
          fn: function () {
            return [
              _c("FormSidebar", {
                staticClass: "p-3 ps-1",
                attrs: {
                  "form-id": _vm.formId,
                  "show-archive-btn": _vm.showArchiveBtn,
                  "show-duplicate-btn": _vm.showDuplicateBtn,
                  "show-history-btn": _vm.showHistoryBtn,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("Form", {
        staticClass: "container-fluid mt-4 mb-5",
        attrs: {
          "form-id": _vm.formId,
          "show-field-names": true,
          "show-legends": true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }