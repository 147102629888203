var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentData.id && !_vm.betaMode
    ? _c("FormControlPlainText", {
        attrs: { value: _vm.title, "form-id": _vm.formId },
      })
    : _vm.show
    ? _c("FormControlTypeSelect", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }