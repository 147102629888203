<template>
    <div class="d-flex align-items-center">
        <small v-if="oldDate" class="border rounded py-1 px-2" v-html="oldDate"></small>
        <i v-else class="small">empty</i>

        <i class="mx-2 bi-chevron-right"/>

        <small v-if="newDate" class="border rounded py-1 px-2" v-html="newDate"></small>
        <i v-else class="small">empty</i>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "ItemHistoryChangeTypeDate",
    props: {
        oldValue: Number,
        newValue: Number,
        dateFormat: String
    },
    computed: {
        oldDate() {
            return moment(this.oldValue * 1000).format('DD MMM YYYY, HH:mm')
        },
        newDate() {
            return moment(this.newValue * 1000).format('DD MMM YYYY, HH:mm')
        }
    }
}
</script>

<style scoped>

</style>