<template>
    <section class="d-flex h-100">
        <!-- Right margin is used here so that when overflow-scroll kicks in for controls too wide for the sidebar,
             some spacing appears to the right of the controls. -->
        <div v-if="activeTab" class="flex-fill p-3 pe-0 me-3 overflow-scroll">
            <PageEditorContentForm :form-id="formId" :key="activeTab"/>

            <template v-if="activeTab === 'Edit'">
                <footer class="pb-3 text-center">
                    <PageEditorContentMoveUpBtn/>
                    <PageEditorContentMoveDownBtn/>
                </footer>
            </template>
        </div>

        <div class="d-flex flex-column" style="margin-top: -1px">
            <PageEditorContentPanelTabBtn v-for="tab in tabs" :key="tab.name" :icon-class="tab.icon"
                                          :tab-name="tab.name" :active-tab-prop-name="'contentPanelActiveTab'"
                                          :border-class-name="'border-start'"/>
            <div class="flex-fill bg-body-tertiary border-start"></div>
        </div>
    </section>
</template>

<script>
import PageEditorContentMoveDownBtn from "./PageEditorContentMoveDownBtn.vue"
import PageEditorContentMoveUpBtn from "./PageEditorContentMoveUpBtn.vue"
import PageEditorContentPanelTabBtn from "./PageEditorContentPanelTabBtn.vue"
import PageEditorContentForm from "./PageEditorContentForm"

export default {
    name: "PageEditorContentPanel",
    components: {
        PageEditorContentForm,
        PageEditorContentPanelTabBtn,
        PageEditorContentMoveUpBtn,
        PageEditorContentMoveDownBtn
    },
    data() {
        return {
            tabs: [
                {name: 'Edit', icon: 'bi-pencil'},
                {name: 'Scheduling', icon: 'bi-calendar-date'},
                {name: 'Access', icon: 'bi-person-lock'},
                {name: 'Page use', icon: 'bi-diagram-2'},
                {name: 'Settings', icon: 'bi-gear'}
            ]
        }
    },
    computed: {
        pageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        },
        pageContent() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId)
        },
        contentId() {
            return this.pageContent?.contentId
        },
        formId() {
            return 'form:content:' + this.contentId
        },
        activeTab() {
            return this.$store.state.pageEditor.contentPanelActiveTab
        }
    },
    created() {
        // Fix for iframes not being positioned properly after content panel appears.
        setTimeout(() => {
            this.$store.dispatch('pageEditor/positionAllIframes')
        }, 50)
    },
    watch: {
        activeTab: {
            immediate: true,
            handler() {
                let fieldNames = []
                switch (this.activeTab) {
                    case 'Edit':
                        fieldNames = ['contentType']
                        break
                    case 'Scheduling':
                        fieldNames = ['validFrom', 'validTo']
                        break
                    case 'Access':
                        fieldNames = ['groupAccess']
                        break
                    case 'Page use':
                        fieldNames = ['pageUse']
                        break
                    case 'Settings':
                        fieldNames = ['']
                        break
                }

                this.$store.dispatch('forms/createForm', {
                    tableName: 'content',
                    id: this.contentId,
                    fieldNames: fieldNames,
                    formSmall: true,
                    singularLabel: 'content',
                    onDelete: () => {
                        this.$store.dispatch('pageEditor/deleteContent', this.pageContentId)
                    },

                })
            }
        }
    }
}
</script>

<style scoped>

</style>