<template>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Select an item</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body p-0">
                <header>
                    <div class="d-flex border-bottom">
                        <div class="flex-fill">
                            <Button v-if="show" class="rounded-0" @click.native="showAddItemModal">
                                <i class="bi-plus-lg"/>
                            </Button>

                            <ListingOptionsSearchModalBtn :view-id="viewId"/>
                            <SearchFiltersResetBtn :view-id="viewId"/>
                        </div>

                        <div class="d-flex align-items-center">
                            <ListingOptionsPaginationMetrics :view-id="viewId"/>
                            <ListingOptionsPreviousBtn class="ms-1" :view-id="viewId"/>
                            <ListingOptionsNextBtn class="ms-1" :view-id="viewId"/>
                            <ListingOptionsMenu class="ms-1" :view-id="viewId"/>
                        </div>
                    </div>

                    <SearchFilterToolbar :view-id="viewId"/>
                </header>

                <div v-if="isOdp">
                    <OdpCourseDatesCoursesMenu :view-id="viewId"/>
                    <OdpCourseDatesSchoolsMenu :view-id="viewId"/>
                </div>

                <TableView v-if="viewMode === 'table' || !viewMode" :view-id="viewId" class="m-3"/>
                <GridView v-else-if="viewMode === 'thumbnail'" :view-id="viewId" class="m-3"/>
            </div>
        </div>
    </div>
</template>

<script>
import view from '../../store/modules/view'

import OdpCourseDatesCoursesMenu from '../../bespoke/odp/OdpCourseDatesCoursesMenu'
import OdpCourseDatesSchoolsMenu from '../../bespoke/odp/OdpCourseDatesSchoolsMenu'
import ListingOptionsSearchModalBtn from '../../common/listing/ListingOptionsSearchModalBtn'
import SearchFiltersResetBtn from '../../common/listing/SearchFiltersResetBtn'
import ListingOptionsPaginationMetrics from '../../common/listing/ListingOptionsPaginationMetrics'
import ListingOptionsPreviousBtn from '../../common/listing/ListingOptionsPreviousBtn'
import ListingOptionsNextBtn from '../../common/listing/ListingOptionsNextBtn'
import ListingOptionsMenu from '../../common/listing/ListingOptionsMenu'
import TableView from '../../common/listing/TableView'
import GridView from '../../common/listing/GridView'
import Button from "../../common/Button.vue"
import SearchFilterToolbar from "../../common/search-filters/SearchFilterToolbar.vue"

export default {
    name: "SelectListModal",
    components: {
        SearchFilterToolbar,
        Button,
        OdpCourseDatesCoursesMenu,
        OdpCourseDatesSchoolsMenu,
        ListingOptionsSearchModalBtn,
        SearchFiltersResetBtn,
        ListingOptionsPaginationMetrics,
        ListingOptionsPreviousBtn,
        ListingOptionsNextBtn,
        ListingOptionsMenu,
        TableView,
        GridView
    },
    props: {
        options: Object
    },
    data() {
        return {
            formId: this.options.formId,
            listingName: this.options.listingName,
            onSelect: this.options.onSelect,
            selectedIds: this.options.selectedIds,
            hiddenFilters: this.options.hiddenFilters
        }
    },
    computed: {
        viewId() {
            if (this.formId === 'pageEditorForm') {
                return 'view:pageEditorSelectListModal'
            }

            const viewId = 'view:' + this.listingName

            // As users move to and from listings we only want to register modules which don't yet exist, so as to
            // preserve the user's settings for each module.
            if (!this.$store.hasModule(viewId)) {
                this.$store.registerModule(viewId, view)
            }

            return viewId
        },
        view() {
            return this.$store.state[this.viewId]
        },
        tableName() {
            return this.view.tableName
        },
        viewMode() {
            return this.view.viewMode
        },
        addNew() {
            return !!this.$store.state.components.items.find(o => o.id === this.view.componentId).addNew
        },
        show() {
            return (
                this.$store.getters['user/access'](this.tableName, 'add')
                && !this.view.showArchive
                && this.tableName !== 'm_basket_orders'
                && this.addNew
            )
        },
        isOdp() {
            return (
                this.$store.state.sitename.indexOf('outdoorsproject') === 0
                && this.tableName.indexOf('odp_course_dates') === 0
            )
        }
    },
    created() {
        this.$store.dispatch(this.viewId + '/init', {
            listingName: this.listingName,
            selectedIds: this.selectedIds,
            hiddenFilters: this.hiddenFilters,
            selectMode: true,
            onSelect: this.onSelect,
            //showArchive: false - It's default is false so what's the point in this?
        })
    },
    methods: {
        showAddItemModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'AddItemModal',
                obj: {
                    tableName: this.tableName,
                    onAddItem: (obj) => {
                        // todo - This might work with Vue.
                        // I disabled this because, although it was highlighting the item in the select listing, it
                        // didn't add the item to the form field's selected items.
                        //scope.view.selectedIds.push(obj.id);
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>