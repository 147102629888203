export default {
    namespaced: true,
    state: {
        visibleColumns: undefined,
        itemObjs: undefined,
    },
    mutations: {},
    actions: {
        get({rootState, state, dispatch, rootGetters}, {tableName, visibleColumns, objs}) {
            state.visibleColumns = visibleColumns
            state.itemObjs = objs
            
            return new Promise((resolve, reject) => {
                if (
                    state.itemObjs
                    && state.itemObjs.length
                ) {
                    // Load the component's structure and determine the file fields.
                    const componentId = rootState.components.componentIds[tableName]
                    const structureObjs = rootGetters['componentStructure/get'](componentId)
                    dispatch('preloadCategories', structureObjs)
                        .then(() => {
                            return dispatch('preloadFiles', structureObjs)
                        })
                        .then(() => {
                            resolve()
                        })
                } else {
                    resolve()
                }
            })
        },
        preloadCategories({state, dispatch, rootState}, structureObjs) {
            return new Promise((resolve, reject) => {
                
                // Assign all ids stored in single category fields to their componentId.
                // i.e. { categoryComponentId => [itemId, itemId], categoryComponentId => [itemId, itemId] }
                // e.g. { 123 => [1,2,3], 456 => [1,2,3] }
                let singleCategoryObjs = structureObjs.filter((obj) => {
                    return obj.type === 'relationshipOneToMany'
                })
                
                let componentIds = {}
                state.itemObjs.forEach((itemObj) => {
                    singleCategoryObjs.forEach((categoryObj) => {
                        // Only preload data for fields which are presented in the listing.
                        if (state.visibleColumns.indexOf(categoryObj.id) > -1) {
                            let componentId = categoryObj.categoryComponentId
                            let columnName = categoryObj.columnName
                            
                            let itemId = parseInt(itemObj[columnName])
                            if (itemId) {
                                if (!componentIds[componentId]) {
                                    componentIds[componentId] = []
                                }
                                
                                if (componentIds[componentId].indexOf(itemId) === -1) {
                                    componentIds[componentId].push(itemId)
                                }
                            }
                        }
                    })
                })
                
                // Now preload each category component's items and add them to ItemData.
                let keys = Object.keys(componentIds)
                let len = keys.length
                if (len) {
                    let i = 0
                    keys.forEach((componentId) => {
                        componentId = parseInt(componentId)
                        const tableName = rootState.components.items.find(o => o.id === componentId).tableName
                        const itemIds = componentIds[componentId]
                        
                        return dispatch('itemData/preload', {
                            tableName: tableName,
                            ids: itemIds
                        }, {root: true})
                            .then(() => {
                                i++
                                if (i === len) {
                                    resolve()
                                }
                            })
                    })
                } else {
                    resolve()
                }
            })
        },
        preloadFiles({state, dispatch}, structureObjs) {
            return new Promise((resolve, reject) => {
                
                // Get the column names of all file and image columns in state.visibleColumns
                let columnNames = structureObjs
                    .filter((obj) => {
                        return (
                            state.visibleColumns.indexOf(obj.id) > -1
                            && ['file', 'image'].indexOf(obj.type) > -1
                        )
                    })
                    .map(function(o) {
                        return o.columnName
                    })
                
                let fileIds = []
                state.itemObjs.forEach((obj) => {
                    columnNames.forEach((columnName) => {
                        let fileId = parseInt(obj[columnName])
                        if (fileId) {
                            fileIds.push(fileId)
                        }
                    })
                })
                
                if (fileIds.length) {
                    dispatch('itemData/preload', {
                        tableName: 'files',
                        ids: fileIds
                    }, {root: true})
                        .then((objs) => {
                            resolve()
                        })
                } else {
                    resolve()
                }
            })
        }
    },
}