var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.legends, function (legend, index) {
      return _c("FormFieldset", {
        key: legend,
        attrs: {
          "form-id": _vm.formId,
          "is-first": !index,
          "is-last": index === _vm.legends.length - 1,
          legend: legend,
          "show-legends": _vm.showLegends,
          "hide-help-text": _vm.hideHelpText,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }