<template>
    <div class="btn-group">
        <Button data-bs-toggle="dropdown" :default-class="'btn-light'" class="dropdown-toggle">
            Select
        </Button>

        <ul class="dropdown-menu dropdown-menu-end small">
            <template v-for="(type, key) in types" v-if="getTypes(type).length">
                <template v-if="key">
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li>
                        <button class="dropdown-item disabled btn-sm" type="button">{{ type }}</button>
                    </li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                </template>
                <li>
                    <button class="dropdown-item" v-for="contentType in getTypes(type)"
                            @click="addContent(contentType.id)" type="button">{{ contentType.title }}
                    </button>
                </li>
            </template>

            <!--<template v-if="listingContentTypes?.length">
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li>
                    <button class="dropdown-item disabled" type="button">Listing</button>
                </li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li>
                    <button class="dropdown-item" v-for="contentType in listingContentTypes"
                            @click="addContent(contentType.id)" type="button">{{ contentType.title }}
                    </button>
                </li>
            </template>
            <li>
                <hr class="dropdown-divider">
            </li>
            <li>
                <button class="dropdown-item disabled" type="button">Advanced</button>
            </li>
            <li>
                <hr class="dropdown-divider">
            </li>
            <li>
                <button class="dropdown-item" v-for="contentType in advancedContentTypes"
                        @click="addContent(contentType.id)" type="button">{{ contentType.title }}
                </button>
            </li>-->
        </ul>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorAddContentMenu",
    components: {Button},
    computed: {
        contentAreaName() {
            return this.$store.state.pageEditor.selectedContentAreaName
        },
        contentTypes() {
            return this.$store.getters['templates/contentTypes']
        },
        types() {
            return [...new Set(this.contentTypes.map(o => o.contentType))].sort()
        },
        pageId() {
            return this.$store.state.pageEditor.pageId
        }
    },
    asyncComputed: {
        page() {
            return this.$store.dispatch('itemData/get', {
                tableName: 'pages',
                id: this.pageId
            })
        }
    },
    methods: {
        getTypes(type) {
            let contentTypes = this.contentTypes.filter(o => o.contentType === type)
            
            if (this.page) {
                // Filter out all listing CTs which target components other than the page's componentId
                if (type === 'Listing') {
                    contentTypes = contentTypes.filter(o => this.page.componentId === o.componentId)
                } else if (type === 'Listing component') {
                    contentTypes = contentTypes.filter(o => this.page.componentId === o.componentId
                        // Listing component CTs can be generic or specific
                        || o.componentId === 0
                    )
                }
            }

            return contentTypes
        },
        addContent(contentTypeId) {
            this.$store.dispatch('request/post', {
                    url: 'api/component/content',
                    postData: {
                        //title: this.formData.title,
                        contentType: contentTypeId
                    }
                })
                .then((obj) => {
                    const contentId = obj.data.id

                    this.$store.dispatch('pageEditor/addContentToPage', {
                        contentId: contentId,
                        contentAreaName: this.contentAreaName
                    })
                })
        }
    }
}
</script>

<style scoped>

</style>