<template>
    <div>
        <div v-for="fieldset in fieldsets" :key="fieldset.id" class="mb-3">
            <h6>{{ fieldset.legend }}</h6>
            <div class="list-group list-group-flush">
                <a v-for="column in getFieldsetColumns(fieldset.id)" :key="column.id"
                   class="list-group-item p-1 d-flex align-items-center"
                   @click="toggleColumnVisibility(column.id)">
                    <i class="me-1 bi-check-lg" :class="{'invisible': activeColumns.indexOf(column.id) === -1}"/>
                    {{ column.title }}
                </a>
            </div>
        </div>

        <h6>&nbsp;</h6>
        <div class="list-group list-group-flush">
            <a v-for="column in getFieldsetColumns(0)" :key="column.id"
               class="list-group-item p-1 d-flex align-items-center"
               @click="toggleColumnVisibility(column.id)">
                <i class="me-1 bi-check-lg" :class="{'invisible': activeColumns.indexOf(column.id) === -1}"/>
                {{ column.title }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListingColumnsList",
    props: {
        viewId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        activeColumns() {
            return this.$store.state[this.viewId].columns
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        fieldsets() {
            return this.$store.state.fieldsets.items.filter(o => o.componentId === this.componentId)
        },
        allColumns() {
            return this.$store.getters['componentStructure/get'](this.componentId)
        },
        columns() {
            let objs = this.$store.getters['componentStructure/get'](this.componentId)

            // Remove devOnly columns.
            if (!this.$store.state.betaMode) {
                objs = objs.filter(o => !o.devOnly)
            }

            objs = objs.filter(o => o.type !== 'hidden' && o.type !== 'fieldset')

            return objs
        },
    },
    methods: {
        getFieldsetColumns(fieldsetId) {
            return this.allColumns.filter(o => o.fieldsetId === fieldsetId)
        },
        toggleColumnVisibility(columnId) {

            // Create a copy of the columns array.
            let columns = JSON.parse(JSON.stringify(this.activeColumns))
            let i = columns.indexOf(columnId)

            // Append or remove the item to/from it.
            // If appending a column this will add the new column onto the end of the array
            // but, we need the ids to be listed in the display order.
            i === -1
                ? columns.push(columnId)
                : columns.splice(i, 1)

            // So we must now recreate the columns array by the order of the component's fields array.
            let viewColumns = []
            this.columns.forEach((field) => {
                if (columns.indexOf(field.id) > -1) {
                    viewColumns.push(field.id)
                }
            })

            this.$store.dispatch(this.viewId + '/setColumns', viewColumns)
        }
    }
}
</script>

<style scoped>

</style>