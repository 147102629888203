<template>
    <button class="btn btn-link btn-sm rounded-5 text-decoration-none" role="button" data-bs-toggle="offcanvas"
            :href="'#' + offCanvasId" :aria-controls="offCanvasId">
        filters
    </button>
</template>

<script>
export default {
    name: "SearchFilterToolbarFiltersBtn",
    props: {
        viewId: String,
        offCanvasId: String
    }
}
</script>

<style scoped>
button {
    font-size: .8rem;
}
</style>