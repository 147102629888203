var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown" }, [
    _vm.typeOptions.length === 1
      ? _c("span", { staticClass: "text-nowrap small" }, [
          _vm._v("\n        " + _vm._s(_vm.filter.conditionText) + "\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.typeOptions.length > 1
      ? _c(
          "button",
          {
            staticClass: "btn btn-light btn-sm text-nowrap focus-ring",
            attrs: { "data-bs-toggle": "dropdown", type: "button" },
          },
          [_vm._v("\n        " + _vm._s(_vm.filter.conditionText) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "dropdown-menu", attrs: { role: "menu" } },
      _vm._l(_vm.typeOptions, function (option, index) {
        return _c(
          "li",
          {
            key: option.id,
            on: {
              click: function ($event) {
                return _vm.setOption(index)
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                class: { active: option.text === _vm.filter.conditionText },
                attrs: { href: "" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_vm._v(_vm._s(option.text))]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }