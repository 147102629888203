var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "btn-sm rounded-0",
      class: _vm.classNames,
      attrs: {
        "default-class": "bg-body-tertiary",
        "active-class": "",
        "active-value": _vm.isActive,
        title: _vm.tabName,
      },
      nativeOn: {
        click: function ($event) {
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [_c("i", { staticClass: "bi", class: _vm.iconClass })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }