var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "d-flex flex-column h-100 p-3" }, [
    _c("header", [
      _c("h2", { staticClass: "h5 mb-1" }, [
        _vm._v(_vm._s(_vm.contentAreaName)),
        _c("br"),
        _c("small", { staticClass: "lh-1 text-body-tertiary" }, [
          _vm._v("area"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex-fill py-4" }, [
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c("h6", [_vm._v("New content")]),
          _vm._v(" "),
          _c("PageEditorAddContentMenu"),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _c("h6", [_vm._v("Existing content")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn-group" },
          [
            _c(
              "Button",
              {
                attrs: { "default-class": "btn-light" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showSiteTreeBrowser.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "bi-diagram-3-fill" })]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("footer"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }