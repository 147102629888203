var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showThumbnail || true
    ? _c("ImageSelection", {
        attrs: {
          "form-id": _vm.formId,
          "column-name": _vm.field.name,
          "file-browser": !_vm.isImage,
          "file-id": _vm.fileId,
          "on-change": _vm.onChange,
          "remove-option": false,
          "row-id": _vm.currentData.id,
          "table-name": _vm.tableName,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }