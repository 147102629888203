var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c("i", { class: _vm.class1 }),
    _vm._v(" "),
    _c("i", { staticClass: "mx-2 bi-chevron-right" }),
    _vm._v(" "),
    _c("i", { class: _vm.class2 }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }