var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-group" },
    [
      _c(
        "Button",
        {
          staticClass: "dropdown-toggle",
          attrs: { "data-bs-toggle": "dropdown", "default-class": "btn-light" },
        },
        [_vm._v("\n        Select\n    ")]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "dropdown-menu dropdown-menu-end small" },
        [
          _vm._l(_vm.types, function (type, key) {
            return _vm.getTypes(type).length
              ? [
                  key
                    ? [
                        _c("li", [
                          _c("hr", { staticClass: "dropdown-divider" }),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "button",
                            {
                              staticClass: "dropdown-item disabled btn-sm",
                              attrs: { type: "button" },
                            },
                            [_vm._v(_vm._s(type))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("hr", { staticClass: "dropdown-divider" }),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    _vm._l(_vm.getTypes(type), function (contentType) {
                      return _c(
                        "button",
                        {
                          staticClass: "dropdown-item",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.addContent(contentType.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(contentType.title) + "\n                "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              : _vm._e()
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }