var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.schoolId,
          expression: "schoolId",
        },
      ],
      staticClass: "form-select form-select-sm w-auto",
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.schoolId = $event.target.multiple
            ? $$selectedVal
            : $$selectedVal[0]
        },
      },
    },
    _vm._l(_vm.options, function (option) {
      return _c("option", { domProps: { value: option.value } }, [
        _vm._v("\n        " + _vm._s(option.text) + "\n    "),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }