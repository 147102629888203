<template>
    <div>
        <FormFieldset v-for="(legend, index) in legends" :key="legend" :form-id="formId" :is-first="!index"
                      :is-last="index === legends.length - 1" :legend="legend" :show-legends="showLegends"
                      :hide-help-text="hideHelpText"/>
    </div>
</template>

<script>
import FormFieldset from './FormFieldset'

export default {
    name: "FormFieldsets",
    components: {
        FormFieldset
    },
    props: {
        formId: String,
        showLegends: Boolean,
        hideHelpText: Boolean
    },
    computed: {
        legends() {
            return this.$store.state[this.formId].legends
        }
    }
}
</script>

<style scoped>

</style>