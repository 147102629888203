<template>
    <ImageSelection :form-id="formId" :column-name="field.name" :file-id="fileId" :on-change="onChange"
                    :readonly="field.readonly" :remove-option="true" :row-id="currentData.id" :table-name="tableName"/>
</template>

<script>
import ImageSelection from '../ImageSelection'

export default {
    name: "FormControlTypeImage",
    components: {
        ImageSelection
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        fileId() {
            return this.currentData[this.field.name]
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
    },
    created() {
        let value = this.currentData[this.field.name]

        if (typeof value === 'undefined') {
            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: 0
            })
        }
    },
    methods: {
        onChange(item) {
            this.currentData[this.field.name] = item.id

            // todo - This should probably be a directive that updates
            // this.currentData.title when fieldData.fileName changes.
            if (
                (
                    this.tableName === 'm_images'
                    || this.tableName === 'documents'
                )
                && this.currentData.title === ''
            ) {
                this.currentData.title = item.fileName
            }
        }
    }
}
</script>

<style scoped>

</style>