var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center" },
      [
        _vm.currentData.creditAmount
          ? _c(
              "FormControlPlainText",
              { staticClass: "d-inline m-0", attrs: { "form-id": _vm.formId } },
              [
                _vm._v(
                  "\n            £" +
                    _vm._s(_vm.currentData.creditAmount) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "Button",
          {
            staticClass: "btn-sm ms-2",
            attrs: { disabled: !_vm.currentData.id },
            nativeOn: {
              click: function ($event) {
                return _vm.openAddCreditModal.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n            Add credit\n        ")]
        ),
        _vm._v(" "),
        _c(
          "Button",
          {
            staticClass: "btn-sm ms-2",
            attrs: { disabled: !_vm.currentData.id },
            nativeOn: {
              click: function ($event) {
                return _vm.openDeleteCreditModal.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n            Delete credit\n        ")]
        ),
        _vm._v(" "),
        _c(
          "Button",
          {
            staticClass: "btn-sm ms-2",
            nativeOn: {
              click: function ($event) {
                return _vm.openCreditHistoryModal.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n            View credit history\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }