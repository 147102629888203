<template>
    <button @click="copyShareToClipboard" class="d-flex align-items-center" type="button">
        <i class="me-2 bi-share"/>
        Share
        <small class="ms-1 badge text-bg-warning">beta</small>
    </button>
</template>

<script>
export default {
    name: "ListingOptionsShareBtn",
    props: {
        viewId: String,
    },
    computed: {
        shareUrl() {
            return this.$store.getters[this.viewId + '/shareUrl']
        }
    },
    methods: {
        copyShareToClipboard() {
            try {
                navigator.clipboard.writeText(this.shareUrl)

                this.$store.dispatch('toasts/add', {
                    //title: 'Copied to clipboard.',
                    body: 'Copied to clipboard.',
                    //colourScheme: 'success'
                })
            } catch (error) {
                console.error(error.message)

                this.$store.dispatch('toasts/add', {
                    //title: 'Share URL',
                    body: this.shareUrl,
                    //colourScheme: 'danger'
                })
            }
        }
    }
}
</script>

<style scoped>

</style>