<template>
    <div class="d-flex flex-column h-100 overflow-hidden">
        <PageEditorToolbar/>

        <div ref="container" class="flex-fill d-flex h-100 overflow-hidden">
            <PageEditorLeftSidebar/>

            <div class="flex-fill position-relative overflow-hidden w-100 h-100 d-flex">
                <PageEditorIframe v-if="pageId" :page-id="pageId" :key="pageId"/>

                <PageEditorContentAreaIframe v-if="contentAreas.length" v-for="contentArea in contentAreas"
                                             :content-area="contentArea" :key="contentArea.name"/>

                <PageEditorContentIframe v-for="o in contentItems" :content-item="o" :key="o.pageContentId"/>
            </div>

            <PageEditorRightSidebar/>
        </div>

        <PageEditorContentAreaOverlay v-if="hoverContentAreaName" :key="hoverContentAreaName"/>
        <PageEditorContentOverlay v-if="hoverPageContentId" :key="hoverPageContentId"/>
    </div>
</template>

<script>
import PageEditorContentOverlay from "./PageEditorContentOverlay.vue"
import PageEditorContentAreaOverlay from "./PageEditorContentAreaOverlay.vue"
import Form from "../../common/form/Form.vue"
import PageEditorContentIframe from "./PageEditorContentIframe.vue"
import PageEditorContentAreaIframe from "./PageEditorContentAreaIframe.vue"
import PageEditorToolbar from "./PageEditorToolbar.vue"
import PageEditorIframe from "./PageEditorIframe.vue"
import PageEditorSiteTree from "./PageEditorSiteTree.vue"
import siteTree from "../../store/modules/siteTree"
import PageEditorLeftSidebar from "./PageEditorLeftSidebar.vue"
import PageEditorRightSidebar from "./PageEditorRightSidebar.vue"

export default {
    name: "PageEditor",
    components: {
        PageEditorRightSidebar,
        PageEditorLeftSidebar,
        PageEditorSiteTree,
        PageEditorIframe,
        PageEditorToolbar,
        PageEditorContentAreaIframe,
        PageEditorContentIframe,
        Form,
        PageEditorContentAreaOverlay,
        PageEditorContentOverlay
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        contentAreas() {
            return this.pageEditor.contentAreas
        },
        contentItems() {
            return this.pageEditor.contentItems
        },
        hoverContentAreaName() {
            return this.pageEditor.hoverContentAreaName
        },
        hoverPageContentId() {
            return this.pageEditor.hoverPageContentId
        }
    },
    created() {
        this.$store.dispatch('pageEditor/loadSettings')

        const siteTreeId = this.$store.state.pageEditor.siteTreeId
        if (!this.$store.hasModule(siteTreeId)) {
            this.$store.registerModule(siteTreeId, siteTree)
        }
        this.$store.dispatch(siteTreeId + '/init')

        const pageId = this.$store.state.pageEditor.pageId || this.$store.state.settings.homePage
        this.$store.commit(siteTreeId + '/selectedPageId', pageId)

        if (!this.$route.params.pageId) {
            this.$router.push({name: 'pageEditor', params: {pageId: pageId}})
        }

        document.addEventListener('keyup', this.deleteEvent)
    },
    destroyed() {
        document.removeEventListener('keyup', this.deleteEvent)
    },
    watch: {
        $route: {
            // This doesn't work because the VueX module's init has yet to be called
            immediate: true,
            handler(to, from) {
                if (to.params?.pageId) {
                    this.$store.commit('pageEditor/pageId', parseInt(to.params?.pageId))
                }

                this.$store.commit('pageEditor/selectedContentAreaName', to.query.area)
                this.$store.commit('pageEditor/activePageContentId', parseInt(to.query.id))
            }
        }
    },
    methods: {
        deleteEvent(e) {
            this.$store.dispatch('pageEditor/keyboardEventHandler', e)
        }
    }
}
</script>

<style scoped>

</style>