<template>
    <form ref="form" v-if="show" @submit.prevent="onSubmit" class="input-group d-inline-flex w-auto">
        <input ref="input" v-model="name" list="savedFilterNames" autocomplete="false" placeholder="Name" required
               class="form-control form-control-sm"/>

        <datalist v-if="savedFilterNames" id="savedFilterNames">
            <option v-for="name in savedFilterNames" :value="name"></option>
        </datalist>

        <button class="btn btn-sm btn-light focus-ring border border-start-0">
            Save
        </button>
    </form>
</template>

<script>
export default {
    name: "SearchFilterSaveFiltersForm",
    props: {
        viewId: String
    },
    data() {
        return {
            name: ''
        }
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        show() {
            return Object.values(this.filters).length > 0
        },
        savedFilters() {
            return this.$store.state[this.viewId].savedFilters
        },
        isSelected() {
            return this.savedFilters.find(o => JSON.stringify(o.filters) === JSON.stringify(this.filters))
        },
        savedFilterNames() {
            return this.savedFilters.map(o => o.name)
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch(this.viewId + '/saveFilters', this.name)
            this.name = ''
        }
    }
}
</script>

<style scoped>

</style>