var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "dropzone", staticClass: "dropzone" }, [
    _c("div", { staticClass: "d-flex align-items-start" }, [
      _vm.thumbnailSrc
        ? _c("div", { staticClass: "me-3" }, [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.showLightbox.apply(null, arguments)
                  },
                },
              },
              [
                _c("img", {
                  class: { "form-field-image-img-progress": _vm.progress },
                  attrs: { alt: _vm.fileData.fileName, src: _vm.thumbnailSrc },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "media-body" }, [
        _c(
          "div",
          {},
          [
            _vm.fileData.fileName
              ? _c("FormControlPlainText", {
                  attrs: {
                    value: _vm.fileData.fileName,
                    "form-id": _vm.formId,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              ref: "fileInput",
              staticClass: "d-none",
              attrs: { accept: _vm.inputAccept, type: "file" },
              on: { change: _vm.onChangeFileInput },
            }),
            _vm._v(" "),
            _vm.showUploadOptions
              ? _c("div", [
                  _vm.tableName === _vm.filesTableName
                    ? _c("span", [_vm._v("Drag file here or")])
                    : _c("span", [_vm._v("Drag file here,")]),
                  _vm._v(" "),
                  _vm.tableName !== _vm.filesTableName
                    ? _c("span", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light btn-sm",
                            attrs: { type: "button" },
                            on: { click: _vm.openImageBrowser },
                          },
                          [
                            _vm._v(
                              "\n                            Browse\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("or")]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-light btn-sm wizard-form-field-file-choose-file",
                      attrs: { type: "button" },
                      on: { click: _vm.chooseFile },
                    },
                    [
                      _vm._v(
                        "\n                        Upload\n                    "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.progress
              ? _c(
                  "div",
                  {
                    staticClass: "progress mt-2 mb-1",
                    staticStyle: { height: "5px" },
                  },
                  [
                    _c("div", {
                      staticClass: "progress-bar",
                      style: { width: _vm.progress + "%" },
                      attrs: { role: "progressbar" },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.fileData.id && !_vm.readonly
              ? _c(
                  "div",
                  { staticClass: "d-flex align-items-center mt-3" },
                  [
                    _vm.tableName !== _vm.filesTableName
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-light btn-sm",
                            attrs: { type: "button" },
                            on: { click: _vm.openImageBrowser },
                          },
                          [
                            _vm._v(
                              "\n                        Browse\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.removeOption
                      ? [
                          _c("span", { staticClass: "ms-2" }, [_vm._v("or")]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light btn-sm ms-2",
                              attrs: { type: "button" },
                              on: { click: _vm.removeFile },
                            },
                            [
                              _vm._v(
                                "\n                            Remove\n                        "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }