var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field.readonly
    ? _c("FormControlPlainText", {
        attrs: {
          value: _vm.currentData[_vm.field.name],
          "form-id": _vm.formId,
        },
      })
    : _c("span", { staticClass: "d-flex-inline align-items-center" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentData[_vm.field.name],
              expression: "currentData[field.name]",
            },
            {
              name: "select",
              rawName: "v-select",
              value: _vm.field.autofocus,
              expression: "field.autofocus",
            },
            { name: "form-validation", rawName: "v-form-validation" },
          ],
          ref: "input",
          staticClass: "form-control w-auto",
          class: {
            "is-invalid": _vm.field.error,
            "form-control-sm": _vm.formSmall,
          },
          attrs: {
            id: _vm.field.id,
            disabled: _vm.field.disabled,
            max: _vm.field.max,
            min: _vm.field.min,
            name: _vm.field.name,
            required: _vm.field.required,
            step: _vm.field.step,
            autocomplete: "off",
            type: "datetime-local",
          },
          domProps: { value: _vm.currentData[_vm.field.name] },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.currentData, _vm.field.name, $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.value
          ? _c(
              "button",
              {
                staticClass: "btn btn-link",
                attrs: { type: "button" },
                on: { click: _vm.clearValue },
              },
              [_vm._v("clear")]
            )
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }