<template>
    <FormSidebarBtn v-if="show" @click.prevent.native="onClick" class="text-danger">
        <i class="me-2 bi-trash"/>
        Delete {{ singularLabel }}
    </FormSidebarBtn>
</template>

<script>
import FormSidebarBtn from "./FormSidebarBtn.vue"

export default {
    name: "FormDeleteBtn",
    components: {FormSidebarBtn},
    props: {
        formId: String,
        alwaysShow: Boolean
    },
    data() {
        return {
            hasVariations: false,
        }
    },
    computed: {
        isArchived() {
            return this.$store.state[this.formId].currentData.isArchived
        },
        productTypeId() {
            return this.$store.state[this.formId].currentData.productTypeId
        },
        show() {
            // Allows individual uses of the button to always be shown, such as the Website Editor's content sidebar
            if (this.alwaysShow) {
                return true
            }

            return (
                this.id
                && this.component.deleteOption
                && (
                    this.isArchived
                    || !this.component.useArchive
                )
                && this.tableName !== 'product_options'
                && !this.hasVariations
                && this.hasAccess
            )
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        component() {
            return this.$store.state.components.items.find(obj => obj.id === this.componentId)
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        hasAccess() {
            return this.$store.getters['user/access'](this.tableName, 'delete')
        },
        singularLabel() {
            return this.$store.state[this.formId].singularLabel
        }
    },
    watch: {
        productTypeId(value) {
            if (
                this.tableName === 'product_options'
                && value
            ) {
                this.$store.dispatch('request/post', {
                        url: 'api/ProductTypes/hasVariations',
                        postData: {
                            typeId: value
                        }
                    })
                    .then((obj) => {
                        this.hasVariations = obj.data.count > 0
                    })
            } else {
                this.hasVariations = false
            }
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch(this.formId + '/delete', {
                siteTree: this.$route.name === 'siteTree'
            })
        }
    }
}
</script>

<style scoped>

</style>