var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showDisplayOrderOption
    ? _c("label", { staticClass: "d-flex align-items-center" }, [
        _c(
          "div",
          { staticClass: "dropdown me-2" },
          [
            _c(
              "Button",
              {
                staticClass: "btn-sm dropdown-toggle border",
                attrs: {
                  id: "dropdownMenuDisplayOrder",
                  "aria-expanded": "true",
                  "data-bs-toggle": "dropdown",
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.currentData.displayOrder) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "dropdown-menu",
                attrs: { "aria-labelledby": "dropdownMenuDisplayOrder" },
              },
              _vm._l(_vm.options, function (i) {
                return _c("li", [
                  _c(
                    "button",
                    {
                      staticClass: "dropdown-item",
                      class: { active: i === _vm.currentData.displayOrder },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.setDisplayOrder(i)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(i) +
                          "\n                "
                      ),
                    ]
                  ),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _vm._v("\n\n    Display order\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }