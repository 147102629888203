<template>
    <aside class="border-end overflow-y-scroll" :class="{'open':activeTab,'closed':!activeTab}">
        <section class="d-flex h-100">
            <div class="d-flex flex-column" style="margin-top: -1px">
                <PageEditorContentPanelTabBtn v-for="tab in tabs" :key="tab.name" :icon-class="tab.icon"
                                              :tab-name="tab.name" :active-tab-prop-name="'leftSidebarActiveTab'"
                                              :border-class-name="'border-end'"/>

                <div class="flex-fill bg-body-tertiary border-end"></div>
            </div>

            <!-- Right margin is used here so that when overflow-scroll kicks in for controls too wide for the sidebar,
                 some spacing appears to the right of the controls. -->
            <div v-if="activeTab" class="flex-fill overflow-scroll">
                <template v-if="activeTab === 'Site tree'">
                    <PageEditorSiteTree/>
                </template>

                <PageEditorContentNav v-if="activeTab === 'Content'"/>
            </div>
        </section>
    </aside>
</template>

<script>
import siteTree from "../../store/modules/siteTree"
import PageEditorContentPanelTabBtn from "./PageEditorContentPanelTabBtn.vue"
import PageEditorSiteTree from "./PageEditorSiteTree.vue"
import PageEditorContentNav from "./PageEditorContentNav.vue"

export default {
    name: "PageEditorLeftSidebar",
    components: {
        PageEditorContentNav,
        PageEditorContentPanelTabBtn,
        PageEditorSiteTree
    },
    data() {
        return {
            tabs: [
                {name: 'Site tree', icon: 'bi-diagram-3'},
                {name: 'Content', icon: 'bi-list'}
            ]
        }
    },
    computed: {
        activeTab() {
            return this.$store.state.pageEditor.leftSidebarActiveTab
        }
    },
    created() {
        const siteTreeId = this.$store.state.pageEditor.siteTreeId
        if (!this.$store.hasModule(siteTreeId)) {
            this.$store.registerModule(siteTreeId, siteTree)
        }
        this.$store.dispatch(siteTreeId + '/init')

        const pageId = this.$store.state.pageEditor.pageId || this.$store.state.settings.homePage
        this.$store.commit(siteTreeId + '/selectedPageId', pageId)
    }
}
</script>

<style scoped>
.closed {
    flex: 0 0 32px
}

.open {
    flex: 0 0 250px
}
</style>