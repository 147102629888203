<template>
    <span class="d-flex align-items-center position-relative">
        <i class="bi bi-search"/>
        <input ref="input" v-model="searchTerm" class="form-control form-control-sm rounded-5 p-0 pe-2"
               :class="{'active': active}">
    </span>
</template>

<script>
export default {
    name: "SearchFilterToolbarSearchField",
    components: {},
    props: {
        viewId: String
    },
    computed: {
        searchTerm: {
            set(val) {
                this.$store.dispatch(this.viewId + '/searchTerm', val)
            },
            get() {
                return this.$store.state[this.viewId].searchTerm
            }
        },
        active() {
            return this.searchTerm !== ''
        }
    },
    mounted() {
        this.$refs.input.focus()
    }
}
</script>

<style scoped>
input {
    transition: all 0.2s;
    padding-left: 30px !important;
    max-width: 120px;
}

/* This causes issues with clicking on items to the right of the search field
input:focus,
input.active {
    max-width: 150px;
}*/

i {
    position: absolute;
    left: 7px;
    color: #999;
}
</style>