<template>
    <Button v-if="show && showText" @click.native="archiveSelectItems" class="d-flex align-items-center">
        <i class="me-2 bi-archive"/>
        {{ text }}
    </Button>

    <Button v-else-if="show" @click.native="archiveSelectItems">
        <i class="bi-archive"/>
        {{ text }}
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ListingOptionsArchiveBtn",
    components: {Button},
    props: {
        viewId: String,
        showText: Boolean
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        selectedIds() {
            return this.$store.state[this.viewId].selectedIds
        },
        allSelected() {
            return this.$store.state[this.viewId].allSelected
        },
        allItemsSelected() {
            return this.$store.state[this.viewId].allItemsSelected
        },
        itemCount() {
            return this.$store.state[this.viewId].itemCount
        },
        showArchive() {
            return this.$store.state[this.viewId].showArchive
        },
        homePageIsSelected() {
            return (
                this.tableName === 'pages'
                && this.homePageId
                && this.selectedIds.indexOf(this.homePageId) > -1
            )
        },
        component() {
            return this.$store.state.components.items.filter(obj => obj.tableName === this.tableName)[0]
        },
        useArchive() {
            return this.component.useArchive
        },
        show() {
            return (
                this.useArchive
                && this.hasArchiveAccess
                && this.selectedIds.length
                && !this.homePageIsSelected
            )
        },
        text() {
            let action = this.showArchive ? 'Un-archive' : 'Archive'
            let count = this.selectedIds.length
            return this.showText
                ? action + ' ' + count + ' item' + (count > 1 ? 's' : '')
                : ''
        },
        homePageId() {
            return this.$store.state.settings.homePage
        },
        hasArchiveAccess() {
            return this.$store.getters['user/access'](this.tableName, 'archive')
        }
    },
    methods: {
        archiveSelectItems() {
            if (
                this.tableName === 'pages'
                && this.selectedIds.indexOf(this.homePageId) > -1
            ) {
                this.$store.dispatch('toasts/add', {
                    body: 'The home page cannot be archived.'
                })

                return false
            }

            if (this.selectedIds.length) {

                let title = ''
                let alertMsg = ''
                if (this.allItemsSelected) {
                    title = 'CONFIRM BULK ACTION'
                    alertMsg = 'This action will affect all ' + this.itemCount + ' items.' +
                        '<br>' +
                        '<br>' +
                        'Are you sure you want to continue?'
                    //'This action will affect all ' + this.itemCount + ' items.' +
                    //'Are you sure you want to continue?'
                } else if (this.showArchive) {
                    title = 'Unarchiving ' + this.selectedIds.length + ' item' + (this.selectedIds.length > 1 ? 's' : '')
                    alertMsg = 'Are you sure?'
                } else {
                    title = 'Archiving ' + this.selectedIds.length + ' item' + (this.selectedIds.length > 1 ? 's' : '')
                    alertMsg = 'Are you sure?'
                }

                this.$store.dispatch('modals/show', {
                    componentName: 'ConfirmModal',
                    obj: {
                        modalTitle: title,
                        modalBody: `
                        <p class="alert alert-danger">
                            ` + alertMsg + `
                        </p>
                    `,
                        onConfirm: () => {
                            this.$store.dispatch('request/post', {
                                    url: 'api/ListingController/archive',
                                    postData: {
                                        tableName: this.tableName,
                                        ids: this.selectedIds,
                                        archive: !this.showArchive,
                                        archiveAll: this.allItemsSelected
                                    }
                                })
                                .then(() => {
                                    this.$store.commit('cacheNeedsClearing')
                                    this.$store.commit(this.viewId + '/selectNone')
                                    this.$store.dispatch(this.viewId + '/scheduleLoad')

                                    if (
                                        this.tableName === 'pages'
                                        || this.tableName === 'content'
                                    ) {
                                        // Refresh site tree pane
                                        this.$store.dispatch('siteTree/init')

                                        // After archiving content pageContentData must be refreshed so the archived content
                                        // is not associated to its pages.
                                        this.$store.dispatch('pageContentData/init')
                                    }
                                })
                        }
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>