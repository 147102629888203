<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name]" :form-id="formId"/>
    <input v-else :id="field.id" v-model="currentData[field.name]" v-select="field.autofocus" :disabled="field.disabled"
           :max="field.max" :min="field.min" :name="field.name" :step="field.step" autocomplete="off" type="range">
</template>

<script>
import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeRange",
    components: {FormControlPlainText},
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: ''
        })
    }
}
</script>

<style scoped>

</style>