<template>
    <div v-if="fieldData.tableName">
        <FormControlPlainText v-if="field.readonly" :form-small="formSmall" :form-id="formId">
            <FormControlCategoryTitle :id="currentData[field.name]" :table-name="fieldData.tableName"/>
        </FormControlPlainText>

        <div v-else>
            <input v-model="currentData[field.name]" :name="field.name" :required="field.required"
                   class="visually-hidden">

            <div class="mb-2">
                <Button @click.native="selectCategories">
                    <i class="bi-plus-lg"/>
                </Button>

                <Button v-user-access="fieldData.tableName" @click.native="manageCategories">
                    <i class="bi-pencil-square"/>
                </Button>
            </div>

            <template v-if="currentData[field.name]">
                <OrderDetailsCustomerPanel v-if="isOrderCustomerControl" :customer-id="value"/>

                <div v-else class="list-group">
                    <a class="list-group-item d-flex align-items-center" href @click.prevent="editItem">
                        <FormControlCategoryTitle :id="currentData[field.name]" :table-name="fieldData.tableName"
                                                  class="flex-fill"/>

                        <ItemStatusIcons :id="currentData[field.name]" :table-name="fieldData.tableName"/>

                        <Button @click.stop.prevent.native="removeCategory">
                            <i class="bi-x-lg"/>
                        </Button>
                    </a>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import FormControlCategoryTitle from '../../common/form-control/FormControlCategoryTitle'
import OrderDetailsCustomerPanel from '../../components/order-details/OrderDetailsCustomerPanel'
import userAccess from '../../vue/directives/userAccess'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"
import Button from "../Button.vue"
import ItemStatusIcons from "../ItemStatusIcons.vue"

export default {
    name: "FormControlTypeRelationshipOneToMany",
    components: {
        ItemStatusIcons,
        Button,
        FormControlPlainText,
        FormControlCategoryTitle,
        OrderDetailsCustomerPanel
    },
    directives: {
        userAccess
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        value: [Number, String]
    },
    data() {
        return {
            fieldData: {
                tableName: undefined,
            },
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        isOrderCustomerControl() {
            return this.tableName === 'm_basket_orders' && this.field.columnName === 'customerId'
        },
        formSmall() {
            return this.$store.state[this.formId].formSmall
        }
    },
    created() {
        let component = this.$store.state.components.items.find(o => o.id === this.field.categoryComponentId)

        // When this component is manually embedded using a static field array a tableName can be provided.
        if (this.field.tableName) {
            this.fieldData.tableName = this.field.tableName
        } else {
            this.fieldData.tableName = component.tableName
        }

        // todo - This test should not be necessary, ON CASCADE DELETE should be in place.
        // If an ID is set then double check that the item hasn't since been deleted. If it has then an
        // erroneous blank tab would appear.
        let id = this.currentData[this.field.name]
        if (id) {
            // Ignore site tree options
            if (this.field.name.indexOf('content__') !== 0) {
                // This check ensures that the stored ID still exists in the component.
                // If not then we should unset it.
                return this.$store.dispatch('request/get', {
                        url: 'api/component/' + component.tableName,
                        params: {
                            field: 'id',
                            id: id,
                        }
                    })
                    .then((obj) => {
                        if (obj.data.values.indexOf(id) === -1) {
                            this.$store.commit(this.formId + '/presetData', {
                                name: this.field.name,
                                value: this.getDefault(),
                            })
                        }
                    })
            }

        } else {
            const idStr = this.$route.query[this.field.name]
            const id = idStr ? parseInt(idStr) : this.getDefault()
            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: id,
            })
        }
    },
    methods: {
        getDefault() {
            // Required validation will only work if the hidden input's value is an empty string.
            return this.field.required ? '' : 0
        },
        manageCategories() {
            if (this.fieldData.tableName === 'templates_templates') {
                this.$router.push({name: 'themesEditor', query: {section: 'templates_templates',}})
            } else {
                this.$router.push({name: 'list', params: {component: this.fieldData.tableName}})
            }
        },
        editItem() {
            if (this.fieldData.tableName === 'templates_templates') {
                this.$router.push({
                    name: 'themesEditor',
                    query: {
                        section: 'templates_templates',
                        id: this.currentData[this.field.name]
                    }
                })
            } else {
                this.$router.push({
                    name: 'form',
                    params: {
                        component: this.fieldData.tableName,
                        id: this.currentData[this.field.name]
                    }
                })
            }
        },
        removeCategory() {
            this.currentData[this.field.name] = this.getDefault()
        },
        selectCategories() {
            // Avoids [""] being provided to the select listing
            let selectedIds = []
            let selectedId = this.currentData[this.field.name]

            if (selectedId) {
                selectedIds.push(selectedId)
            }

            let modal = this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    listingName: this.fieldData.tableName + '.' + this.field.name,
                    selectedIds: selectedIds,
                    onSelect: (newIds) => {
                        let id = newIds.pop()

                        let setValue = (id) => {
                            this.currentData[this.field.name] = id
                            modal.then((obj) => {
                                this.$store.dispatch('modals/remove', obj.index)
                            })
                        }

                        // Only validate parents and ancestors if the field is referencing the same table.
                        if (this.fieldData.tableName === this.tableName) {
                            this.parentIdValidation(id, () => {
                                setValue(id)
                            })
                        } else {
                            setValue(id)
                        }
                    }
                }
            })
        },
        parentIdValidation(id, callback) {
            if (id === this.id) {
                this.$store.dispatch('toasts/add', {
                    body: 'A category cannot be its own parent.'
                })
            } else if (id) {
                this.checkAncestors(id, callback)
            }
        },
        // A category cannot be the child of its own descendant.
        // Recursively check the new parent's ancestors to make sure the current scope.id isn't found.
        checkAncestors(id, callback) {
            this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: id
                })
                .then((obj) => {
                    if (
                        obj.parentId
                        && obj.parentId === this.id
                    ) {
                        this.$store.dispatch('toasts/add', {
                            body: 'A category cannot be the child of its descendant.'
                        })
                    } else if (obj.parentId) {
                        this.checkAncestors(obj.parentId, callback)
                    } else {
                        callback()
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>