export default {
    namespaced: true,
    state() {
        return {
            homePageId: undefined,
            
            lastSelectedPageId: false,
            lastSelectedContentId: false,
            selectedPageId: false,
            selectedContentId: false,
            selectedNavigationGroup: false,
            
            expandedPageIds: [],
            
            // Arrays of page IDs associated to their parent ID.
            // E.g. { 1: [ 2, 3 ], 4: [ 5, 6, 7 ]}
            // IDs with not children are not included.
            // Used to present the child page components.
            parentChildIds: {},
            
            // Arrays of the root level page IDs associated to their navigation groups
            // E.g. { "Primary navigation": [ 1, 2, 3 ], "Secondary navigation": [ 4, 5, 6, ], ... }
            // Used to present the top level page components.
            groupedPageIds: {},
            
            navigationGroups: []
        }
    },
    mutations: {
        addExpandedPageId(state, pageId) {
            if (state.expandedPageIds.indexOf(pageId) === -1) {
                state.expandedPageIds.push(pageId)
            }
        },
        selectedNavigationGroup(state, group) {
            state.selectedNavigationGroup = group
        },
        navigationGroups(state, navigationGroups) {
            state.navigationGroups = navigationGroups
        },
        selectedPageId(state, selectedPageId) {
            state.selectedPageId = selectedPageId
        }
    },
    actions: {
        init({state, dispatch, rootState}) {
            state.homePageId = rootState.settings.homePage
            
            dispatch('request/get', {
                url: 'api/component/pages'
            }, {root: true})
                .then((obj) => {
                    dispatch('itemData/setItems', {
                        tableName: 'pages',
                        objs: obj.data.items,
                    }, {root: true})
                    
                    return dispatch('request/get', {
                        url: 'api/component/content'
                    }, {root: true})
                })
                .then((obj) => {
                    dispatch('itemData/setItems', {
                        tableName: 'content',
                        objs: obj.data.items,
                    }, {root: true})
                    
                    return dispatch('templates/init', false, {root: true})
                })
                .then(() => {
                    dispatch('itemData/setItems', {
                        tableName: 'templates_templates',
                        objs: rootState.templates.items,
                    }, {root: true})
                    
                    return dispatch('itemData/get', {
                        tableName: 'pages',
                        id: state.homePageId,
                    }, {root: true})
                })
                .then((obj) => {
                    // Don't set it if another script has set it whilst its been loading.
                    setTimeout(() => {
                        if (!state.selectedNavigationGroup) {
                            state.selectedNavigationGroup = obj.navigation
                        }
                    })
                    
                    return dispatch('setNavigationGroups')
                })
                .then(() => {
                    return dispatch('setPageHierarchy')
                })
        },
        setNavigationGroups({state, dispatch, commit}, reset) {
            if (state.navigationGroups.length === 0 || reset) {
                return dispatch('request/get', {
                    url: 'api/component/pages',
                    params: {
                        field: 'DISTINCT navigation',
                        sort: 'navigation',
                        navigation: {
                            ne: ''
                        }
                    }
                }, {root: true})
                    .then((o) => {
                        commit('navigationGroups', o.data.values)
                    })
            }
        },
        setPageHierarchy({state, dispatch}) {
            return dispatch('request/get', {
                url: 'api/component/pages',
                params: {
                    fields: ['id', 'navigation', 'parent', 'displayOrder'],
                    isArchived: 0,
                    sort: 'navigation,parent,displayOrder'
                }
            }, {root: true})
                .then((obj) => {
                    let objs = obj.data.items
                    let groupedPageIds = {}
                    let parentChildIds = {}
                    
                    objs.forEach((obj) => {
                        if (obj.parent) {
                            if (parentChildIds[obj.parent] === undefined) {
                                parentChildIds[obj.parent] = []
                            }
                            parentChildIds[obj.parent].push(obj.id)
                        } else {
                            if (groupedPageIds[obj.navigation] === undefined) {
                                groupedPageIds[obj.navigation] = []
                            }
                            groupedPageIds[obj.navigation].push(obj.id)
                        }
                    })
                    
                    state.parentChildIds = parentChildIds
                    state.groupedPageIds = groupedPageIds
                })
        },
        setPageId({state, dispatch}, pageId) {
            pageId = parseInt(pageId)
            
            if (state.selectedPageId) {
                state.lastSelectedPageId = state.selectedPageId
                state.lastSelectedContentId = false
            }
            state.selectedPageId = pageId
            state.selectedContentId = false
            
            // Preserve the last navigation group of pageId === 0, when adding new pages.
            if (pageId) {
                dispatch('setPagesNavigationGroup', pageId)
            }
        },
        setContentId({state, dispatch, rootState}, contentId) {
            contentId = parseInt(contentId)
            
            if (contentId === 0) {
                if (state.selectedContentId) {
                    state.lastSelectedContentId = state.selectedContentId
                } else if (state.selectedPageId) {
                    state.lastSelectedPageId = state.selectedPageId
                }
                state.selectedContentId = contentId
                state.selectedPageId = false
            } else {
                let pageId
                let obj = rootState.pageContentData.items.find(o => o.id === contentId)
                // When content is archived state is reloaded but the archived content is no longer available.
                if (obj) {
                    pageId = obj.pageId
                    return dispatch('itemData/get', {
                        tableName: 'content',
                        id: obj.contentId,
                    }, {root: true})
                        .then((contentData) => {
                            // If the data doesn't exist then the content may have been deleted.
                            if (contentData) {
                                if (state.selectedContentId) {
                                    state.lastSelectedContentId = state.selectedContentId
                                } else if (state.selectedPageId) {
                                    state.lastSelectedPageId = state.selectedPageId
                                }
                                state.selectedContentId = contentId
                                state.selectedPageId = false
                                
                                dispatch('setPagesNavigationGroup', pageId)
                            }
                        })
                }
            }
        },
        setPagesNavigationGroup({state, dispatch}, pageId) {
            return dispatch('itemData/get', {
                tableName: 'pages',
                id: pageId,
            }, {root: true})
                .then((pageData) => {
                    // If the data doesn't exist then the page may have been deleted.
                    if (pageData) {
                        state.selectedNavigationGroup = pageData.navigation
                    }
                })
        },
        setExpandedPageIds({commit, dispatch}, pageId) {
            if (pageId) {
                dispatch('itemData/get', {
                    tableName: 'pages',
                    id: pageId,
                }, {root: true})
                    .then((obj) => {
                        if (obj.parent) {
                            commit('addExpandedPageId', obj.parent)
                            dispatch('setExpandedPageIds', obj.parent)
                        }
                    })
            }
        },
        refreshSiteTree({state, dispatch}) {
            return dispatch('pageContentData/init', null, {root: true})
                .then(() => {
                    return dispatch('setNavigationGroups')
                })
                .then(() => {
                    return dispatch('setPageHierarchy')
                })
                .then(() => {
                    // This ensures that changing a page's navigation group results in the nav groups updating.
                    if (state.selectedPageId) {
                        dispatch('setPagesNavigationGroup', state.selectedPageId)
                    }
                })
        }
    }
}