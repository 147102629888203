<template>
    <form ref="form">
        <FormGroup class="mb-3" v-for="(field, i) in fields" :key="i" :form-id="formId" :field="field"
                   :hide-help-text="true"/>

        <FormSidebar v-if="activeTab === 'Settings'" :form-id="formId" :show-duplicate-btn="true"
                     :show-history-btn="true" :always-show-delete-btn="true" class="small"/>
    </form>
</template>

<script>
import FormGroup from "../../common/form/FormGroup.vue"
import FormSidebar from "../../common/form/FormSidebar.vue"

export default {
    name: "PageEditorContentForm",
    components: {FormSidebar, FormGroup},
    data() {
        return {
            savePromise: undefined,
            savePromise2: undefined
        }
    },
    props: {
        formId: String
    },
    computed: {
        activeTab() {
            return this.$store.state.pageEditor.contentPanelActiveTab
        },
        fields() {
            return this.$store.state[this.formId]?.fields
        },
        modifiedData() {
            return this.$store?.getters[this.formId + '/modifiedData']()
        },
        pageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        }
    },
    mounted() {
        this.$store.commit(this.formId + '/setFormEl', this.$refs.form)
    },
    watch: {
        modifiedData(newValue) {
            if (!newValue) {
                return
            }

            // todo Perhaps an autosave: true property in the VueX forms module would be better.
            //      See PageEditorPageForm for full script inc. form validation
            if (this.savePromise) {
                clearTimeout(this.savePromise)
                this.savePromise = undefined
            }

            const columnName = Object.keys(this.modifiedData)[0]
            const type = this.fields.find(o => o.columnName === columnName).type

            // Only apply the delay to text input fields.
            // This way, when changing a select menu, for example, the save will be instant.
            const delay = ['texteditor', 'text'].indexOf(type) > -1 ? 500 : 0

            if (delay) {
                // This timeout is cleared as the user types, so a request is only sent after the user stops typing.
                this.savePromise = setTimeout(() => {
                    this.save()
                }, delay)

                // This timeout always occurs, so that when the user is typing consistently fast, so the previous
                // timeout is repeatedly cleared, the UI will periodically update the content so the user can see the
                // new text appear as they type.
                if (!this.savePromise2) {
                    this.savePromise2 = setTimeout(() => {
                        this.savePromise2 = undefined
                        this.save()
                    }, 1000)
                }
            } else {
                this.save()
            }
        }
    },
    methods: {
        async save() {
            await this.$store.dispatch(this.formId + '/save')

            await this.$store.dispatch('pageEditor/reloadContent', this.pageContentId)
        }
    }
}
</script>

<style scoped>

</style>