export default {
    namespaced: true,
    getters: {
        typesOptions: () => (type) => {
            switch (type) {
                case 'checkbox':
                    return [
                        {
                            condition: 'is',
                            predicate: '=',
                            text: 'is'
                        }
                    ]
                
                case 'color':
                case 'range':
                    return [
                        {
                            condition: 'is',
                            predicate: '=',
                            text: 'is',
                            // todo This requires double checking. If the emptied value is "undefined" then this is not
                            //      required. I need to test by setting and unsetting a color and range field.
                            //defaultValue: '',
                        },
                        {
                            condition: 'is not',
                            predicate: 'ne',
                            text: 'is not'
                        }
                    ]
                
                case 'custom':
                case 'customscript':
                    return []
                
                case 'date':
                case 'dateDate':
                case 'datetime-local':
                case 'time':
                    return [
                        {
                            condition: 'date is',
                            predicate: '=',
                            text: 'is',
                            defaultValue: '',
                        },
                        {
                            condition: 'date is not',
                            predicate: 'ne',
                            text: 'is not'
                        },
                        {
                            condition: 'date is after',
                            predicate: 'gt',
                            text: 'is after'
                        },
                        {
                            condition: 'date is before',
                            predicate: 'lt',
                            text: 'is before'
                        },
                        /*{
                            condition: 'date is in the last',
                            text: 'is in the last'
                        },
                        {
                            condition: 'date is not in the last',
                            text: 'is not in the last'
                        }/*,
                        {
                            condition: 'date is in the range',
                            text: 'is in the range'
                        }*/
                    ]
                
                case 'file':
                    return [
                        {
                            condition: 'has file',
                            predicate: 'isSet',
                            text: 'exists',
                            // todo This requires double checking. If the emptied value is "undefined" then this is not
                            //      required. I need to test by setting and unsetting an file field.
                            //defaultValue: '',
                        },
                        {
                            condition: 'does not have file',
                            predicate: 'isNotSet',
                            text: 'does not exist'
                        }
                    ]
                
                case 'hidden':
                    return []
                
                case 'internalLink':
                    return [
                        {
                            condition: 'is',
                            predicate: '=',
                            text: 'is',
                            // todo This requires double checking. If the emptied value is "undefined" then this is not
                            //      required. I need to test by setting and unsetting an internalLink field.
                            //defaultValue: '',
                        },
                        {
                            condition: 'is not',
                            predicate: 'ne',
                            text: 'is not'
                        }
                    ]
                
                case 'number':
                case 'currency':
                    return [
                        {
                            condition: 'is',
                            predicate: '=',
                            text: 'is',
                            defaultValue: '',
                        },
                        {
                            condition: 'is not',
                            predicate: 'ne',
                            text: 'is not'
                        },
                        {
                            condition: 'is less than',
                            predicate: 'lt',
                            text: 'is less than'
                        },
                        {
                            condition: 'is greater than',
                            predicate: 'gt',
                            text: 'is greater than'
                        }
                    ]
                
                // Password cannot use "is set" and "is not set" because it has not top option (e.g. "Select...") and
                // so there's no means to unset the menu.
                case 'password':
                    return [
                        {
                            condition: 'is',
                            predicate: '=',
                            text: 'is',
                            defaultValue: ''
                        }
                    ]
                
                case 'relationshipOneToMany':
                    return [
                        {
                            condition: 'is associated to',
                            predicate: '=',
                            text: 'is'
                        },
                        {
                            condition: 'is not associated to',
                            predicate: 'ne',
                            text: 'is not'
                        },
                        {
                            condition: 'one is set',
                            predicate: 'gt',
                            text: 'is set',
                            value: 0
                        },
                        {
                            condition: 'one is not set',
                            predicate: '=',
                            text: 'is not set',
                            value: 0
                        }
                    ]
                
                case 'relationshipManyToMany':
                    return [
                        {
                            condition: 'is associated to many',
                            predicate: '=',
                            text: 'is'
                        },
                        {
                            condition: 'is not associated to many',
                            predicate: 'ne',
                            text: 'is not'
                        },
                        {
                            condition: 'many is set',
                            predicate: 'isSet',
                            text: 'is set'
                        },
                        {
                            condition: 'many is not set',
                            predicate: 'isNotSet',
                            text: 'is not set'
                        }
                    ]
                
                case 'select':
                    return [
                        {
                            condition: 'select is',
                            predicate: '=',
                            text: 'is',
                            // todo This requires double checking. If the emptied value is "undefined" then this is not
                            //      required. I need to test by setting and unsetting a select field.
                            //defaultValue: '',
                        },
                        {
                            condition: 'select is not',
                            predicate: 'ne',
                            text: 'is not'
                        }
                    ]
                
                case 'email':
                case 'tel':
                case 'text':
                case 'textarea':
                case 'url':
                    return [
                        {
                            condition: 'contains',
                            predicate: 'contains',
                            text: 'contains',
                            defaultValue: '',
                        },
                        {
                            condition: 'does not contain',
                            predicate: 'doesNotContain',
                            text: 'does not contain'
                        },
                        {
                            condition: 'is',
                            predicate: '=',
                            text: 'is'
                        },
                        {
                            condition: 'is not',
                            predicate: 'ne',
                            text: 'is not'
                        },
                        {
                            condition: 'starts with',
                            predicate: 'startsWith',
                            text: 'starts with'
                        },
                        {
                            condition: 'does not start with',
                            predicate: 'doesNotStartWith',
                            text: 'does not start with'
                        },
                        {
                            condition: 'ends with',
                            predicate: 'endsWith',
                            text: 'ends with'
                        },
                        {
                            condition: 'does not end with',
                            predicate: 'doesNotEndWith',
                            text: 'does not end with'
                        },
                        {
                            condition: 'is set',
                            predicate: 'isSet',
                            text: 'is set'
                        },
                        {
                            condition: 'is not set',
                            predicate: 'isNotSet',
                            text: 'is not set'
                        }
                    ]
                
                case 'texteditor':
                    return [
                        {
                            condition: 'contains',
                            predicate: 'contains',
                            text: 'contains',
                            defaultValue: '',
                        },
                        {
                            condition: 'does not contain',
                            predicate: 'doesNotContain',
                            text: 'does not contain'
                        },
                        {
                            condition: 'is set',
                            predicate: 'isSet',
                            text: 'is set'
                        },
                        {
                            condition: 'is not set',
                            predicate: 'isNotSet',
                            text: 'is not set'
                        }
                    ]
                
                default:
                    return []
            }
        }
    }
}