var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-link btn-sm rounded-5 text-decoration-none",
      attrs: {
        role: "button",
        "data-bs-toggle": "offcanvas",
        href: "#" + _vm.offCanvasId,
        "aria-controls": _vm.offCanvasId,
      },
    },
    [_vm._v("\n    filters\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }