<template>
    <div ref="overlay" v-show="show">
        <a @click.prevent="edit" role="button" href
           class="d-block w-100 h-100 d-flex align-items-center justify-content-center h1 text-body-tertiary text-decoration-none">
        </a>
    </div>
</template>

<script>
export default {
    name: "PageEditorContentAreaOverlay",
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        contentAreaName() {
            return this.pageEditor.hoverContentAreaName || this.pageEditor.selectedContentAreaName || this.pageEditor.highlightContentAreaName
        },
        activeContentArea() {
            return this.$store.state.pageEditor.contentAreas.find(o => o.name === this.contentAreaName)
        },
        show() {
            return !!this.contentAreaName
        }
    },
    mounted() {
        this.activeContentArea.iframe.contentDocument.body.append(this.$refs.overlay)
    },
    methods: {
        edit() {
            if (this.$route.query.area !== this.contentAreaName) {
                const pageId = this.$store.state.pageEditor.pageId
                this.$router.push({name: 'pageEditor', params: {pageId: pageId,}, query: {area: this.contentAreaName}})
            }
        }
    }
}
</script>

<style scoped>

</style>