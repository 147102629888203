var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "a",
        {
          staticClass:
            "filter d-inline-flex align-items-center px-3 py-1 rounded-5 border text-bg-light position-relative small text-nowrap text-decoration-none focus-ring",
          attrs: { href: "", role: "button", "aria-controls": _vm.offCanvasId },
          on: {
            click: [
              function ($event) {
                $event.preventDefault()
              },
              _vm.openOffcanvas,
            ],
          },
        },
        [
          _c("small", [_vm._v(_vm._s(_vm.columnTitle))]),
          _vm._v(" "),
          _c("small", { staticClass: "mx-1 text-secondary" }, [
            _vm._v(_vm._s(_vm.filter.conditionText)),
          ]),
          _vm._v(" "),
          _c("small", [_vm._v(_vm._s(_vm.value))]),
          _vm._v(" "),
          _c(
            "small",
            {
              staticClass:
                "filter-delete-btn btn btn-sm btn-light rounded-5 p-0 lh-1 position-absolute end-0",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.resetFilter.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "bi bi-x" })]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }