<template>
    <FormControlPlainText v-if="currentData.id && !betaMode" :value="title" :form-id="formId"/>
    <FormControlTypeSelect v-else-if="show" :current-data="currentData" :field="field" :form-id="formId"/>
</template>

<script>
import FormControlTypeSelect from "../form-control-type/FormControlTypeSelect.vue"
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlTemplatesTemplatesComponentId",
    components: {FormControlPlainText, FormControlTypeSelect},
    props: {
        formId: String,
        field: Object,
        currentData: Object
    },
    computed: {
        components() {
            return this.$store.state.components.items
        },
        options() {
            return this.components
                .filter(o => o.listing === 1 || o.tableName === 'm_registrants_registrants')
                .map(o => ({value: o.id, text: o.title}))
                .sort((a, b) => (a.text > b.text) ? 1 : -1)
        },
        contentType() {
            return this.currentData.contentType
        },
        topOptionValue() {
            return this.field.required ? '' : 0
        },
        show() {
            return true

            /*
            This hid the Component option unless specific types were selected, but it prevented PG and BBB's
            Products - Recently viewed CT from targeting a componentId, and this CT didn't relate to any of the types
            available. I tried setting it as a teaser but this resulted in the CT processing ALL the component's
            items and seizing things up.

            if (['Categories', 'Filter', 'Listing', 'Tags', 'Teaser'].indexOf(this.currentData.contentType) !== -1) {
                this.field.visibility = '';
                return true;
            }

            this.field.visibility = 'hidden';
            this.currentData.componentId = 0;
            return false;
            */
        },
        pageEditorMode() {
            return this.$store.state.pageEditorMode
        },
        betaMode() {
            return this.$store.state.betaMode
        }
    },
    asyncComputed: {
        title() {
            const id = this.currentData[this.field.name]
            if (id) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'modulecomponents',
                    id: id
                })
                    .then(o => o.title)
            } else if (id === 0) {
                return 'All'
            }
        }
    },
    created() {
        this.field.options = this.options

        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: 'componentId',
            value: 0
        })
    },
    watch: {
        contentType() {
            this.field.required = ['Categories', 'Filter', 'Listing', 'Tags', 'Teaser']
                .indexOf(this.currentData.contentType) !== -1

            // When changing the content type, if no componentId has been selected the value must be set
            // to match the first option's value other a blank option is presented instead of "Select..."
            if (
                this.currentData.componentId === ''
                || this.currentData.componentId === 0
            ) {
                this.currentData.componentId = this.field.required ? '' : 0
            }
        }
    }
}
</script>

<style scoped>

</style>