<template>
    <div v-if="type" class="d-flex align-items-top">
        <small class="mt-1 me-2 w-25">{{ label }}</small>

        <div class="w-75">
            <component :is="componentName" v-bind="componentProps"/>

            <template v-if="!componentName && type !== 'hidden'">
                <div v-if="tableName === 'pages' && property === 'content'">
                    <div class="badge bg-secondary">changed</div>
                </div>

                <div v-else class="d-flex align-items-center">
                    <small v-if="oldValue" v-html="oldValue" class="border rounded py-1 px-2"></small>
                    <i v-else class="small">empty</i>

                    <i class="mx-2 bi-chevron-right"/>

                    <small v-if="newValue" v-html="newValue" class="border rounded py-1 px-2"></small>
                    <i v-else class="small">empty</i>
                </div>
            </template>
        </div>

        <button v-if="type !== 'password'" class="position-absolute end-0 top-0 mt-2 me-2 btn btn-secondary btn-sm visible-on-hover-hidden"
                type="button" @click="restore(property, oldValue)">
            restore
        </button>
    </div>
</template>

<script>
import ItemHistoryChangeTypeCheckbox from './ItemHistoryChangeTypeCheckbox'
import ItemHistoryChangeTypeCodeEditor from './ItemHistoryChangeTypeCodeEditor'
import ItemHistoryChangeTypeCustomStatus from './ItemHistoryChangeTypeCustomStatus'
import ItemHistoryChangeTypeDate from './ItemHistoryChangeTypeDate'
import ItemHistoryChangeTypeDateTimeLocal from './ItemHistoryChangeTypeDateTimeLocal.vue'
import ItemHistoryChangeTypeFile from './ItemHistoryChangeTypeFile'
import ItemHistoryChangeTypeInternalLink from './ItemHistoryChangeTypeInternalLink'
import ItemHistoryChangeTypeJson from './ItemHistoryChangeTypeJson'
import ItemHistoryChangeTypePassword from './ItemHistoryChangeTypePassword'
import ItemHistoryChangeTypeTexteditor from './ItemHistoryChangeTypeTexteditor'
import ItemHistoryChangeTypeRelationshipManyToMany from './ItemHistoryChangeTypeRelationshipManyToMany'
import ItemHistoryChangeTypeRelationshipOneToMany from './ItemHistoryChangeTypeRelationshipOneToMany'
import Button from "../Button.vue"

export default {
    name: "ItemHistoryChange",
    components: {
        Button,
        ItemHistoryChangeTypeCheckbox,
        ItemHistoryChangeTypeCodeEditor,
        ItemHistoryChangeTypeCustomStatus,
        ItemHistoryChangeTypeDate,
        ItemHistoryChangeTypeDateTimeLocal,
        ItemHistoryChangeTypeFile,
        ItemHistoryChangeTypeInternalLink,
        ItemHistoryChangeTypeJson,
        ItemHistoryChangeTypePassword,
        ItemHistoryChangeTypeTexteditor,
        ItemHistoryChangeTypeRelationshipManyToMany,
        ItemHistoryChangeTypeRelationshipOneToMany,
    },
    props: {
        formId: String,
        tableName: String,
        id: Number,
        property: String,
        newValue: String|Number,
        oldValue: String|Number
    },
    computed: {
        componentId() {
            // If this is the Content component then the property may relate to a value stored in the content type's
            // component e.g. component_123.
            if (
                this.tableName === 'content'
                && this.contentItem
            ) {
                const tableName = 'component_' + this.contentItem.contentType
                const componentId = this.$store.state.components.componentIds[tableName]

                // Check if the property relates to one of the CT's fields. If it does then by overriding the
                // componentId with the content type component's this.column, this.type will source the CTs field data.
                const field = this.$store.getters['componentStructure/get'](componentId)
                    .find(o => o.columnName === this.property)

                if (field) {
                    return componentId
                }
            }

            return this.$store.state.components.componentIds[this.tableName]
        },
        column() {
            return this.$store.getters['componentStructure/get'](this.componentId)
                .find(o => o.columnName === this.property)
        },
        type() {
            switch (this.property) {
                case 'isArchived':
                    return 'checkbox'

                case 'tags':
                    return this.property
            }

            if (this.column) {
                let type = this.column.type

                if (
                    type === 'checkbox'
                    && this.column.columnName === 'status'
                ) {
                    return 'status'
                }

                return type
            }
        },
        label() {
            switch (this.property) {
                case 'isArchived':
                    return 'Archived'

                case 'tags':
                    return 'Tags'
            }

            if (this.column) {
                return this.column.title
            }
        },
        componentName() {
            let typeComponentNames = {
                status: 'ItemHistoryChangeTypeCustomStatus',
                checkbox: 'ItemHistoryChangeTypeCheckbox',
                codeEditor: 'ItemHistoryChangeTypeCodeEditor',
                date: 'ItemHistoryChangeTypeDate',
                'datetime-local': 'ItemHistoryChangeTypeDateTimeLocal',
                file: 'ItemHistoryChangeTypeFile',
                image: 'ItemHistoryChangeTypeFile',
                internalLink: 'ItemHistoryChangeTypeInternalLink',
                json: 'ItemHistoryChangeTypeJson',
                password: 'ItemHistoryChangeTypePassword',
                relationshipOneToMany: 'ItemHistoryChangeTypeRelationshipOneToMany',
                relationshipManyToMany: 'ItemHistoryChangeTypeRelationshipManyToMany',
                texteditor: 'ItemHistoryChangeTypeTexteditor',
            }

            return typeComponentNames[this.type]
        },
        componentProps() {
            let props = {
                oldValue: this.oldValue,
                newValue: this.newValue

            }

            switch (this.type) {
                case 'checkbox':
                case 'file':
                case 'image':
                case 'internalLink':
                case 'json':
                case 'status':
                    props.value = this.oldValue
                    break

                case 'codeEditor':
                    props.value = this.oldValue
                    props.mode = this.column.codeEditorMode
                    props.label = this.label
                    break

                case 'date':
                    props.value = this.oldValue
                    props.dateFormat = this.column.dateFormat
                    break

                case 'relationshipOneToMany':
                case 'relationshipManyToMany':
                    props.value = this.oldValue
                    props.categoryComponentId = this.column.categoryComponentId
                    break

                case 'texteditor':
                    props.value = this.oldValue
                    props.label = this.label
                    break
            }

            return props
        }
    },
    asyncComputed: {
        contentItem() {
            if (this.tableName === 'content') {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.id
                })
            }
        }
    },
    created() {
        // I've disabled this because to set a prop requires a setter.
        //if (this.property === 'tags') {
        //    this.oldValue = JSON.parse(this.oldValue).join(', ')
        //}
    },
    methods: {
        restore(property, value) {
            let modal = this.$store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Are you sure?',
                    modalBody: `The value being replaced will be saved to the item's history.`,
                    onConfirm: () => {
                        this.$store.dispatch('itemData/update', {
                                tableName: this.tableName,
                                id: this.id,
                                data: {
                                    [property]: value
                                }
                            })
                            .then(() => {
                                this.$store.commit(this.formId + '/presetData', {
                                    name: property,
                                    value: value,
                                })

                                // Toggle the form's fieldsets so that dynamic fields are refreshed else, for example,
                                // editors and date fields won't update.
                                this.$store.commit(this.formId + '/toggleShow')

                                modal.then((obj) => {
                                    this.$store.dispatch('modals/remove', obj.index)
                                })
                            })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>