<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name]" :form-id="formId"/>
    <select v-else :id="field.id" v-model="currentData[field.name]" v-form-validation :name="field.name"
            :required="field.required" class="form-select w-auto"
            :class="{'is-invalid': field.error, 'form-select-sm': formSmall}">
        <!-- todo Support for optgroup
             ng-options="option.value as option.text group by option.group for option in options"
        -->

        <!-- If the field is required then assigning both "selected" and "disabled" means that the user cannot select
             the top option -->
        <option v-if="firstOption" :value="firstOption.value" :selected="field.required" :disabled="field.required">
            {{ firstOption.text }}
        </option>

        <option v-for="option in optionsArray" :value="option.value">
            {{ option.text }}
        </option>
    </select>
</template>

<script>
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeSelect",
    components: {FormControlPlainText},
    directives: {
        formValidation,
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object,
        options: [String, Array, Object],
    },
    data() {
        return {
            optionsArray: this.options || undefined,
        }
    },
    computed: {
        components() {
            return this.$store.state.components.items
        },
        componentIds() {
            return this.$store.state.components.componentIds
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        firstOption() {

            // If the first option's value is an empty string then it's the top option.
            let firstOption = this.optionsArray !== undefined ? this.optionsArray[0] : false

            if (
                firstOption
                && (
                    firstOption.value === ''
                    || firstOption.value === 0
                )
            ) {
                // do nothing
            }
            // Otherwise set the top option
            else {
                let defaultValue = this.getDefaultValue(this.field.type, firstOption)

                return {
                    value: defaultValue,
                    text: this.field.firstOption || 'Select…'
                }
            }
        },
        formSmall() {
            return this.$store.state[this.formId].formSmall
        }
    },
    created() {
        this.preLink()

        switch (true) {

            // The field's structure has been set with default options.
            case !!this.field.options:

                // DEPRECATED - Select options storing data in the options column as line break delimited options
                // todo - Upgrade script should convert them to JSON encoded objects.
                if (typeof this.field.options === 'string') {
                    this.setOptionsArrayFromDeprecatedOptionsString()

                } else if (typeof this.field.options === 'object') {

                    // Support for special strings e.g. ORDER_BY:articles
                    if (
                        this.field.options.length === 1
                        && this.field.options[0].text === ''
                    ) {
                        let value = this.field.options[0].value

                        switch (true) {
                            // E.g. "COLUMN:pages:navigation"
                            case value.indexOf('COLUMN:') === 0:
                                this.setTableColumnOptions(value)
                                break

                            case value.indexOf('FORM_BUILDER') === 0:
                                this.setFormBuilderOptions()
                                break

                            case value.indexOf('ORDER_BY:') === 0:
                                let tableName = value.split(':').pop()
                                this.setOrderByOptions(tableName)
                                break

                            case value === 'COMPONENTS_WITH_TAGS':
                                this.setTagComponentOptions()
                                break

                            case value === 'CATEGORY_LIST_ORDER_BY':
                                this.setCategoryListOrderByOptions()
                                break
                        }

                    } else {
                        this.optionsArray = this.formatOptions(this.field.options)

                        // If the field is a Select with other... then combine the default options with additional options
                        // that users have added.
                        if (this.field.selectType === '1') {
                            this.requestOptions(this.tableName, this.field.name)
                        }
                    }
                }

                break

            // No options were explicitly provided to this component from another component.
            case this.optionsArray === undefined:
                if (this.tableName) {
                    this.requestOptions(this.tableName, this.field.name)
                } else {
                    let component = this.$store.state.components.items.find(o => o.id === this.field.modulecomponentsID)
                    // If a table name is not specified then load the component's table name.
                    this.requestOptions(component.tableName, this.field.name)
                }

                break

        }
    },
    methods: {
        preLink() {
            let value = this.currentData[this.field.name]

            // todo - Check if this is still the case for any sites, if so upgrade them, and remove this code.
            // Some old fields were storing integer values as strings, but they're being properly managed as integers
            // now. This supports older saved data.
            if (
                this.field.name === 'content__component'
                && value
            ) {
                this.$store.commit(this.formId + '/presetData', {
                    name: this.field.name,
                    value: parseInt(value),
                })
            }

            if (
                (value === null || value === undefined)
                // A value should not be set if the field is hidden in case it's preset with an invalid value.
                // For example, a select menu which submits an integer AND is required must have a defaultValue of ""
                // so that it invalidates. This would result in a MySQL error when attempting to save "" into an INT.
                && !this.field.hidden
            ) {
                this.$store.commit(this.formId + '/presetData', {
                    name: this.field.name,
                    value: this.getDefaultValue(this.field.type),
                })
            }
        },
        requestOptions(tableName, fieldName) {
            // todo Temp hack to force navigation CT's navigationGroup field to target the pages component.
            //      This can be removed once Navigation Groups are managed using a component, as the fields will be
            //      updated to use relationshipOneToMany fields.
            if (
                tableName !== 'modulecomponents'
                && fieldName === 'navigationGroup'
            ) {
                tableName = 'pages'
                fieldName = 'navigation'
            }

            this.$store.dispatch('request/get', {
                    url: 'api/component/' + tableName,
                    params: {
                        field: fieldName,
                        groupBy: fieldName,
                        sort: fieldName
                        // Not all components have an archive option. To reinstate this it will need to check
                        // the component's settings.
                        //isArchived: 0
                    }
                })
                .then((obj) => {
                    let options = this.optionsArray || []

                    obj.data.values.forEach((value) => {
                        if (
                            value
                            && value !== ''
                            && options.filter(option => option.value === value).length === 0
                        ) {
                            options.push({
                                value: value,
                                text: value
                            })
                        }
                    })

                    // Sort the options.
                    options = options.sort((a, b) => (a.text > b.text) ? 1 : -1)

                    this.optionsArray = options
                })
        },
        getDefaultValue(type, firstOption) {

            // I'm guessing that this supports the relationshipOneToMany field being presented as a select menu.
            // But if this is the case then I'm not sure why the custom component can't just create a new select
            // menu from scratch.
            let numericTypes = [
                'relationshipOneToMany'
            ]

            let defaultValue = ''

            // A zero value will not invalidate if a select menu is a required field, it must be an empty string.
            if (!this.field.required) {
                if (numericTypes.indexOf(type) > -1) {
                    defaultValue = 0
                } else if (
                    firstOption
                    && typeof firstOption.value === 'number'
                ) {
                    defaultValue = 0
                }
            }

            return defaultValue
        },
        // TODO - Options should be provided in the correct format. If this was the case then this
        // wouldn't be required.
        formatOptions(options) {

            let newOptions = []

            options.forEach((option, key) => {

                // Options are arrays of objects grouped by strings - e.g. {'groupName':[{"value": "text"},{"value": "text"}],'groupName':...}
                if (
                    typeof key === 'string'
                    && typeof option === 'object'
                ) {
                    option.forEach((obj) => {
                        newOptions.push({
                            value: obj.value,
                            text: obj.text,
                            group: key
                        })
                    })

                    // Objects (keys are strings) - e.g. {"value": "text", "value": "text", "value": "text"}
                } else if (
                    typeof key === 'string'
                    && typeof option === 'string'
                ) {
                    newOptions.push({
                        value: key,
                        text: option
                    })

                    // Arrays (keys are integers) - e.g. ["No", "With 'All' option", "With 'None' option"]
                } else if (
                    (
                        typeof key === 'string'
                        || typeof key === 'number'
                    ) && (
                        typeof option === 'string'
                        || typeof option === 'number'
                    )
                ) {
                    newOptions.push({
                        value: key,
                        text: option
                    })

                    // Arrays of objects - e.g. [{"value": "text"},{"value": "text"},{"value": "text"}]
                } else if (
                    typeof key === 'number'
                    && typeof option === 'object'
                ) {
                    // TODO - This is just recreating the same array, so it could be bypassed
                    // and save a bit of processing.
                    newOptions.push({
                        value: option.value,
                        text: option.text,
                        group: option.group
                    })
                }

            })

            return newOptions
        },
        setTableColumnOptions(str) {
            // E.g. "COLUMN:pages:navigation"
            const parts = str.split(':')
            const tableName = parts[1]
            const columnName = parts[2]
            this.requestOptions(tableName, columnName)
        },
        setFormBuilderOptions() {
            let objs = this.components.filter((obj) => {
                return obj.navigationGroup === 'Forms'
            })
            let options = []
            objs.forEach((obj) => {
                options.push({
                    value: obj.tableName,
                    text: obj.title
                })
            })
            this.optionsArray = options
        },
        setOrderByOptions(tableName) {
            let componentId = this.componentIds[tableName]
            let objs = this.$store.getters['componentStructure/get'](componentId)

            objs = objs.filter((obj) => {
                return [
                    'text',
                    'date', // Legacy date menus
                    'dateDate', // HTML date fields
                    'datetime-local' // HTML date fields
                ].indexOf(obj.type) > -1
            })

            // Add display order here so that it's included in the sort
            let component = this.$store.state.components.items.find(o => o.id === componentId)
            if (component.showDisplayOrder) {
                objs.push({
                    columnName: 'displayOrder',
                    title: 'Display order'
                })
            }

            objs = objs.sort((a, b) => (a.title > b.title) ? 1 : -1)

            let options = []

            objs.forEach((obj) => {
                options.push({
                    value: obj.columnName + ' ASC',
                    text: obj.title
                })

                options.push({
                    value: obj.columnName + ' DESC',
                    text: obj.title + ' (desc)'
                })
            })

            options.push({
                value: 'RAND()',
                text: 'Random'
            })

            this.optionsArray = options
        },
        setCategoryListOrderByOptions() {
            this.optionsArray = [
                {
                    value: '',
                    text: ' - none - '
                },
                {
                    value: 'displayOrder',
                    text: 'Display order'
                },
                {
                    value: '-displayOrder',
                    text: 'Display order (desc)'
                },
                {
                    value: 'title',
                    text: 'Title'
                },
                {
                    value: '-title',
                    text: 'Title (desc)'
                },
                {
                    value: 'RAND()',
                    text: 'Random'
                },
            ]
        },
        // todo - This is only used by the deprecated Tags CT.
        setTagComponentOptions() {
            let objs = this.components.filter((obj) => {
                return obj.showTags
            })
            let options = []
            objs.forEach((obj) => {
                options.push({
                    value: obj.tableName,
                    text: obj.title
                })
            })
            this.optionsArray = options
        },
        setOptionsArrayFromDeprecatedOptionsString() {
            let options = []

            let str = this.field.options
            let array = str.trim('\n').split('\n')
            array.forEach((line) => {
                if (line.search('/:/')) {
                    let parts = line.split(':')
                    options.push({
                        value: parts[0],
                        text: parts[1]
                    })
                } else {
                    options.push({
                        value: line,
                        text: line
                    })
                }
            })

            this.optionsArray = options
        }
    }
}
</script>

<style scoped>

</style>