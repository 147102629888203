var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _vm.show
        ? _c(
            "Button",
            {
              staticClass: "rounded-0",
              attrs: {
                "data-bs-toggle": "offcanvas",
                "data-bs-target": "#offcanvasSiteTree",
                "aria-controls": "offcanvasSiteTree",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onClick.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "bi-diagram-3-fill" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "offcanvas",
          staticClass: "offcanvas offcanvas-start",
          attrs: {
            id: "offcanvasSiteTree",
            "aria-labelledby": "offcanvasSiteTreeLabel",
            tabindex: "-1",
          },
        },
        [
          _c(
            "div",
            { staticClass: "offcanvas-header" },
            [
              _c("SiteTreeNavigationMenu", {
                staticClass: "w-auto",
                attrs: { "site-tree-id": _vm.siteTreeId },
              }),
              _vm._v(" "),
              _c("button", {
                ref: "closeBtn",
                staticClass: "btn-close",
                attrs: {
                  type: "button",
                  "data-bs-dismiss": "offcanvas",
                  "aria-label": "Close",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "offcanvas-body" },
            [
              _c("SiteTree", {
                attrs: {
                  "select-content": _vm.selectContent,
                  "select-page": _vm.selectPage,
                  "site-tree-id": _vm.siteTreeId,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showPinBtn
            ? _c(
                "div",
                { staticClass: "offcanvas-footer text-end p-3" },
                [_c("PageEditorSiteTreePinBtn")],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }