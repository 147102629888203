<template>
    <div v-disable-shift-select>
        <template v-if="itemCount === 0">
            <ListingNoContent :view-id="viewId"/>
            <ListingNoContentOptions :view-id="viewId"/>
        </template>

        <div v-if="itemCount > 0" class="row g-3">
            <div v-for="id in ids" :key="id" class="col-xs-6 col-sm-3 col-md-2 col-lg-2">
                <GridViewThumbnail :id="id" :view-id="viewId"/>
            </div>
        </div>
    </div>
</template>

<script>
import ListingNoContent from './ListingNoContent'
import ListingNoContentOptions from './ListingNoContentOptions'
import GridViewThumbnail from './GridViewThumbnail'

import disableShiftSelect from '../../vue/directives/disableShiftSelect'

export default {
    name: "GridView",
    components: {
        ListingNoContent,
        ListingNoContentOptions,
        GridViewThumbnail,
    },
    directives: {
        disableShiftSelect,
    },
    props: {
        viewId: String,
    },
    computed: {
        listingName() {
            return this.$store.state[this.viewId].listingName
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        itemCount() {
            return this.$store.state[this.viewId].itemCount
        },
        ids() {
            return this.$store.state[this.viewId].ids
        },
    },
    created() {
        this.$store.commit('lightbox/reset')
    }
}
</script>

<style scoped>

</style>