<template>
    <aside class="overflow-y-scroll" :class="{'open border-start':isOpen,'closed':!isOpen}">
        <PageEditorContentAreaPanel v-if="selectedContentAreaName"/>
        <PageEditorContentPanel v-else-if="activePageContentId" :key="activePageContentId"/>
        <PageEditorPagePanel v-else :key="pageId"/>
    </aside>
</template>

<script>
import PageEditorContentPanel from "./PageEditorContentPanel.vue"
import PageEditorContentAreaPanel from "./PageEditorContentAreaPanel.vue"
import PageEditorPagePanel from "./PageEditorPagePanel.vue"

export default {
    name: "PageEditorRightSidebar",
    components: {
        PageEditorPagePanel,
        PageEditorContentAreaPanel,
        PageEditorContentPanel
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        selectedContentAreaName() {
            return this.pageEditor.selectedContentAreaName
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        pagePanelActiveTab() {
            return this.pageEditor.pagePanelActiveTab
        },
        contentPanelActiveTab() {
            return this.pageEditor.contentPanelActiveTab
        },
        isOpen() {
            if (this.selectedContentAreaName) {
                return true
            } else if (this.activePageContentId) {
                return !!this.contentPanelActiveTab
            } else {
                return !!this.pagePanelActiveTab
            }
        }
    }
}
</script>

<style scoped>
.closed {
    flex: 0 0 32px
}

.open {
    flex: 0 0 260px
}
</style>