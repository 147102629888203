<template>
    <div class="dashboard-panel-state-history card">
        <div class="card-header">
            Your viewing history
        </div>

        <div class="card-body small">
            <div role="button" @click="open(item.fullPath)" class="breadcrumb" v-if="item.fullPath"
                 v-for="(item, index) in items" :key="item.id"
                 :class="{ 'mb-1': index !== items.length - 1 }">
                <span class="breadcrumb-item">{{ item.componentTitle }}</span>
                <span v-if="item.itemTitle" class="breadcrumb-item">
                        {{ item.itemTitle }}
                    </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DashboardStateHistory",
    computed: {
        items() {
            return this.$store.getters['stateHistory/items']
        }
    },
    methods: {
        open(fullPath) {
            window.location = '#' + fullPath
        }
    }
}
</script>

<style scoped>

</style>