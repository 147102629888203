var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.currentData.createdDate
      ? _c("div", { staticClass: "mb-3" }, [
          _c("label", { staticClass: "form-sidebar-label" }, [
            _vm._v("Created"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex align-items-start" },
            [
              _vm.currentData.createdBy
                ? _c("UserAvatar", {
                    staticClass: "mt-1 me-2",
                    attrs: {
                      "user-id": _vm.currentData.createdBy,
                      "is-small": false,
                      "tooltip-placement": "right",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "small",
                [
                  _vm.currentData.createdBy
                    ? _c("UserName", {
                        attrs: {
                          id: _vm.currentData.createdBy,
                          "no-user-found-str": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getDate(_vm.currentData.createdDate * 1000)) +
                      "\n            "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.currentData.modifiedDate
      ? _c("div", { staticClass: "mb-3" }, [
          _c("label", { staticClass: "form-sidebar-label" }, [
            _vm._v("Modified"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex align-items-start" },
            [
              _vm.currentData.modifiedBy
                ? _c("UserAvatar", {
                    staticClass: "mt-1 me-2",
                    attrs: {
                      "user-id": _vm.currentData.modifiedBy,
                      "is-small": false,
                      "tooltip-placement": "right",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "small",
                [
                  _vm.currentData.modifiedBy
                    ? _c("UserName", {
                        attrs: {
                          id: _vm.currentData.modifiedBy,
                          "no-user-found-str": "<em>user not found</em>",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getDate(_vm.currentData.modifiedDate * 1000)) +
                      "\n            "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }