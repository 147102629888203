var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.currentData.id && !_vm.betaMode
        ? _c("FormControlPlainText", {
            attrs: { value: _vm.templateTitle, "form-id": _vm.formId },
          })
        : _vm.options
        ? _c("FormControlTypeSelect", {
            attrs: {
              "current-data": _vm.currentData,
              field: _vm.field,
              "form-id": _vm.formId,
              options: _vm.options,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentData.templateId
        ? _c("PageContentTemplateContent", {
            attrs: { "template-id": _vm.currentData.templateId },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }