export default {
    namespaced: true,
    state: {
        data: {},
        items: []
    },
    mutations: {
        // todo - DEPRECATED - See actions get() below.
        data(state, data) {
            state.data = data
        },
        items(state, items) {
            state.items = items
        }
    },
    getters: {
        // Providing rootState in the first parentheses generated errors because the property wasn't set.
        get: (state) => (componentId) => {
            return state.items.filter(o => o.modulecomponentsID === componentId)
        },
        getItem: (state) => (id) => {
            return state.items.find(o => o.id === id)
        }
    },
    actions: {
        // Loads all structure data and adds it to itemData module, the stores all structure IDs associated to their
        // component ID, allowing for the get action to an array of a component's structure items.
        init({commit, dispatch}) {
            // Loads ALL structure data.
            return dispatch('request/get', {
                url: 'api/component/modulestructure',
                params: {
                    sort: 'displayOrder'
                }
            }, {root: true})
                .then((obj) => {
                    let items = obj.data.items
                    commit('items', items)
                    
                    // Store data in the itemData module
                    dispatch('itemData/setItems', {
                        tableName: 'modulestructure',
                        objs: items,
                    }, {root: true})
                    
                    let data = {}
                    
                    items.forEach((obj) => {
                        let componentId = obj.modulecomponentsID
                        if (data[componentId] === undefined) {
                            data[componentId] = []
                        }
                        
                        data[componentId].push(obj.id)
                    })
                    
                    commit('data', data)
                })
        }
    }
}