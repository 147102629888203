var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-1 list-group list-group-flush" },
    [
      _vm._l(_vm.contentAreaNames, function (contentAreaName) {
        return [
          _c(
            "a",
            {
              staticClass:
                "list-group-item pt-2 pb-1 border-0 strong text-body-tertiary text-uppercase",
              attrs: { role: "button" },
              on: {
                mouseover: function ($event) {
                  return _vm.mouseoverContentArea(contentAreaName)
                },
                mouseout: _vm.mouseoutContentArea,
                click: function ($event) {
                  return _vm.selectContentArea(contentAreaName)
                },
              },
            },
            [
              _c("small", { staticClass: "flex-fill" }, [
                _vm._v(_vm._s(contentAreaName)),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._l(
            _vm.getContentAreaContent(contentAreaName),
            function (content, i) {
              return _c(
                "a",
                {
                  key: contentAreaName + "." + i,
                  staticClass: "list-group-item py-1 border-0",
                  attrs: { role: "button" },
                  on: {
                    mouseover: function ($event) {
                      return _vm.mouseoverContent(content.pageContentId)
                    },
                    mouseout: _vm.mouseoutContent,
                    click: function ($event) {
                      return _vm.selectContent(content.pageContentId)
                    },
                  },
                },
                [
                  _c("PageEditorToolbarContentDropdownContentType", {
                    attrs: { "page-content-id": content.pageContentId },
                  }),
                ],
                1
              )
            }
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }