<template>
    <div>
        <FormPublishBtn :form-id="formId" class="mb-3"/>
        <FormCreatedModified :form-id="formId"/>
        <FormDisplayOrderMenu v-if="showDisplayOrderOption && currentData.id" :form-id="formId" class="mb-2"/>
        <FormHistoryBtn v-if="showHistoryBtn" :form-id="formId"/>
        <FormDuplicateBtn v-if="showDuplicateBtn" :form-id="formId"/>
        <TagsBtn :form-id="formId"/>
        <OrderRequestReview :form-id="formId"/>
        <PrintBtn :form-id="formId" :is-invoice="true"/>
        <PrintBtn :form-id="formId"/>
        <PageEditorDetachFromPageBtn :form-id="formId"/>
        <FormArchiveBtn v-if="showArchiveBtn" :form-id="formId"/>
        <FormDeleteBtn :form-id="formId" :always-show="alwaysShowDeleteBtn"/>
    </div>
</template>

<script>
import FormPublishBtn from '../../common/form/FormPublishBtn'
import FormDisplayOrderMenu from '../../common/form/FormDisplayOrderMenu'
import FormHistoryBtn from './FormHistoryBtn'
import FormDuplicateBtn from './FormDuplicateBtn'
import FormArchiveBtn from './FormArchiveBtn'
import FormDeleteBtn from './FormDeleteBtn'
import TagsBtn from '../tags/TagsBtn'
import PrintBtn from '../../components/order-details/PrintBtn'
import OrderRequestReview from "../../components/order-details/OrderRequestReview"
import FormCreatedModified from "./FormCreatedModified.vue"
import PageEditorDetachFromPageBtn from "./PageEditorDetachFromPageBtn.vue"

export default {
    name: "FormSidebar",
    components: {
        PageEditorDetachFromPageBtn,
        FormCreatedModified,
        OrderRequestReview,
        FormArchiveBtn,
        FormDeleteBtn,
        FormPublishBtn,
        FormDisplayOrderMenu,
        FormHistoryBtn,
        FormDuplicateBtn,
        TagsBtn,
        PrintBtn
    },
    props: {
        formId: String,
        showHistoryBtn: Boolean,
        showDuplicateBtn: Boolean,
        showArchiveBtn: Boolean,
        alwaysShowDeleteBtn: Boolean
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        showDisplayOrderOption() {
            return this.$store.getters[this.formId + '/showDisplayOrderOption']
        }
    }
}
</script>

<style scoped>

</style>