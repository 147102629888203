<template>
    <span>
        <button class="btn btn-sm btn-light me-2" type="button" @click="openSiteTreeModal">
            Browse…
        </button>

        <span v-if="pageId" class="well well-sm m-0 p-1 text-nowrap">
            <PagePath :page-id="pageId"/>
        </span>
    </span>
</template>

<script>
import PagePath from '../../common/PagePath'

export default {
    name: "SearchFilterFieldInternalLink",
    components: {
        PagePath
    },
    props: {
        viewId: String,
        filterKey: Number
    },
    computed: {
        filter() {
            return this.$store.state[this.viewId].filters[this.filterKey]
        },
        pageId() {
            return parseInt(this.filter.value) || 0
        }
    },
    methods: {
        openSiteTreeModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    linkOptions: 'ip',
                    pageId: this.pageId,
                    //contentId: ctrl.contentId,
                    onSelect: (obj) => {
                        this.$store.dispatch(this.viewId + '/setFilterProp', {
                            key: this.filterKey,
                            name: 'value',
                            value: obj.pageId || false
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>