<template>
    <div class="dropdown">
        <span v-if="typeOptions.length === 1" class="text-nowrap small">
            {{ filter.conditionText }}
        </span>

        <button v-if="typeOptions.length > 1" class="btn btn-light btn-sm text-nowrap focus-ring" data-bs-toggle="dropdown" type="button">
            {{ filter.conditionText }}
        </button>

        <ul class="dropdown-menu" role="menu">
            <li v-for="(option, index) in typeOptions" :key="option.id" @click="setOption(index)">
                <a href @click.prevent :class="{'active': option.text === filter.conditionText}"
                   class="dropdown-item">{{ option.text }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "SearchFilterColumnOptions",
    props: {
        viewId: String,
        filterKey: Number,
        columns: Array
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        filter() {
            return this.$store.state[this.viewId].filters[this.filterKey]
        },
        column() {
            return this.columns.find(o => o.columnName === this.filter.columnName)
        },
        typeOptions() {
            return this.$store.getters['advancedFilters/typesOptions'](this.column.type)
        }
    },
    created() {
        if (
            // The IS SET and IS NOT SET conditions also set their values because they do not have fields for storing values.
            // This value must be unset when returning to the advanced filters.
            (
                (this.filter.condition === 'is set' || this.filter.condition === 'is not set')
                && this.filter.value === undefined
            )
            || (
                this.filter.type === 'relationshipOneToMany'
                && (this.filter.condition === 'one is set' || this.filter.condition === 'one is not set')
                && this.filter.value === undefined
            )
            || (
                this.filter.type === 'relationshipManyToMany'
                && (this.filter.condition === 'many is set' || this.filter.condition === 'many is not set')
                && this.filter.value === undefined
            )
        ) {
            this.setFilter('condition', '')
        }
    },
    methods: {
        setOption(i) {
            let option = this.typeOptions[i]
            this.setFilter('condition', option.condition)
            this.setFilter('predicate', option.predicate)
            this.setFilter('conditionText', option.text)

            if (
                this.filter.condition === 'is set'
                || this.filter.condition === 'is not set'
            ) {
                this.setFilter('value', this.filter.condition)

            } else if (this.filter.type === 'relationshipOneToMany') {
                if (option.condition === 'one is set' || option.condition === 'one is not set') {
                    this.setFilter('value', 0)
                } else if (this.filter.value === 0) { // Retain the selected item when changing between IS and IS NOT
                    this.setFilter('value', undefined)
                }

            } else if (this.filter.type === 'relationshipManyToMany') {
                if (option.condition === 'many is set') {
                    this.setFilter('value', 'IS SET')
                } else if (option.condition === 'many is not set') {
                    this.setFilter('value', 'IS NOT SET')
                }
            }
        },
        setFilter(name, value) {
            this.$store.dispatch(this.viewId + '/setFilterProp', {
                key: this.filterKey,
                name: name,
                value: value
            })
        }
    }
}
</script>

<style scoped>

</style>