var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c(
      "button",
      {
        staticClass: "me-1 btn btn-sm",
        class: _vm.btn1Class,
        attrs: { type: "button" },
      },
      [_vm._v("\n        " + _vm._s(_vm.btn1Text) + "\n    ")]
    ),
    _vm._v(" "),
    _c("i", { staticClass: "me-1 bi-chevron-right" }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-sm",
        class: _vm.btn2Class,
        attrs: { type: "button" },
      },
      [_vm._v("\n        " + _vm._s(_vm.btn2Text) + "\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }