<template>
    <Button v-if="contentAreaName" class="btn-sm lh-1 p-1" type="button" @click.native="selectContentArea">
        <small>{{ contentAreaName }}</small>
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorToolbarContentAreaBtn",
    components: {Button},
    computed: {
        activePageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        },
        activeContent() {
            return this.$store.state.pageEditor.contentItems.find(o => o.pageContentId === this.activePageContentId)
        },
        contentAreaName() {
            return this.$store.state.pageEditor.selectedContentAreaName || this.activeContent?.contentAreaName
        },
    },
    methods: {
        selectContentArea() {
            if (this.$route.query.area !== this.contentAreaName) {
                const pageId = this.$store.state.pageEditor.pageId
                this.$router.push({name: 'pageEditor', params: {pageId: pageId}, query: {area: this.contentAreaName}})
            }
        }
    }
}
</script>

<style scoped>

</style>