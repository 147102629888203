var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-light",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            return _vm.showModal()
          },
        },
      },
      [_vm._v("\n        View old\n    ")]
    ),
    _vm._v(" "),
    _c("i", { staticClass: "mx-2 bi-chevron-right" }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-light",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            return _vm.showModal(true)
          },
        },
      },
      [_vm._v("\n        View new\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }