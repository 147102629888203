var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormControlPlainText", { attrs: { "form-id": _vm.formId } }, [
        _vm._v(
          "\n        " + _vm._s(_vm.currentData[_vm.field.name]) + "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("textarea", { ref: "codeMirror", staticStyle: { display: "none" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }