<template>
    <FormControlPlainText v-if="field.readonly || isTitleColumn" :value="currentData[field.name]" :form-id="formId"/>
    <input v-else :list="field.datalist ? 'datalist-' + field.id : false" :id="field.id" ref="field"
           v-model="currentData[field.name]" v-select="field.autofocus" v-form-validation :disabled="field.disabled"
           :minlength="field.minlength" :name="field.name" :pattern="field.pattern || false"
           :placeholder="field.placeholder || false" :required="field.required" :size="field.size" autocomplete="off"
           class="form-control" :class="{'is-invalid': field.error, 'form-control-sm': formSmall}" type="text">
</template>

<script>
import uniqueValueRequired from '../../vue/mixins/unique-value-required'
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"
import moment from "moment/moment"

export default {
    name: "FormControlTypeText",
    mixins: [uniqueValueRequired],
    components: {FormControlPlainText,},
    directives: {formValidation},
    props: {
        formId: String,
        currentData: Object,
        field: Object,
        value: String
    },
    data() {
        return {
            oldValues: {},
            nameParts: []
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        component() {
            return this.$store.state.components.items.find(o => o.id === this.componentId)
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.componentId)
        },
        displayName() {
            return this.component.displayName
        },
        isDisplayNameSet() {
            return !!this.displayName?.length
        },
        isTitleColumn() {
            const bool = this.isDisplayNameSet && this.nameField === this.field.columnName

            if (bool) {
                // There's no need to show the required help text when the user has no direct control over the value
                this.field.required = false
            }

            return bool
        },
        formSmall() {
            return this.$store.state[this.formId].formSmall
        }
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: ''
        })
    },
    watch: {
        currentData: {
            handler(newValue) {
                // todo Would it be much simpler if this was replaced by an API request which tapped into PHP's
                //      DisplayName class? It would avoid having to update two scripts to support a single feature.
                if (this.isTitleColumn) {
                    const fields = this.$store.getters['componentStructure/get'](this.componentId)

                    this.displayName.split(',').forEach((i, k) => {
                        let str = i.trim()

                        // If the str is wrapped in single quotes then it's a string and not a column name.
                        if (str[0] === "'") {
                            const len = str.length
                            str = str.slice(1, len - 1)
                            this.nameParts[k] = str

                        } else {
                            const keys = Object.keys(newValue)
                            const columnName = str
                            if (keys.indexOf(columnName)) {
                                const field = fields.find(o => o.columnName === columnName)
                                let value = newValue[columnName]

                                if (JSON.stringify(value) !== JSON.stringify(this.oldValues[columnName])) {
                                    /*
                                    console.log([
                                        columnName,
                                        JSON.stringify(value),
                                        JSON.stringify(this.oldValues[columnName])
                                    ])
                                    //*/

                                    let dateStr = ''
                                    const type = field.type
                                    switch (type) {
                                        case 'dateDate':
                                            if (value) {
                                                dateStr = moment(value).format('DD.MM.YY')
                                            }

                                            this.setData(k, columnName, dateStr, value)
                                            return

                                        case 'datetime-local':
                                            if (value) {
                                                dateStr = moment(value).format('DD.MM.YY HH:mm')
                                            }

                                            this.setData(k, columnName, dateStr, value)
                                            return

                                        case 'date':
                                            if (value) {
                                                // todo Duplicate of TableCellTypeDate
                                                let format
                                                switch (field.dateFormat) {
                                                    case 'dd MMM yyyy':
                                                        format = 'DD MMM YYYY'
                                                        break
                                                    case 'dd MMM yyyy, HH:mm':
                                                    default:
                                                        format = 'DD MMM YYYY, HH:mm'
                                                }

                                                dateStr = moment(value * 1000).format(format)
                                            }

                                            this.setData(k, columnName, dateStr, value)
                                            return

                                        case 'relationshipOneToMany':
                                            if (value) {
                                                const component = this.$store.state.components.items.find(o => o.id === field.categoryComponentId)
                                                const tableName = component.tableName
                                                this.$store.dispatch('itemData/get', {
                                                        tableName: tableName,
                                                        id: value
                                                    })
                                                    .then(o => {
                                                        const nameField = this.$store.getters['components/nameField'](component.id)
                                                        this.setData(k, columnName, o[nameField], value)
                                                    })
                                            } else {
                                                this.setData(k, columnName, '', value)
                                            }
                                            break

                                        case 'text':
                                            value = value.trim() // Prevents extra whitespace e.g. "Nick  Wright "
                                            this.setData(k, columnName, value, value)
                                            break

                                        default:
                                            // Old values must always be stored
                                            this.oldValues[columnName] = value
                                    }
                                }
                            }
                        }
                    })
                }
            },
            deep: true
        }
    },
    methods: {
        setData(key, columnName, value, oldValue) {
            this.nameParts[key] = value
            this.currentData[this.field.name] = this.nameParts.join('')
            this.oldValues[columnName] = oldValue
        }
    }
}
</script>

<style scoped>

</style>