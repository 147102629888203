<template>
    <button
        v-if="useArchive" :class="{'warning':showArchive}" class="d-flex align-items-center" type="button"
        @click="toggleArchive"
    >
        <i class="me-2 bi-archive"/>
        {{ text }} archive
    </button>
</template>

<script>
export default {
    name: "ListingOptionsShowArchiveBtn",
    props: {
        viewId: String,
    },
    computed: {
        listingName() {
            return this.$store.state[this.viewId].listingName
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        showArchive() {
            return this.$store.state[this.viewId].showArchive
        },
        text() {
            return this.showArchive ? 'Close' : 'Open'
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        useArchive() {
            // Only display the Show Archive button if the archive is enabled for this component.
            let component = this.$store.state.components.items.find(o => o.id === this.componentId)
            return !!component.useArchive
        }
    },
    methods: {
        toggleArchive() {
            this.$store.dispatch(this.viewId + '/setPage', 1)
            this.$store.dispatch(this.viewId + '/toggleShowArchive')
            this.$store.commit(this.viewId + '/selectNone')
        }
    }
}
</script>

<style scoped>
.warning {
    background: #ec971f;
    color: #fff;
    text-shadow: #253c8896;
}
</style>