<template>
    <p class="bg-body-tertiary rounded" :class="{'px-2 py-1 small': formSmall, 'p-2': !formSmall}">
        {{ value }}
        <slot/>
    </p>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "FormControlPlainText",
    props: {
        formId: String,
        value: [String, Number]
    },
    computed: {
        formSmall() {
            return this.$store.state[this.formId].formSmall
        }
    }
})
</script>

<style scoped>
/* Force the paragraph to have height even if the value is empty */
p::after {
    content: " ";
    font-size: 0;
    white-space: pre;
}
</style>