<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name]" :form-id="formId"/>
    <input v-else :id="field.id" v-model="currentData[field.name]" v-form-validation :maxlength="field.maxlength"
           :minlength="field.minlength" :name="field.name" :pattern="field.pattern || false"
           :placeholder="field.placeholder || false" :required="field.required" :size="field.size" autocomplete="off"
           class="form-control" :class="{'is-invalid': field.error}" type="email">
</template>

<script>
import uniqueValueRequired from '../../vue/mixins/unique-value-required'
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeEmail",
    components: {FormControlPlainText},
    mixins: [uniqueValueRequired],
    directives: {
        formValidation,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: ''
        })
    }
}
</script>

<style scoped>

</style>