var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c(
        "Button",
        {
          ref: "btn",
          staticClass: "rounded-0",
          attrs: {
            "data-bs-toggle": "offcanvas",
            "data-bs-target": "#pageEditorAddPageOffCanvas",
            "aria-controls": "pageEditorAddPageOffCanvas",
          },
        },
        [_c("i", { staticClass: "bi bi-plus-lg" })]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "offcanvas",
          staticClass: "offcanvas offcanvas-start",
          attrs: {
            tabindex: "-1",
            id: "pageEditorAddPageOffCanvas",
            "aria-labelledby": "pageEditorAddPageOffCanvasLabel",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "offcanvas-body" },
            [
              _vm.formId
                ? _c("Form", {
                    attrs: {
                      "form-id": _vm.formId,
                      "show-field-names": true,
                      "hide-help-text": true,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "offcanvas-footer text-end p-3" },
            [
              _vm.formId
                ? _c("FormSaveBtn", {
                    attrs: {
                      "form-id": _vm.formId,
                      "cancel-on-save": true,
                      "button-text": "Save",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "offcanvas-header" }, [
      _c(
        "h5",
        {
          staticClass: "offcanvas-title",
          attrs: { id: "pageEditorAddPageOffCanvasLabel" },
        },
        [_vm._v("Add page")]
      ),
      _vm._v(" "),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "offcanvas",
          "aria-label": "Close",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }