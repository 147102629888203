<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name]" :form-id="formId"/>
    <textarea v-else :id="field.id" ref="field" v-model="currentData[field.name]" v-form-validation v-textarea-auto-grow
              :cols="field.cols" :disabled="field.disabled" :minlength="field.minlength" :name="field.name"
              :placeholder="field.placeholder || false" :required="field.required" :rows="field.rows" :wrap="field.wrap"
              class="form-control" :class="{'is-invalid': field.error, 'form-control-sm': formSmall}"/>
</template>

<script>
import textareaAutoGrow from '../../vue/directives/textareaAutoGrow'
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeTextarea",
    components: {FormControlPlainText},
    directives: {textareaAutoGrow, formValidation},
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        value: String
    },
    computed: {
        formSmall() {
            return this.$store.state[this.formId].formSmall
        }
    }
}
</script>

<style scoped>

</style>