var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "dropdown pb-3 border-bottom" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-light btn-sm dropdown-toggle",
            attrs: { "data-bs-toggle": "dropdown", type: "button" },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.selectedFilter ? _vm.selectedFilter.name : "Saved filters"
                ) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dropdown-menu" },
          _vm._l(_vm.savedFilters, function (o, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "dropdown-item pe-2 d-flex align-items-center",
                class: { active: _vm.isSelected(o.filters) },
                attrs: { role: "button" },
                on: {
                  click: function ($event) {
                    return _vm.loadSavedFilter(o.name)
                  },
                },
              },
              [
                _c("span", { staticClass: "flex-fill" }, [
                  _vm._v(_vm._s(o.name)),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "ms-3 btn btn-sm btn-danger p-0 lh-1 rounded-5",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.deletedSavedFilter(o.name)
                      },
                    },
                  },
                  [_c("i", { staticClass: "bi bi-x" })]
                ),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }