var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.columns
    ? _c(
        "div",
        { staticClass: "root d-flex align-items-center py-3 border-bottom" },
        [
          _c(
            "div",
            { staticClass: "flex-fill d-flex align-items-center" },
            [
              _c("SearchFilterColumnMenu", {
                attrs: {
                  "view-id": _vm.viewId,
                  filterKey: _vm.filterKey,
                  columns: _vm.columns,
                },
              }),
              _vm._v(" "),
              _c("SearchFilterColumnOptions", {
                staticClass: "ms-1",
                attrs: {
                  "view-id": _vm.viewId,
                  filterKey: _vm.filterKey,
                  columns: _vm.columns,
                },
              }),
              _vm._v(" "),
              _c("SearchFilterField", {
                staticClass: "ms-1 focus-ring",
                attrs: {
                  "view-id": _vm.viewId,
                  filterKey: _vm.filterKey,
                  "data-search-filter-field": "",
                },
              }),
              _vm._v(" "),
              _c("SearchFilterItemResetBtn", {
                staticClass: "ms-1",
                attrs: { "view-id": _vm.viewId, "filter-key": _vm.filterKey },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "ms-1 btn btn-light btn-sm focus-ring component-filters-item-remove-btn",
              attrs: { type: "button" },
              on: { click: _vm.deleteFilter },
            },
            [_c("small", { staticClass: "bi-trash3" })]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }