var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fieldset",
    {
      ref: "fieldset",
      staticClass: "fieldset",
      attrs: { id: _vm.fieldsetId, name: _vm.legend },
    },
    [
      !_vm.isFirst && _vm.showLegends
        ? _c("legend", { staticClass: "legend" }, [
            _vm._v("\n        " + _vm._s(_vm.legend) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.fieldsetsFields, function (field, index) {
        return _c("FormGroup", {
          key: field.id,
          staticClass: "mb-4 pb-3",
          attrs: {
            field: field,
            "form-id": _vm.formId,
            "hide-help-text": _vm.hideHelpText,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }