<template>
    <p>
        <input v-if="field.readonly" v-model="currentData[field.name]" class="form-control-type-color" disabled
               type="color">
        <input v-else :id="field.id" v-model="currentData[field.name]" v-select="field.autofocus" v-form-validation
               :disabled="field.disabled" :name="field.name" :required="field.required" autocomplete="off"
               class="form-control-type-color" :class="{'is-invalid': field.error}" type="color">

        {{ currentData[field.name] }}
    </p>
</template>

<script>
import uniqueValueRequired from '../../vue/mixins/unique-value-required'
import formValidation from '../../vue/directives/formValidation'

export default {
    name: "FormControlTypeColor",
    mixins: [uniqueValueRequired],
    directives: {
        formValidation,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: ''
        })
    }
}
</script>

<style scoped>
p {
    display: flex;
    align-items: center;
}

p > * {
    margin-right: 5px;
}
</style>