<template>
    <form class="form form-inline" role="search" @submit.prevent>
        <SearchFilterItem v-for="(filter, i) in filters" :key="i" :view-id="viewId" :filterKey="i"/>

        <!--<pre class="small"><small>{{ filters }}</small></pre>
        <pre class="small"><small>{{ savedFilters }}</small></pre>-->
    </form>
</template>

<script>
import SearchFilterItem from './SearchFilterItem'

export default {
    name: "SearchFilters",
    components: {
        SearchFilterItem
    },
    props: {
        viewId: String
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        savedFilters() {
            return this.$store.state[this.viewId].savedFilters
        }
    }
}
</script>

<style scoped>

</style>