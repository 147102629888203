<template>
    <Button :disabled="isDisabled" class="rounded-0" @click.native="onClick">
        <i class="bi-chevron-left"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ListingOptionsPreviousBtn",
    components: {Button},
    props: {
        viewId: String
    },
    computed: {
        listingName() {
            return this.$store.state[this.viewId].listingName
        },
        page() {
            return this.$store.state[this.viewId].page
        },
        isDisabled() {
            return this.page === 1
        }
    },
    methods: {
        onClick() {
            if (this.page > 1) {
                this.$store.dispatch(this.viewId + '/pageDown')
            }
        }
    }
}
</script>

<style scoped>

</style>