<template>
    <small v-show="show">
        <span class="from">{{ from }}</span>
        - <span class="to">{{ to }}</span>
        of <span class="total">{{ itemCount }}</span>
    </small>
</template>

<script>
export default {
    name: "ListingOptionsPaginationMetrics",
    props: {
        viewId: String,
    },
    computed: {
        listingName() {
            return this.$store.state[this.viewId].listingName
        },
        pageSize() {
            return this.$store.state[this.viewId].pageSize
        },
        itemCount() {
            return this.$store.state[this.viewId].itemCount
        },
        page() {
            return this.$store.state[this.viewId].page
        },
        from() {
            return (this.page - 1) * this.pageSize + 1
        },
        to() {
            let to = this.from + this.pageSize - 1
            return (to > this.itemCount) ? this.itemCount : to
        },
        show() {
            return (
                this.itemCount
                && this.from !== false
                && this.to !== false
            )
        }
    }
}
</script>

<style scoped>

</style>