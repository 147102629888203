<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name]" :form-id="formId"/>
    <input v-else :id="field.id" v-model="currentData[field.name]" v-select="field.autofocus" v-form-validation
           :disabled="field.disabled" :max="field.max" :min="field.min" :name="field.name" :required="field.required"
           :step="field.step" autocomplete="off" class="form-control" :class="{'is-invalid': field.error}" type="time">
</template>

<script>
import uniqueValueRequired from '../../vue/mixins/unique-value-required'
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeTime",
    components: {FormControlPlainText},
    mixins: [uniqueValueRequired],
    directives: {
        formValidation,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: ''
        })

        // https://code.angularjs.org/1.7.9/docs/error/ngModel/datefmt?p0=1975-10-03%2010:20:00
        // "All date-related inputs like <input type="date"> require the model to be a Date object."
        let value = this.currentData[this.field.name]
        if (value) {
            // Date cannot parse a time without a date, so we provide an irrelevant date with the time.
            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: new Date('1970-01-01T' + value + '')
            })
        }
    }
}
</script>

<style scoped>

</style>