<template>
    <div class="pt-1 list-group list-group-flush">
        <template v-for="contentAreaName in contentAreaNames">
            <a role="button" class="list-group-item pt-2 pb-1 border-0 strong text-body-tertiary text-uppercase"
               @mouseover="mouseoverContentArea(contentAreaName)" @mouseout="mouseoutContentArea"
               @click="selectContentArea(contentAreaName)">
                <small class="flex-fill">{{ contentAreaName }}</small>
            </a>

            <a v-for="(content, i) in getContentAreaContent(contentAreaName)" :key="contentAreaName + '.' + i"
               role="button" class="list-group-item py-1 border-0" @mouseover="mouseoverContent(content.pageContentId)"
               @mouseout="mouseoutContent" @click="selectContent(content.pageContentId)">
                <PageEditorToolbarContentDropdownContentType :page-content-id="content.pageContentId"/>
            </a>
        </template>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"
import PageEditorToolbarContentDropdownContentType from "./PageEditorToolbarContentDropdownContentType.vue"

export default {
    name: "PageEditorContentNav",
    components: {PageEditorToolbarContentDropdownContentType, Button},
    computed: {
        activePageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        },
        pageContent() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.activePageContentId)
        },
        contentId() {
            return this.pageContent?.contentId
        },
        pageId() {
            return this.$store.state.pageEditor.pageId
        },
        contentAreaNames() {
            return this.$store.state.pageEditor.contentAreas.map(o => o.name)
        }
    },
    asyncComputed: {
        content() {
            if (this.contentId) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.contentId
                })
            }
        }
    },
    methods: {
        getContentAreaContent(name) {
            return this.$store.state.pageEditor.contentItems.filter(o => o.contentAreaName === name)
        },
        mouseoverContentArea(contentAreaName) {
            this.$store.commit('pageEditor/highlightContentAreaName', contentAreaName)
            this.$store.dispatch('pageEditor/scrollToContentAreaIframe', {contentAreaName})
        },
        mouseoutContentArea() {
            this.$store.commit('pageEditor/highlightContentAreaName', '')
        },
        selectContentArea(contentAreaName) {
            const pageId = this.pageId
            this.$router.push({name: 'pageEditor', params: {pageId: pageId}, query: {area: contentAreaName}})

            this.$refs.dropdown.click() // Hide the menu
        },
        mouseoverContent(pageContentId) {
            this.$store.commit('pageEditor/highlightPageContentId', pageContentId)
            this.$store.dispatch('pageEditor/scrollToContentIframe', pageContentId)
        },
        mouseoutContent() {
            this.$store.commit('pageEditor/highlightPageContentId', 0)
        },
        async selectContent(pageContentId) {
            const pageId = this.pageId
            await this.$router.push({name: 'pageEditor', params: {pageId: pageId}, query: {id: pageContentId}})

            await this.$store.dispatch('pageEditor/positionAllIframes')

            this.$refs.dropdown.click() // Hide the menu
        }
    }
}
</script>

<style scoped>
.list-group-item {
    font-size: .8rem !important;
}
</style>