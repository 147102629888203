<template>
    <FormControlPlainText v-if="currentData.id && !betaMode" :value="currentData[field.name]" :form-id="formId"/>
    <FormControlTypeSelect v-else :current-data="currentData" :field="field" :form-id="formId"/>
</template>

<script>
import FormControlPlainText from "./FormControlPlainText.vue"
import FormControlTypeSelect from "../form-control-type/FormControlTypeSelect.vue"

export default {
    name: "FormControlTemplatesTemplatesContentType",
    components: {FormControlTypeSelect, FormControlPlainText},
    props: {
        formId: String,
        field: Object,
        currentData: Object
    },
    computed: {
        betaMode() {
            return this.$store.state.betaMode
        }
    },
    created() {
        // todo Should be set via an upgrade
        this.field.required = true

        this.field.options = [
            {value: 'Basic', text: 'Basic'},
            {value: 'Basket', text: 'Basket'},
            {value: 'Categories', text: 'Categories'},
            {value: 'Filter', text: 'Filter'},
            {value: 'Form', text: 'Form'},
            {value: 'Form - Forgot password', text: 'Form - Forgot password'},
            {value: 'Form - Registration', text: 'Form - Registration'},
            {value: 'Form builder', text: 'Form builder'},
            {value: 'Form builder - Registration', text: 'Form builder - Registration'},
            {value: 'Listing', text: 'Listing'},
            {value: 'Listing component', text: 'Listing component'},
            {value: 'Misc.', text: 'Misc.'},
            {value: 'Navigation', text: 'Navigation'},
            {value: 'Product filter', text: 'Product filter'},
            {value: 'Product navigation', text: 'Product navigation'},
            {value: 'Tags', text: 'Tags'},
            {value: 'Teaser', text: 'Teaser'}
        ]

        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: '',
        })
    }
}
</script>

<style scoped>

</style>