<template>
    <Button class="rounded-0" @click.native="toggleSearchToolbar" :active-class="'btn-warning'" :active-value="isFilterActive">
        <i class="bi-search"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ListingOptionsSearchModalBtn",
    components: {Button},
    props: {
        viewId: String,
    },
    computed: {
        isFilterActive() {
            return !!this.$store.getters[this.viewId + '/isFilterActive']
        }
    },
    methods: {
        toggleSearchToolbar() {
            this.$store.dispatch(this.viewId + '/toggleSearchToolbar')
        }
    }
}
</script>

<style scoped>

</style>