import { render, staticRenderFns } from "./PageEditorContentMoveDownBtn.vue?vue&type=template&id=3ecb63e4&scoped=true&"
import script from "./PageEditorContentMoveDownBtn.vue?vue&type=script&lang=js&"
export * from "./PageEditorContentMoveDownBtn.vue?vue&type=script&lang=js&"
import style0 from "./PageEditorContentMoveDownBtn.vue?vue&type=style&index=0&id=3ecb63e4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ecb63e4",
  null
  
)

export default component.exports