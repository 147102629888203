<template>
    <div class="d-flex h-100">
        <aside class="w-25 overflow-hidden">
            <ThemesEditorSidebar/>
        </aside>

        <!-- Without w-100 and overflow-hidden different code mirror's widths jump around -->
        <main class="flex-fill w-100 overflow-hidden">
            <div class="d-flex flex-column h-100">
                <div class="themes-editor-toolbar overflow-x-scroll" data-themes-editor-toolbar>
                    <ThemesEditorTabs/>
                </div>

                <div v-for="(editor, index) in editors" v-if="index === activeEditorKey" :key="editor.key"
                     class="themes-editor--editor-container">
                    <ThemesEditorCodemirror :editor="editor" :focused="editor.focused" :latest-code="editor.latestCode"
                                            class="d-flex flex-column h-100"/>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import ThemesEditorSidebar from './ThemesEditorSidebar'
import ThemesEditorTabs from './ThemesEditorTabs'
import ThemesEditorCodemirror from './ThemesEditorCodemirror'

export default {
    name: "ThemesEditor",
    components: {
        ThemesEditorSidebar,
        ThemesEditorTabs,
        ThemesEditorCodemirror,
    },
    computed: {
        activeEditorKey() {
            return this.$store.state.themes.activeEditorKey
        },
        editors() {
            return this.$store.state.themes.editors
        },
        codeMirror() {
            return this.$store.state.themes.codeMirror
        }
    },
    created() {
        window.addEventListener('keydown', this.findKeyboardShortcut)

        // If returning to Themes and active tabs are open route to active tab
        if (!this.$route.query.section && this.editors.length) {
            const editor = this.editors[this.activeEditorKey]
            this.$router.push({query: {section: editor.sectionName, id: editor.id}})
        }

        this.$store.dispatch('themes/init')
        this.$store.dispatch('themes/setSectionName', this.$route.query.section || 'page_templates')

        this.$store.commit('navigationComponent', 'themes_editor')
    },
    destroyed() {
        window.removeEventListener('keydown', this.findKeyboardShortcut)
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                if (
                    to.query.section
                    && to.query.id
                ) {
                    if (
                        !from // Reloading the UI
                        || to.query.section !== from.query.section
                        || to.query.id !== from.query.id
                    ) {
                        this.$store.dispatch('themes/createEditor', {
                            sectionName: to.query.section,
                            id: to.query.id,
                        })
                    }
                }
            }
        }
    },
    methods: {
        findKeyboardShortcut(e) {
            if (
                e.metaKey // CMD key
                && e.shiftKey
                && e.keyCode === 70 // F key
            ) {
                e.preventDefault()

                if (this.codeMirror) {
                    let str = this.codeMirror.getSelection()
                    if (str) {
                        // If the search string doesn't match the previous string then this must be reset.
                        if (this.$store.state.themes.searchStr !== str) {
                            this.$store.state.themes.findModalItemIndex = 0
                        }

                        this.$store.state.themes.searchStr = str
                    }
                }

                this.$store.dispatch('modals/show', {
                    componentName: 'ThemesFindModal'
                })
            }
        }
    }
}
</script>

<style scoped>

.themes-editor--editor-container {
    height: calc(100% - 38px);
}

.themes-editor-toolbar::-webkit-scrollbar {
    display: none;
}
</style>