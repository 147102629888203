<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name]" :form-id="formId"/>
    <input v-else :id="field.id" v-model="currentData[field.name]" v-select="field.autofocus" v-form-validation
           :disabled="field.disabled" :max="field.max" :min="field.min" :name="field.name" :required="field.required"
           :step="field.step" autocomplete="off" class="form-control w-auto" :class="{'is-invalid': field.error}"
           type="date">
</template>

<script>
import uniqueValueRequired from '../../vue/mixins/unique-value-required'
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeDateDate",
    components: {FormControlPlainText},
    mixins: [uniqueValueRequired],
    directives: {
        formValidation,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: ''
        })
    }
}
</script>

<style scoped>

</style>