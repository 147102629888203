var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fadeHeight" } }, [
    _vm.showSearchToolbar
      ? _c(
          "div",
          {
            staticClass:
              "d-flex flex-wrap align-items-center gap-1 border-bottom px-3 py-1",
          },
          [
            _c("SearchFilterToolbarSearchField", {
              staticClass: "me-1",
              attrs: { "view-id": _vm.viewId },
            }),
            _vm._v(" "),
            _vm._l(_vm.filters, function (filter, i) {
              return _c("SearchFilterToolbarFilter", {
                key: i,
                staticClass: "me-1",
                attrs: {
                  "view-id": _vm.viewId,
                  "off-canvas-id": _vm.offCanvasId,
                  "filter-key": i,
                },
              })
            }),
            _vm._v(" "),
            _c("SearchFilterToolbarFiltersBtn", {
              attrs: {
                "view-id": _vm.viewId,
                "off-canvas-id": _vm.offCanvasId,
              },
            }),
            _vm._v(" "),
            _c("SearchFilterToolbarFilterOffcanvas", {
              attrs: {
                "view-id": _vm.viewId,
                "off-canvas-id": _vm.offCanvasId,
              },
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }