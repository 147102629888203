<template>
    <div ref="overlay" v-show="show">
        <a @click.prevent="edit" role="button"
           class="d-block w-100 h-100 d-flex align-items-center justify-content-center h1">
            <!--<i class="bi bi-pencil-square"></i>-->
        </a>
    </div>
</template>

<script>
export default {
    name: "PageEditorContentOverlay",
    computed: {
        hoverPageContentId() {
            return this.$store.state.pageEditor.hoverPageContentId
        },
        activeContent() {
            return this.$store.state.pageEditor.contentItems.find(o => o.pageContentId === this.hoverPageContentId)
        },
        show() {
            return !!this.hoverPageContentId
        }
    },
    mounted() {
        this.activeContent.iframe.contentDocument.body.append(this.$refs.overlay)
    },
    methods: {
        async edit() {
            const pageId = this.$store.state.pageEditor.pageId
            await this.$router.push({
                name: 'pageEditor',
                params: {pageId: pageId},
                query: {id: this.hoverPageContentId}
            })

            await this.$store.dispatch('pageEditor/positionAllIframes')

            this.$store.state.pageEditor.toolbarDropdown?.click() // Hide the menu
        }
    }
}
</script>

<style scoped>

</style>