<template>
    <div class="d-flex align-items-center">
        <small v-if="oldPageName" class="border rounded py-1 px-2" v-html="oldPageName"></small>
        <i v-else-if="oldValue" class="small">page #{{ newValue }} not found</i>
        <i v-else class="small">empty</i>

        <i class="mx-2 bi-chevron-right"/>

        <small v-if="newPageName" class="border rounded py-1 px-2" v-html="newPageName"></small>
        <i v-else-if="newValue" class="small">page #{{ newValue }} not found</i>
        <i v-else class="small">empty</i>
    </div>
</template>

<script>
export default {
    name: "ItemHistoryChangeTypeInternalLink",
    props: {
        oldValue: Number,
        newValue: Number
    },
    asyncComputed: {
        oldPageName() {
            if (this.oldValue) {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'pages',
                        id: this.oldValue
                    })
                    .then((obj) => {
                        return obj.title
                    })
            }
        },
        newPageName() {
            if (this.oldValue) {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'pages',
                        id: this.newValue
                    })
                    .then((obj) => {
                        return obj.title
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>