<template>
    <select v-model="courseId" class="form-select form-select-sm w-auto">
        <option v-for="option in options" :value="option.value">
            {{ option.text }}
        </option>
    </select>
</template>

<script>
export default {
    name: "OdpCourseDatesCoursesMenu",
    props: {
        viewId: String
    },
    data() {
        return {
            options: []
        }
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        filter() {
            return this.filters.find(o => o.columnName === 'courseId')
        },
        filterKey() {
            return this.filters.map(o => o.columnName).indexOf('courseId')
        },
        showArchive() {
            return this.$store.state[this.viewId].showArchive
        },
        courseId: {
            get() {
                return parseInt(this.filter?.value) || ''
            },
            set(courseId) {
                // If there is no active filter which filters by columnId, then add it.
                if (this.filterKey === -1) {
                    const type = 'relationshipOneToMany'
                    const option = this.$store.getters['advancedFilters/typesOptions'](type)[0]
                    this.$store.dispatch(this.viewId + '/addFilter', {
                        columnName: 'courseId',
                        type: type,
                        condition: option.condition,
                        predicate: option.predicate,
                        conditionText: option.text
                    })
                }

                this.$store.dispatch(this.viewId + '/setFilterProp', {
                    key: this.filterKey,
                    name: 'value',
                    value: courseId
                })
            }
        }
    },
    asyncComputed: {
        options() {
            const params = {
                fields: ['id', 'title'],
                sort: 'title',
                isArchived: this.showArchive ? 1 : 0
            }
            if (this.$store.state.user.user.franchiseId) {
                params.franchiseId = this.$store.state.user.user.franchiseId
            }

            return this.$store.dispatch('request/get', {
                    url: 'api/component/odp_courses',
                    params: params
                })
                .then((obj) => {
                    let options = [{
                        value: '',
                        text: 'Select a course…'
                    }]
                    obj.data.items.forEach((obj) => {
                        options.push({
                            value: obj.id,
                            text: obj.title
                        })
                    })
                    return options
                })
        }
    }
}
</script>

<style scoped>
select {
    width: 200px !important;
}
</style>