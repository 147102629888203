<template>
    <iframe v-if="iframeSrcdoc" ref="iframe" :srcdoc="iframeSrcdoc" @load="init" class="flex-fill"></iframe>
</template>

<script>
export default {
    name: "PageEditorIframe",
    props: {
        pageId: Number
    },
    data() {
        return {
            iframeSrcdoc: ''
        }
    },
    async created() {
        const o = await this.$store.dispatch('request/get', {
            url: 'controller/page-editor/' + this.pageId
        })

        this.iframeSrcdoc = o.data.html
    },
    destroyed() {
        this.$store.dispatch('pageEditor/unset')
    },
    methods: {
        init() {
            this.$store.dispatch('pageEditor/init', {
                router: this.$router,
                iframe: this.$refs.iframe,
                area: this.$route.query.area,
                pageContentId: parseInt(this.$route.query.id || 0)
            })
        }
    }
}
</script>

<style scoped>

</style>