<template>
    <a href @click.prevent v-if="show" role="button" @click="openOffcanvas" :aria-controls="offCanvasId"
       class="filter d-inline-flex align-items-center px-3 py-1 rounded-5 border text-bg-light position-relative small text-nowrap text-decoration-none focus-ring">
        <small>{{ columnTitle }}</small>

        <small class="mx-1 text-secondary">{{ filter.conditionText }}</small>

        <!--<span class="text-truncate" style="max-width: 100px" :title="value">{{ value }}</span>-->
        <small>{{ value }}</small>

        <small @click.stop.prevent="resetFilter"
               class="filter-delete-btn btn btn-sm btn-light rounded-5 p-0 lh-1 position-absolute end-0">
            <i class="bi bi-x"/>
        </small>
    </a>
</template>

<script>
import moment from "moment"
import {Offcanvas} from "bootstrap"

export default {
    name: "SearchFilterToolbarFilter",
    components: {},
    props: {
        viewId: String,
        filterKey: Number,
        offCanvasId: String
    },
    computed: {
        filter() {
            return this.$store.state[this.viewId].filters[this.filterKey]
        },
        show() {
            return this.filter.value !== undefined && this.filter.value !== ''
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        componentId() {
            return this.$store.state[this.viewId].componentId
        },
        fields() {
            return this.$store.getters['componentStructure/get'](this.componentId)
        },
        columns() {
            const idColumn = {
                fieldsetId: this.firstFieldsetId,
                columnName: this.tableName + 'ID',
                title: 'ID',
                type: 'number'
            }

            return [idColumn].concat(this.fields)
        },
        columnTitle() {
            return this.columns.find(o => o.columnName === this.filter.columnName)?.title
        },
    },
    asyncComputed: {
        value() {
            const value = this.filter.value

            switch (this.filter.type) {
                case 'checkbox':
                    return value ? 'checked' : 'unchecked'

                case 'date':
                    return moment(value).format('DD.MM.YY')

                case 'datetime-local':
                    return moment(value).format('DD.MM.YY HH:mm')

                case 'internalLink':
                    return this.$store.dispatch('itemData/get', {tableName: 'pages', id: value}).then(o => o.title)

                case 'relationshipOneToMany':
                case 'relationshipManyToMany':
                    const column = this.columns.find(o => o.columnName === this.filter.columnName)
                    const tableName = this.$store.state.components.items.find(o => o.id === column.categoryComponentId).tableName
                    return this.$store.dispatch('itemData/get', {tableName: tableName, id: value})
                        .then(o => o.name || o.title)

                /*
                case 'TYPE':
                    return this.filter.type
                //*/

                default:
                    return value
            }
        }
    },
    methods: {
        openOffcanvas() {
            const offcanvas = document.getElementById(this.offCanvasId)

            // I had to use JS, instead of data-bs-toggle, because the click.stop on the delete filter button
            // didn't prevent the offcanvas from opening.
            new Offcanvas(offcanvas).show()
            offcanvas.addEventListener('shown.bs.offcanvas', e => {
                const filterNode = offcanvas.querySelectorAll('form > div')[this.filterKey]
                const filterField = filterNode.querySelector('[data-search-filter-field]')
                if (filterField.tagName === 'BUTTON') {
                    filterField.focus()
                } else {
                    filterField?.select()
                }
            })
        },
        resetFilter() {
            this.$store.dispatch(this.viewId + '/resetFilter', this.filterKey)
        }
    }
}
</script>

<style scoped>
span {
    font-size: .75rem;
}

.filter-delete-btn {
    visibility: hidden;
}

.filter:hover .filter-delete-btn {
    visibility: visible;
}
</style>