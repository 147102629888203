<template>
    <iframe v-show="show" ref="iframe" @mouseleave="onMouseleave" @mouseenter="onMouseenter"
            :class="{ active: isActive, empty: isEmpty }"/>
</template>

<script>
export default {
    name: "PageEditorContentAreaIframe",
    props: {
        contentArea: Object
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        contentAreaName() {
            return this.contentArea.name
        },
        activeContentAreaName() {
            // Highlight must proceed selected otherwise when an area is selected this computed property won't register
            // a change.
            return this.pageEditor.highlightContentAreaName || this.pageEditor.selectedContentAreaName
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        show() {
            return this.activeContentAreaName === this.contentAreaName || this.isEmpty
        },
        isActive() {
            return this.activeContentAreaName === this.contentAreaName
                // When using the navigator, this prevents the area being highlighted when hovering over content.
                // Otherwise, both the area and the content would be highlighted, which is confusing.
                && this.pageEditor.highlightPageContentId === 0
        },
        isEmpty() {
            return this.pageEditor.contentItems.filter(o => o.contentAreaName === this.contentAreaName).length === 0
        }
    },
    mounted() {
        this.contentArea.iframe = this.$refs.iframe
        this.setIframeStyling()
        this.positionIframe()
    },
    watch: {
        isEmpty: {
            immediate: true,
            handler() {
                const contentArea = this.pageEditor.contentAreas.find(o => o.name === this.contentAreaName).el
                this.$store.dispatch('pageEditor/setContentAreaStyling', contentArea)
            }
        }
    },
    methods: {
        onMouseenter() {
            this.$store.commit('pageEditor/hoverContentAreaName', this.contentAreaName)
        },
        onMouseleave() {
            this.$store.commit('pageEditor/hoverContentAreaName', '')
        },
        positionIframe() {
            this.$store.dispatch('pageEditor/positionIframe', {
                iframe: this.$refs.iframe,
                contentEl: this.contentArea.el
            })
        },
        setIframeStyling() {
            const iframe = this.$refs.iframe

            addLink(iframe, 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css')
            addLink(iframe, 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css')

            const bodyClassList = iframe.contentWindow.document.body.classList
            // Bootstrap sets the body's bg to white, but it must be transparent
            bodyClassList.add('bg-transparent')
            bodyClassList.add('lh-1')
            bodyClassList.add('overflow-hidden')

            function addLink(iframe, src) {
                const cssLink = document.createElement('link')
                cssLink.href = src
                cssLink.rel = 'stylesheet'
                //cssLink.type = 'text/css';
                iframe.contentDocument.head.appendChild(cssLink)
            }
        }
    }
}
</script>

<style scoped>
iframe {
    position: absolute;
    z-index: 998; /* Bootstrap's dropdown z-indexes are set to 1000 */
    /*z-index: 1053; Bootstrap's modals' z-indexes start at 1055 */
    /*z-index: 2147483647; https://stackoverflow.com/questions/491052/minimum-and-maximum-value-of-z-index; */
    overflow: hidden;
    /*border: 1px solid blue;*/
}

.empty,
.active {
    background: rgba(255, 255, 255, 0.75);
    border: 2px dashed !important;
    border-radius: 5px;
}

.empty {
    min-height: 50px;
    border-color: lightgrey !important;
}

.empty:hover {
    border-color: #0d6efd !important;
}

.active,
.active:hover {
    border-color: #0d6efd !important;
}
</style>