var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-1 border-bottom" },
    [
      _c(
        "div",
        { staticClass: "text-center p-3" },
        [
          _c("SiteTreeNavigationMenu", {
            attrs: { "site-tree-id": _vm.siteTreeId },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("SiteTree", {
        attrs: {
          "select-page": _vm.onPageSelect,
          "site-tree-id": _vm.siteTreeId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }